import { useContext, useState } from 'react'
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import TableComponent from '../../../shared/Table'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import {
  AddTemplateItems,
  LocationTemplateConfigs,
} from '../../../../utils/constants'
import { useAuth0 } from '@auth0/auth0-react'
import { SelectWithSearch } from '../../../shared/SearchSelect'
import { ILocationTemplateWithRegions } from '../../../../types/spectrum-manager.type'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import {
  createLocationTemplate,
  deleteLocationTemplate,
} from '../../../../services/spectrum-manager.service'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { SelectComponent } from '../../../shared/SelectComponent'

import styles from './styles.module.scss'

export const LocationTemplates = () => {
  const [locationTemplate, setLocationTemplate] =
    useState<ILocationTemplateWithRegions | null>(null)

  const { getAccessTokenSilently } = useAuth0()
  const { locationTemplatesWithRegions, setLocationTemplatesWithRegions } =
    useContext(SpectrumManagerContext)

  const { isLightMode } = useContext(PlaybookContext)

  const { stateRegions } = useContext(ExerciseRequestContext)

  const handleTemplateChange = (key: string, value: string | number) => {
    const template = locationTemplate
      ? locationTemplate
      : ({} as ILocationTemplateWithRegions)

    setLocationTemplate({ ...template, [key]: value })
  }

  const handleRemoveLocation = async (id: number) => {
    const data = await deleteLocationTemplate(
      await getAccessTokenSilently(),
      id,
    )

    if (data.success) {
      setLocationTemplatesWithRegions(
        locationTemplatesWithRegions.filter((template) => template.id !== id),
      )
    }
  }

  const handleTemplateCreate = async () => {
    if (locationTemplate) {
      const data = await createLocationTemplate(
        await getAccessTokenSilently(),
        locationTemplate,
      )

      if (data.data) {
        setLocationTemplatesWithRegions([
          data.data,
          ...locationTemplatesWithRegions,
        ])
      }
    }
  }

  return (
    <Box className={styles.locationTemplate}>
      <Box className={styles.titleContainer}>
        <Typography className={styles.title}></Typography>
      </Box>
      <Box className={styles.tableContainer}>
        <TableComponent
          height={300}
          isLightMode={isLightMode}
          data={locationTemplatesWithRegions}
          configs={LocationTemplateConfigs}
          handleRefetch={() => {}}
          handleDelete={handleRemoveLocation}
        />
        <Box>
          <Box className={styles.titleContainer}>
            <Typography className={styles.title}>Add location</Typography>
          </Box>
          <Box className={styles.addTemplate}>
            <Box className={styles.subContainer}>
              {AddTemplateItems.slice(0, AddTemplateItems.length / 2).map(
                (item) => (
                  <Box key={item.column} className={styles.fieldContainer}>
                    <Typography className={styles.label}>
                      {item.column}{' '}
                      <Typography className={styles.asterisk}>*</Typography>:
                    </Typography>
                    {item.key !== 'latitude_ns' &&
                      item.key !== 'longitude_ew' &&
                      item.key !== 'state_region_type_id' && (
                        <TextField
                          label={item.column}
                          className={styles.field}
                          onChange={(evt) =>
                            handleTemplateChange(item.key, evt.target.value)
                          }
                        />
                      )}
                    {item.key === 'longitude_ew' && (
                      <SelectComponent
                        isLightMode={isLightMode}
                        className={styles.field}
                        label="Longitude EW"
                        items={['E', 'W'].map((value) => ({
                          value,
                          displayValue: value,
                        }))}
                        handleChange={(value) =>
                          handleTemplateChange('longitude_ew', String(value))
                        }
                      />
                    )}
                    {item.key === 'state_region_type_id' && (
                      <SelectWithSearch
                        label="Regions"
                        width={350}
                        options={stateRegions.map((region) => ({
                          value: region.id,
                          label: region.name,
                        }))}
                        value={stateRegions
                          .map((region) => ({
                            value: region.id,
                            label: region.name,
                          }))
                          .find(
                            (item) =>
                              item.value ===
                              locationTemplate?.state_region_type_id,
                          )}
                        handleChange={(value) =>
                          handleTemplateChange(
                            'state_region_type_id',
                            Number(value),
                          )
                        }
                      />
                    )}
                  </Box>
                ),
              )}
            </Box>
            <Box className={styles.subContainer}>
              {AddTemplateItems.slice(AddTemplateItems.length / 2).map(
                (item) => (
                  <Box key={item.column} className={styles.fieldContainer}>
                    <Typography className={styles.label}>
                      {item.column}{' '}
                      <Typography className={styles.asterisk}>*</Typography>:
                    </Typography>
                    {item.key !== 'latitude_ns' &&
                      item.key !== 'longitude_ew' &&
                      item.key !== 'state_region_type_id' && (
                        <TextField
                          label={item.column}
                          className={styles.field}
                          onChange={(evt) =>
                            handleTemplateChange(item.key, evt.target.value)
                          }
                        />
                      )}
                    {item.key === 'latitude_ns' && (
                      <SelectComponent
                        isLightMode={isLightMode}
                        className={styles.field}
                        label="Longitude EW"
                        items={['N', 'S'].map((value) => ({
                          value,
                          displayValue: value,
                        }))}
                        handleChange={(value) =>
                          handleTemplateChange('latitude_ns', String(value))
                        }
                      />
                    )}

                    {item.key === 'state_region_type_id' && (
                      <SelectWithSearch
                        label="Regions"
                        width={350}
                        options={stateRegions.map((region) => ({
                          value: region.id,
                          label: region.name,
                        }))}
                        value={stateRegions
                          .map((region) => ({
                            value: region.id,
                            label: region.name,
                          }))
                          .find(
                            (item) =>
                              item.value ===
                              locationTemplate?.state_region_type_id,
                          )}
                        handleChange={(value) =>
                          handleTemplateChange(
                            'state_region_type_id',
                            Number(value),
                          )
                        }
                      />
                    )}
                  </Box>
                ),
              )}
              <Box className={styles.btnContainer}>
                <Button
                  variant="contained"
                  onClick={() => handleTemplateCreate()}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
