import { FC } from 'react'
import { Handle, Position } from '@xyflow/react'
import { DirectionTypes } from '../../../types/playbook.type'

import styles from './styles.module.scss'

interface IProps {
  direction: DirectionTypes
  isStartNode?: boolean
}

const NodeHandle: FC<IProps> = ({ direction, isStartNode }) => {
  if (isStartNode) {
    return (
      <Handle
        type="source"
        position={
          direction === DirectionTypes.HORIZONTAL
            ? Position.Right
            : Position.Bottom
        }
        className={styles.topHandle}
      />
    )
  }

  return (
    <>
      <Handle
        type="target"
        position={
          direction === DirectionTypes.HORIZONTAL ? Position.Left : Position.Top
        }
        className={styles.topHandle}
      />
      <Handle
        type="source"
        position={
          direction === DirectionTypes.HORIZONTAL
            ? Position.Right
            : Position.Bottom
        }
        className={styles.bottomHandle}
      />
    </>
  )
}

export default NodeHandle
