import { useContext, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import TableComponent from '../../../shared/Table'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import { OperatingUnitConfigs } from '../../../../utils/constants'
import { useAuth0 } from '@auth0/auth0-react'
import { IOperatingUnit } from '../../../../types/operating-unit.types'
import {
  createOperatingUnit,
  deleteOperatingUnit,
} from '../../../../services/spectrum-manager.service'
import { PlaybookContext } from '../../../../contexts/playbooks.context'

import styles from './styles.module.scss'
import EditModal from '../../Modals/EditModal'

export const OperatingUnits = () => {
  const [isEditing, setIsEditing] = useState(false)
  const [operatingUnit, setOperatingUnit] = useState<IOperatingUnit | null>(
    null,
  )

  const { getAccessTokenSilently } = useAuth0()
  const { operatingUnits, setOperatingUnits } = useContext(
    SpectrumManagerContext,
  )

  const { isLightMode } = useContext(PlaybookContext)

  const handleUnitChange = (key: string, value: string) => {
    const unit = operatingUnit ? operatingUnit : ({} as IOperatingUnit)

    setOperatingUnit({ ...unit, [key]: value })
  }

  const handleUnitCreate = async () => {
    if (operatingUnit) {
      const data = await createOperatingUnit(
        await getAccessTokenSilently(),
        operatingUnit,
      )

      if (data.data) {
        setOperatingUnits([data.data, ...operatingUnits])
      }
    }
  }

  const handleUpdate = async () => {
    if (operatingUnit) {
      const data = await createOperatingUnit(
        await getAccessTokenSilently(),
        operatingUnit,
      )

      if (data.data) {
        setOperatingUnits([data.data, ...operatingUnits])
      }
    }
  }

  const handleRemoveUnit = async (id: number) => {
    const data = await deleteOperatingUnit(await getAccessTokenSilently(), id)

    if (data.success) {
      setOperatingUnits(operatingUnits.filter((unit) => unit.id !== id))
    }
  }

  return (
    <Box className={styles.operatingUnit}>
      <Box className={styles.titleContainer}>
        <Typography className={styles.title}></Typography>
      </Box>
      <Box className={styles.tableContainer}>
        <TableComponent
          height={300}
          isLightMode={isLightMode}
          data={operatingUnits}
          configs={OperatingUnitConfigs}
          handleRefetch={() => {}}
          handleDelete={handleRemoveUnit}
        />
        <Box>
          <Box className={styles.titleContainer}>
            <Typography className={styles.title}>Add Unit</Typography>
          </Box>
          <Box className={styles.addUnit}>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                Unit Name:
                <Typography className={styles.asterisk}>*</Typography>:
              </Typography>
              <TextField
                label="Unit Name"
                className={styles.field}
                value={operatingUnit?.unit_name}
                onChange={(evt) =>
                  handleUnitChange('unit_name', evt.target.value)
                }
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                Manager<Typography className={styles.asterisk}>*</Typography>:
              </Typography>
              <TextField
                label="Manager"
                className={styles.field}
                value={operatingUnit?.manager}
                onChange={(evt) =>
                  handleUnitChange('manager', evt.target.value)
                }
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                Sub Unit Name
                <Typography className={styles.asterisk}>*</Typography>:
              </Typography>
              <TextField
                label="Sub unit name"
                className={styles.field}
                value={operatingUnit?.sub_unit_name}
                onChange={(evt) =>
                  handleUnitChange('sub_unit_name', evt.target.value)
                }
              />
            </Box>
            <Box className={styles.btnContainer}>
              <Button variant="contained" onClick={() => handleUnitCreate()}>
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <EditModal
        open={isEditing}
        onClose={() => setIsEditing(false)}
        fields={OperatingUnitConfigs.items}
        handleEdit={handleUpdate}
        handleChange={() => {}}
      />
    </Box>
  )
}
