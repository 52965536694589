import { Dispatch, FC, SetStateAction, SyntheticEvent, useContext } from 'react'
import PublicIcon from '@mui/icons-material/Public'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import PhonelinkIcon from '@mui/icons-material/Phonelink'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import {
  AppBar,
  Box,
  Button,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import ControlCameraIcon from '@mui/icons-material/ControlCamera'
import { SelectComponent } from '../../../shared/SelectComponent'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'

import styles from './styles.module.scss'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const tabs = [
  {
    label: 'Requests',
    icon: SyncAltIcon,
  },
  {
    label: 'ART Diagram',
    icon: KeyboardCommandKeyIcon,
  },
  {
    label: 'Map',
    icon: PublicIcon,
  },
  {
    label: 'Field Controls',
    icon: ControlCameraIcon,
  },
  {
    label: 'Devices & Platforms',
    icon: PhonelinkIcon,
  },
  {
    label: 'Licenses',
    icon: ReceiptLongIcon,
  },
]

interface IProps {
  value: number
  setValue: Dispatch<SetStateAction<number>>
}

const Toolbar: FC<IProps> = ({ value, setValue }) => {
  const { exercises } = useContext(ExerciseRequestContext)
  const { isLightMode } = useContext(PlaybookContext)
  const { locationTemplatesWithRegions, setFilterOptions } = useContext(
    SpectrumManagerContext,
  )

  const handleChange = (_: SyntheticEvent, value: number) => {
    setValue(value)
  }

  return (
    <Box
      className={styles.toolbar}
      style={{ backgroundColor: isLightMode ? 'white' : '#13222e' }}
    >
      <Box className={styles.leftContainer}>
        <AppBar position="static">
          <Tabs
            value={value}
            TabIndicatorProps={{ sx: { backgroundColor: 'transparent' } }}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            {tabs.map((tab, index) => (
              <Tab
                sx={{
                  backgroundColor:
                    index === value
                      ? isLightMode
                        ? 'rgba(204, 205, 207, 0.5)'
                        : 'rgba(255, 255, 255, 0.2)'
                      : '',
                  borderRadius: 10,
                }}
                {...a11yProps(index)}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {<tab.icon sx={{ width: 18, height: 18 }} />}
                    <Typography sx={{ fontSize: 12 }}>{tab.label}</Typography>
                  </Box>
                }
              />
            ))}
          </Tabs>
        </AppBar>
      </Box>
      <Box className={styles.rightContainer}>
        <Button className={styles.exportBtn} variant="contained">
          Export
        </Button>
        <Select
          className={styles.select}
          onChange={(evt) =>
            setFilterOptions((prev) => ({
              ...prev,
              exerciseId: Number(evt.target.value),
            }))
          }
        >
          {exercises.map((exercise) => (
            <MenuItem key={exercise.id} value={exercise.id}>
              {exercise.name}
            </MenuItem>
          ))}
        </Select>
        <SelectComponent
          isLightMode={isLightMode}
          items={['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED', 'TEST'].map(
            (value) => ({ value, displayValue: value }),
          )}
          className={styles.select}
          handleChange={(value) =>
            setFilterOptions((prev) => ({
              ...prev,
              status: String(value),
            }))
          }
        />
        <Select
          className={styles.select}
          onChange={(evt) =>
            setFilterOptions((prev) => ({
              ...prev,
              locationId: Number(evt.target.value),
            }))
          }
        >
          {locationTemplatesWithRegions.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  )
}

export default Toolbar
