import { useContext } from 'react'
import { Box } from '@mui/material'
import Sidebar from '../../components/features/ExerciseRequestForm/Sidebar'
import Sections from '../../components/features/ExerciseRequestForm/Sections'
import { PlaybookContext } from '../../contexts/playbooks.context'

import styles from './styles.module.scss'

const ExerciseRequestFormPage = () => {
  const { isLightMode } = useContext(PlaybookContext)

  return (
    <Box
      className={styles.exerciseRequestForm}
      style={{ backgroundColor: isLightMode ? 'white' : '#04111a' }}
    >
      <Sidebar isLightMode={isLightMode} />
      <Sections isLightMode={isLightMode} />
    </Box>
  )
}

export default ExerciseRequestFormPage
