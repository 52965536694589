import { useContext, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Box, Button, TextField, Typography } from '@mui/material'
import TableComponent from '../../../shared/Table'
import { ExercisesConfigs } from '../../../../utils/constants'
import { useAuth0 } from '@auth0/auth0-react'
import {
  createExercise,
  deleteExercise,
} from '../../../../services/spectrum-manager.service'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import { IExercise } from '../../../../types/exercise.type'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import styles from './styles.module.scss'

export const Exercises = () => {
  const [exercise, setExercise] = useState<IExercise | null>(null)
  const [dateError, setDateError] = useState<string | null>(null)

  const { getAccessTokenSilently } = useAuth0()
  const { exercises, setExercises } = useContext(ExerciseRequestContext)
  const { isLightMode } = useContext(PlaybookContext)

  const handleExerciseChange = (key: string, value: string | null) => {
    const unit = exercise ? exercise : ({} as IExercise)
    setExercise({ ...unit, [key]: value })
  }

  const handleDateChange = (
    key: 'start_date' | 'end_date',
    value: Dayjs | null,
  ) => {
    const formattedDate = value ? value.format('YYYY-MM-DD') : null

    if (key === 'start_date' && exercise?.end_date) {
      if (value && dayjs(exercise.end_date).isBefore(value)) {
        setDateError('Start date cannot be after end date')
      } else {
        setDateError(null)
      }
    }

    if (key === 'end_date' && exercise?.start_date) {
      if (value && dayjs(exercise.start_date).isAfter(value)) {
        setDateError('End date cannot be before start date')
      } else {
        setDateError(null)
      }
    }

    handleExerciseChange(key, formattedDate)
  }

  const handleExerciseCreate = async () => {
    if (exercise && !dateError) {
      const data = await createExercise(
        await getAccessTokenSilently(),
        exercise,
      )

      if (data.data) {
        setExercises([...exercises, data.data])
      }
    }
  }

  const handleRemoveExercise = async (id: number) => {
    const data = await deleteExercise(await getAccessTokenSilently(), id)

    if (data.success) {
      setExercises(exercises.filter((exercise) => exercise.id !== id))
    }
  }

  return (
    <Box className={styles.exercises}>
      <Box className={styles.titleContainer}>
        <Typography className={styles.title}></Typography>
      </Box>
      <Box className={styles.tableContainer}>
        <TableComponent
          height={300}
          isLightMode={isLightMode}
          data={exercises}
          configs={ExercisesConfigs}
          handleRefetch={() => {}}
          handleDelete={handleRemoveExercise}
        />
        <Box>
          <Box className={styles.titleContainer}>
            <Typography className={styles.title}>Add Exercise</Typography>
          </Box>
          <Box className={styles.addExercise}>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                Name:
                <Typography className={styles.asterisk}>*</Typography>
              </Typography>
              <TextField
                label="Name"
                className={styles.field}
                value={exercise?.name || ''}
                onChange={(evt) =>
                  handleExerciseChange('name', evt.target.value)
                }
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                Start Date:
                <Typography className={styles.asterisk}>*</Typography>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  className={styles.field}
                  value={
                    exercise?.start_date ? dayjs(exercise.start_date) : null
                  }
                  sx={{ color: isLightMode ? 'black' : 'white' }}
                  onChange={(newValue) =>
                    handleDateChange('start_date', newValue)
                  }
                />
              </LocalizationProvider>
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                End Date:
                <Typography className={styles.asterisk}>*</Typography>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  className={styles.field}
                  value={exercise?.end_date ? dayjs(exercise.end_date) : null}
                  sx={{ color: isLightMode ? 'black' : 'white' }}
                  onChange={(newValue) =>
                    handleDateChange('end_date', newValue)
                  }
                />
              </LocalizationProvider>
              {dateError && (
                <Typography color="error" className={styles.errorText}>
                  {dateError}
                </Typography>
              )}
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>F 702</Typography>
              <TextField
                label="F 702"
                className={styles.field}
                value={exercise?.f_702}
                onChange={(evt) =>
                  handleExerciseChange('f_702', evt.target.value)
                }
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                F 910
                <Typography className={styles.asterisk}>*</Typography>:
              </Typography>
              <TextField
                label="F 910"
                className={styles.field}
                value={exercise?.f_910}
                onChange={(evt) =>
                  handleExerciseChange('f_910', evt.target.value)
                }
              />
            </Box>
            <Box className={styles.btnContainer}>
              <Button
                variant="contained"
                onClick={handleExerciseCreate}
                disabled={!!dateError}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
