import { FC, useContext } from 'react'
import { ThemeProvider } from '@mui/material'
import { ThemeOptions, createTheme } from '@mui/material/styles'
import { PlaybookContext } from '../contexts/playbooks.context'

const DarkTheme: ThemeOptions = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          borderColor: 'white',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          color: 'white !important',
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #303030 inset',
            WebkitTextFillColor: 'white !important',
          },
          '& .MuiInput-underline': {
            borderBottomColor: 'white !important',
          },
          '& .MuiOutlinedInput-root': {
            color: 'white !important',
            borderColor: 'white !important ',

            '&.Mui-focused fieldset': {
              borderColor: 'white !important',
            },
          },
          '& fieldset': {
            borderColor: 'white !important',
            color: 'white !important',
          },
          '& label': {
            color: 'white !important',
          },
          '& input': {
            color: 'white !important',
          },
          '& div': {
            color: 'white !important',
          },
          '& svg': {
            color: 'white !important',
          },
          '& label.Mui-focused': {
            color: 'white !important',
          },
          '& .Mui-disabled': {
            color: 'white !important',
            WebkitTextFillColor: 'white !important',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'white !important',
          },
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: 'white !important',
          },
        },
        paper: {
          color: 'white',
          backgroundColor: '#04111a',
        },
        noOptions: {
          color: 'white !important',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: 'white',
          borderColor: 'white',
          '& .MuiInput-underline': {
            borderBottomColor: 'white',
          },
          '& .MuiOutlinedInput-root': {
            borderColor: 'white',

            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
          '& fieldset': {
            borderColor: 'white',
          },
          '& label': {
            color: 'white',

            '&.Mui-disabled': {
              color: 'white',
            },
          },
          '& div': {
            color: 'white',
          },
          '& label.Mui-focused': {
            color: 'white',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
          },
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: 'white',
          },
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: 'gray',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: 'white',
          outline: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          outline: 'none',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          outline: 'none !important',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& > div:nth-of-type(3)': {
            backgroundColor: '#04111a',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: '#13222e',
          '& div': { display: 'flex', justifyContent: 'center' },
          '& button': { color: 'white' },
          '& button:hover': { color: 'rgb(22, 119, 56)' },
          '& button:focus': { color: 'rgb(22, 119, 56)' },
          '& button:active': { color: 'rgb(22, 119, 56)' },
          '& button.Mui-selected': { color: 'rgb(22, 119, 56)' },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          outline: 'none',
          backgroundColor: '#13222e',
          borderColor: 'white',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& div': {
            color: 'white',
          },
          '& svg': {
            color: 'white',
          },
          '& fieldset': {
            border: '1px solid white',
            backgroundColor: 'transparent',
          },
        },
        iconOutlined: {
          color: 'white',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#13222e',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          div: {
            color: 'white !important',
          },
          span: {
            color: 'white !important',
          },
          button: {
            color: 'white !important',
          },
        },
      },
    },
  },
  palette: {
    background: {
      default: 'white',
      paper: 'rgb(19, 34, 46)',
    },
  },
})

const LightTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'black !important',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: 'black',
          borderColor: 'black',
          '& .MuiInput-underline': {
            borderBottomColor: 'black',
          },
          '& .MuiOutlinedInput-root': {
            borderColor: 'black',

            '&.Mui-focused fieldset': {
              borderColor: 'black',
            },
          },
          '& fieldset': {
            borderColor: 'black',
          },
          '& label.Mui-focused': {
            color: 'black',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
          },
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: 'black',
          },
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: 'gray',
            color: 'white',
            border: '1px solid white',

            '& .MuiInput-underline': {
              borderBottomColor: 'black',
            },
            '& .MuiOutlinedInput-root': {
              borderColor: 'black',

              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& fieldset': {
              borderColor: 'black',
            },
            '& label.Mui-focused': {
              color: 'black',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'black',
            },
            '& .MuiFilledInput-underline:after': {
              borderBottomColor: 'black',
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          outline: 'none',
          backgroundColor: 'rgb(22, 119, 56)',
          '&:hover': {
            backgroundColor: 'rgb(22, 119, 56, 0.8)',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          outline: 'none !important',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: 'black',
          outline: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          outline: 'none',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& > div:nth-of-type(3)': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '$ div': {
            color: 'black',
          },
          '$ svg': {
            color: 'black',
          },
          '& fieldset': {
            border: '1px solid black',
            backgroundColor: 'transparent',
          },
        },
        iconOutlined: {
          color: 'black',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          '& div': { display: 'flex', justifyContent: 'center' },
          '& button': { color: 'black' },
          '& button:hover': { color: 'rgb(22, 119, 56)' },
          '& button:focus': { color: 'rgb(22, 119, 56)' },
          '& button:active': { color: 'rgb(22, 119, 56)' },
          '& button.Mui-selected': { color: 'rgb(22, 119, 56)' },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          color: 'black !important',
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #303030 inset',
            WebkitTextFillColor: 'black !important',
          },
          '& .MuiInput-underline': {
            borderBottomColor: 'black !important',
          },
          '& .MuiOutlinedInput-root': {
            color: 'black !important',
            borderColor: 'black !important ',

            '&.Mui-focused fieldset': {
              borderColor: 'black !important',
            },
          },
          '& fieldset': {
            borderColor: 'black !important',
            color: 'black !important',
          },
          '& label': {
            color: 'black !important',
          },
          '& input': {
            color: 'black !important',
          },
          '& div': {
            color: 'black !important',
          },
          '& svg': {
            color: 'black !important',
          },
          '& label.Mui-focused': {
            color: 'black !important',
          },
          '& .Mui-disabled': {
            color: 'black !important',
            WebkitTextFillColor: 'black !important',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'black !important',
          },
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: 'black !important',
          },
        },
        paper: {
          backgroundColor: 'white',
        },
        noOptions: {
          color: 'white !important',
        },
      },
    },
  },
  palette: {
    background: {
      default: 'white',
      paper: 'white',
    },
  },
})

interface IProps {
  children: JSX.Element
}

export const MUIThemeProvider: FC<IProps> = ({ children }) => {
  const { isLightMode } = useContext(PlaybookContext)

  if (isLightMode) {
    return <ThemeProvider theme={LightTheme}>{children}</ThemeProvider>
  }

  return <ThemeProvider theme={DarkTheme}>{children}</ThemeProvider>
}
