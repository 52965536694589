import { createContext, ReactNode, useState } from 'react'
import { IEvents, IEventsContext } from '../types/event.type'

export const EventsContext = createContext<IEventsContext>({
  events: {},
  eventsRoom: '',
  setEvents: () => {},
})

const eventsRoom = crypto.randomUUID()

export const EventsProvider = ({ children }: { children: ReactNode }) => {
  const [events, setEvents] = useState<IEvents>({})

  return (
    <EventsContext.Provider value={{ events, eventsRoom, setEvents }}>
      {children}
    </EventsContext.Provider>
  )
}
