import { useContext } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { PlaybookContext } from '../../../contexts/playbooks.context'

import styles from './styles.module.scss'

const Loader = () => {
  const { isLightMode } = useContext(PlaybookContext)

  return (
    <Box
      className={styles.loader}
      style={{ backgroundColor: isLightMode ? 'white' : '#13222e' }}
    >
      <CircularProgress color="warning" />
    </Box>
  )
}

export default Loader
