import React, { useContext, useState } from 'react'
import Paper from '@mui/material/Paper'
import {
  TableCell,
  Box,
  Tooltip,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Collapse,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Loader from '../Loader'
import SFAFDrawer from '../../features/SpectrumManagerDashboard/SFAFDrawer'
import TableToolbar from '../TableToolbar'
import { IPagination } from '../../../types/playbook.type'
import { TableVirtuoso } from 'react-virtuoso'
import { PlaybookContext } from '../../../contexts/playbooks.context'
import { getTableCellColor } from '../../../utils/functions'
import { IExerciseRequestDetails } from '../../../types/spectrum-manager.type'

import styles from './styles.module.scss'

const colors: { [key: string]: { background: string; color: string } } = {}

interface IProps<T> {
  data: T[]
  configs: {
    title: string
    items: {
      column: string
      key: string
      isColor: boolean
      width?: number
      items?: any[]
    }[]
    isViewable: boolean
    isDeletable: boolean
  }
  height: number
  isLightMode: boolean
  totalCount?: number
  handleRefetch: (pagination: IPagination) => void
  isLoading?: boolean
  handleDelete?: (id: number) => void
  handleEdit?: () => void
  handleView?: (item: any) => void
}

const TableComponent = <T extends Record<string, any>>({
  data,
  height,
  configs,
  isLoading,
  totalCount,
  handleEdit,
  handleView,
  handleDelete,
  handleRefetch,
}: IProps<T>) => {
  const [page, setPage] = useState(0)
  const [request, setRequest] = useState<IExerciseRequestDetails | null>(null)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set())
  const { isLightMode } = useContext(PlaybookContext)

  const onPageChange = (page: number) => {
    setPage(page)
    handleRefetch({
      count: totalCount || 0,
      take: rowsPerPage,
      skip: page * rowsPerPage,
    })
  }

  const onRowsPerPageChange = (rows: number) => {
    setPage(0)
    setRowsPerPage(rows)
    handleRefetch({
      count: totalCount || 0,
      take: rows,
      skip: page * rows,
    })
  }

  const toggleRowExpansion = (index: number) => {
    const newExpandedRows = new Set(expandedRows)
    if (newExpandedRows.has(index)) {
      newExpandedRows.delete(index)
    } else {
      newExpandedRows.add(index)
    }
    setExpandedRows(newExpandedRows)
  }

  const fixedHeaderContent = () => (
    <TableRow sx={{ background: isLightMode ? 'white' : 'rgb(19, 34, 46)' }}>
      {configs.isViewable && (
        <th
          style={{
            width: '100px',
            borderColor: isLightMode ? 'black' : 'white',
          }}
        >
          <Typography>
            {configs.title === 'Requests' ? 'View SFAF' : ''}
          </Typography>
        </th>
      )}
      {configs.items.map(({ column, width }) => (
        <th
          key={column}
          style={{
            width: width || 150,
            borderColor: isLightMode ? 'black' : 'white',
          }}
        >
          <Tooltip title={column}>
            <Typography>{column}</Typography>
          </Tooltip>
        </th>
      ))}
      {configs.isDeletable && (
        <th
          style={{
            width: '100px',
            borderColor: isLightMode ? 'black' : 'white',
          }}
        ></th>
      )}
    </TableRow>
  )

  const rowContent = (_index: number, elem: T) => {
    const isExpanded = expandedRows.has(_index)
    return (
      <>
        {configs.isViewable && (
          <td
            align="center"
            style={{
              width: '50px',
              borderColor: isLightMode ? 'black' : 'white',
            }}
          >
            <IconButton
              onClick={() => {
                setRequest(elem as any)
                if (handleView) handleView(elem)
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </td>
        )}
        {configs.items.map(({ key, isColor, width, items }) => {
          let color = { background: '', color: '' }
          if (isColor) {
            const value = elem[key]
            color = getTableCellColor(colors, value, isLightMode)
            if (!colors[value]) colors[value] = color
          }
          return (
            <td
              key={key}
              className={styles.bodyCell}
              style={{
                width: width || 150,
                padding: '0 10px',
                borderColor: isLightMode ? 'black' : 'white',
              }}
            >
              <Tooltip title={elem[key]}>
                {isColor ? (
                  <Box
                    sx={{ backgroundColor: color.background }}
                    className={isColor && elem[key] ? styles.coloredCell : ''}
                  >
                    <Typography sx={{ color: color.color }}>
                      {elem[key]}
                    </Typography>
                  </Box>
                ) : (
                  elem[key]
                )}
              </Tooltip>
            </td>
          )
        })}
        {configs.isDeletable && (
          <td
            style={{
              width: '100px',
              borderColor: isLightMode ? 'black' : 'white',
            }}
            align="center"
          >
            <IconButton onClick={() => handleDelete && handleDelete(elem.id)}>
              <DeleteIcon sx={{ color: 'red' }} />
            </IconButton>
          </td>
        )}
        <td>
          {configs.items.some((item) => item.items) && (
            <IconButton onClick={() => toggleRowExpansion(_index)}>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </td>
        {isExpanded && configs.items.some((item) => item.items) && (
          <TableRow>
            <TableCell
              colSpan={
                configs.items.length +
                (configs.isViewable ? 1 : 0) +
                (configs.isDeletable ? 1 : 0)
              }
            >
              {configs.items.map(({ items, column }) =>
                items ? (
                  <Box key={column}>
                    <Typography variant="subtitle2">{column}</Typography>
                    {items.map((subItem) => (
                      <Typography key={subItem.key}>
                        {elem[subItem.key]}
                      </Typography>
                    ))}
                  </Box>
                ) : null,
              )}
            </TableCell>
          </TableRow>
        )}
      </>
    )
  }

  return (
    <Box className={styles.tableComponent}>
      <Box className={styles.titleContainer}>
        <Typography className={styles.title}>{configs.title}</Typography>
      </Box>
      {!isLoading && (
        <TableVirtuoso
          data={data}
          components={{
            Scroller: React.forwardRef((props: any, ref) => (
              <TableContainer component={Paper} {...props} ref={ref} />
            )),
            Table: (props: any) => (
              <Table
                {...props}
                sx={{
                  borderCollapse: 'separate',
                  tableLayout: 'fixed',
                  width: '100%',
                }}
              />
            ),
            TableHead: React.forwardRef((props: any, ref) => (
              <TableHead {...props} ref={ref} />
            )),
            TableRow,
            TableBody: React.forwardRef((props: any, ref) => (
              <TableBody {...props} ref={ref} />
            )),
          }}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
          style={{ width: '100%', height }}
          totalCount={data.length}
        />
      )}
      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <Loader />
        </Box>
      )}
      {totalCount && (
        <TableToolbar
          isLightMode={isLightMode}
          pagination={{ skip: page, take: rowsPerPage, count: totalCount }}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
      {configs.title === 'Requests' && request && (
        <SFAFDrawer
          isOpen={!!request}
          request={request}
          handleClose={() => setRequest(null)}
        />
      )}
    </Box>
  )
}

export default TableComponent
