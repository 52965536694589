import {
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  TextField,
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import styles from './styles.module.scss'

interface IProps<T> {
  open: boolean
  fields: { key: string; column: string; isColor: boolean }[]
  onClose: () => void
  handleEdit: () => void
  handleChange: (key: string, value: string | number | null) => void
}

const EditModal = <T extends Record<string, any>>({
  open,
  fields,
  handleEdit,
  handleChange,
  onClose,
}: IProps<T>) => {
  return (
    <Modal className={styles.modal} open={open} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box>
          <Box className={styles.header}>
            <Typography className={styles.title} id="title">
              Edit
            </Typography>
            <IconButton onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box>
            {fields.map((field) => (
              <Box>
                <Typography>{field.column}</Typography>
                <TextField
                  onChange={(evt) => handleChange(field.key, evt.target.value)}
                />
              </Box>
            ))}
          </Box>
          <Box className={styles.actions}>
            <Button
              className={styles.removeBtn}
              color="success"
              onClick={handleEdit}
            >
              Create
            </Button>
            <Button
              className={styles.cancelBtn}
              color="inherit"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default EditModal
