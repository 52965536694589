import { useContext } from 'react'
import styles from './styles.module.scss'
import { PlaybookContext } from '../../contexts/playbooks.context'

const ResiumMap = () => {
  const { isLightMode } = useContext(PlaybookContext)

  return (
    <div
      className={styles.iframe}
      style={{ backgroundColor: isLightMode ? 'white' : '#13222e' }}
    >
      <iframe src={process.env.REACT_APP_CESIUM_URL} allowFullScreen />
    </div>
  )
}

export default ResiumMap
