import { type Edge } from '@xyflow/react'

export const createEdge = (
  id: string,
  source: string,
  target: string,
  animated?: boolean,
  isLightMode?: boolean,
): Edge => ({
  id,
  source,
  target,
  animated,
  style: {
    fontSize: 20,
    stroke: animated
      ? isLightMode
        ? 'green'
        : '#6bf66b'
      : isLightMode
      ? 'black'
      : 'white',
    strokeWidth: 3,
  },
})
