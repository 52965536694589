import { FC } from 'react'
import { useDrag } from 'react-dnd'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Box, Typography } from '@mui/material'
import { AddNodeTypes } from '../../../../types/node.type'

import styles from './styles.module.scss'

interface IProps {
  type: AddNodeTypes
  name: string
  description: string
  isLightMode: boolean
  icon: JSX.Element
}

const NodeItem: FC<IProps> = ({
  icon,
  type,
  isLightMode,
  name,
  description,
}) => {
  const [_, drag] = useDrag(() => ({
    type: 'draggableItem',
    item: { type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  return (
    <Box
      ref={drag}
      className={styles.nodeItem}
      draggable
      sx={{ border: isLightMode ? '1px solid black' : '1px solid white' }}
    >
      <Box className={styles.iconContainer}>
        {icon}
        <Box>
          <Typography className={styles.text}>{name}</Typography>
          <Typography className={styles.description}>{description}</Typography>
        </Box>
      </Box>
      <DragIndicatorIcon className={styles.dragIcon} />
    </Box>
  )
}

export default NodeItem
