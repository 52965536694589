import { Dispatch, SetStateAction } from 'react'

export interface IEventsContext {
  events: IEvents
  eventsRoom: string
  setEvents: Dispatch<SetStateAction<IEvents>>
}

export interface IEvents {
  [key: string]: {
    events: IEvent[]
    eventsDate: string
  }
}

export interface IEvent {
  date: Date
  text: string
  run_number: number
  status: EventStatus
}

export enum EventStatus {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}
