import { Dispatch, FC, SetStateAction } from 'react'
import { Box, Button } from '@mui/material'
import Search from '../../../shared/PlaybookSearch'

import styles from './styles.module.scss'

interface IProps {
  btnText: string
  isLightMode: boolean
  handleSearch: (search: string) => void
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const DashboardToolbar: FC<IProps> = ({
  btnText,
  isLightMode,
  handleSearch,
  setIsOpen,
}) => {
  return (
    <Box className={styles.toolbar}>
      <Search handleSearch={handleSearch} isLightMode={isLightMode} />
      <Button
        data-testid="button"
        id="openPlaybookCreateModal"
        className={styles.createBtn}
        style={{
          backgroundColor: isLightMode ? '' : '#13222e',
        }}
        onClick={() => setIsOpen(true)}
      >
        {btnText}
      </Button>
    </Box>
  )
}

export default DashboardToolbar
