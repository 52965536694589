import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import Loader from './components/shared/Loader'
import ErrorPage from './components/shared/ErrorPage'
import RoutesComponent from './routers'

const App = () => {
  const { isLoading, error } = useAuth0()
  const [isLightMode, setIsLightMode] = useState(false)

  const mode = window.localStorage.getItem('mode') || 'light'

  useEffect(() => {
    setIsLightMode(mode === 'light')
    document.body.classList.toggle('light-mode', mode === 'light')
    document.body.classList.toggle('dark-mode', mode === 'dark')
  }, [mode])

  if (error) {
    return <ErrorPage code={400} message={error.message} />
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <Box className="App">
      <RoutesComponent />
    </Box>
  )
}

export default App
