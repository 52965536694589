import { Dispatch, SetStateAction } from 'react'

export interface ISchedulerContext {
  schedulers: IScheduler[]
  getSchedulers: (id: number) => void
  setSchedulers: Dispatch<SetStateAction<IScheduler[]>>
}

export interface IScheduler {
  id: number
  time: string
  date: string
}

export enum SchedulerFrequencies {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
}

export interface ISchedulerOptions {
  time: string
  date: string
  frequency: SchedulerFrequencies
}
