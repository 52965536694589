import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  getAllDevices,
  getAllNomenclatures,
  getAllNumberOfStations,
} from '../services/equipment.service'
import {
  IDevice,
  IEquipmentNomenclature,
  INumberOfStations,
} from '../types/equipment.type'
import {
  IOperatingSubUnit,
  IOperatingUnit,
} from '../types/operating-unit.types'
import { getAllOperatingUnits } from '../services/exercise-request-form.service'
import {
  IDetailedExerciseRequest,
  IFrequencyPlan,
  ILocationTemplateWithRegions,
  IPlatform,
  ISpectrumManagerContext,
} from '../types/spectrum-manager.type'
import {
  getAllPlatforms,
  getAllDetailedExerciseRequests,
  getAllLocationTemplatesWithRegions,
} from '../services/spectrum-manager.service'

export const SpectrumManagerContext = createContext<ISpectrumManagerContext>({
  devices: [],
  platforms: [],
  setDevices: () => {},
  setPlatforms: () => {},
  nomenclatures: [],
  filterOptions: {},
  operatingUnits: [],
  numberOfStations: [],
  operatingSubUnits: [],
  setFrequencyPlans: () => {},
  setFilterOptions: () => {},
  setOperatingUnits: () => {},
  detailedExerciseRequests: {
    requests: [],
    isLoading: true,
    pagination: { take: 0, skip: 0, count: 0 },
  },
  frequencyPlans: {
    frequencyPlans: [],
    isLoading: true,
    pagination: { take: 0, skip: 0, count: 0 },
  },
  locationTemplatesWithRegions: [],
  setDetailedExerciseRequests: () => {},
  setLocationTemplatesWithRegions: () => {},
})

export const SpectrumManagerProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [devices, setDevices] = useState<IDevice[]>([])
  const [platforms, setPlatforms] = useState<IPlatform[]>([])
  const [operatingUnits, setOperatingUnits] = useState<IOperatingUnit[]>([])
  const [filterOptions, setFilterOptions] = useState<{
    locationId?: number
    status?: string
    exerciseId?: number
    search?: string
  }>({})
  const [operatingSubUnits, setOperatingSubUnits] = useState<
    IOperatingSubUnit[]
  >([])
  const [nomenclatures, setNomenclatures] = useState<IEquipmentNomenclature[]>(
    [],
  )
  const [numberOfStations, setNumberOfStations] = useState<INumberOfStations[]>(
    [],
  )
  const [detailedExerciseRequests, setDetailedExerciseRequests] =
    useState<IDetailedExerciseRequest>({
      requests: [],
      isLoading: true,
      pagination: { take: 10, skip: 0, count: 0 },
    })
  const [frequencyPlans, setFrequencyPlans] = useState<IFrequencyPlan>({
    frequencyPlans: [],
    isLoading: true,
    pagination: { take: 10, skip: 0, count: 0 },
  })
  const [locationTemplatesWithRegions, setLocationTemplatesWithRegions] =
    useState<ILocationTemplateWithRegions[]>([])

  const { getAccessTokenSilently } = useAuth0()

  const abortControllerRef = useRef<AbortController | null>(null)

  const memoizedFilterOptions = useMemo(() => filterOptions, [filterOptions])
  const memoizedRequestsPagination = useMemo(
    () => detailedExerciseRequests.pagination,
    [detailedExerciseRequests.pagination],
  )
  const memoizedFreqPlansPagination = useMemo(
    () => frequencyPlans.pagination,
    [frequencyPlans.pagination],
  )

  const getDevices = async () => {
    const data = await getAllDevices(await getAccessTokenSilently())

    if (data.success && data.data) {
      setDevices(data.data)
    }
  }

  const getNumberOfStations = async () => {
    const data = await getAllNumberOfStations(await getAccessTokenSilently())

    if (data.success && data.data) {
      setNumberOfStations(data.data)
    }
  }

  const getNomenclatures = async () => {
    const data = await getAllNomenclatures(await getAccessTokenSilently())

    if (data.success && data.data) {
      setNomenclatures(data.data)
    }
  }

  const getOperatingUnits = async () => {
    const data = await getAllOperatingUnits(await getAccessTokenSilently())

    if (data.success && data.data) {
      setOperatingUnits(data.data)
    }
  }

  const getLocationTemplatesWithRegions = async () => {
    const data = await getAllLocationTemplatesWithRegions(
      await getAccessTokenSilently(),
    )

    if (data.success && data.data) {
      setLocationTemplatesWithRegions(data.data)
    }
  }

  const getPlatforms = async () => {
    const data = await getAllPlatforms(await getAccessTokenSilently())

    if (data.success && data.data) {
      setPlatforms(data.data)
    }
  }

  const getDetailedExerciseRequests = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }

    const controller = new AbortController()
    abortControllerRef.current = controller

    const data = await getAllDetailedExerciseRequests(
      await getAccessTokenSilently(),
      { ...filterOptions, ...detailedExerciseRequests.pagination },
      controller.signal,
    )

    if (data.success && data.data) {
      setDetailedExerciseRequests({
        requests: data.data.requests,
        pagination: {
          ...memoizedRequestsPagination,
          count: data.data.pagination.count,
        },
        isLoading: false,
      })
    }
  }

  useEffect(() => {
    getDevices()
    getPlatforms()
    getNomenclatures()
    getOperatingUnits()
    getNumberOfStations()
    getLocationTemplatesWithRegions()
  }, [])

  useEffect(() => {
    setDetailedExerciseRequests((prev) => ({ ...prev, isLoading: true }))
    getDetailedExerciseRequests()
  }, [
    memoizedFilterOptions,
    memoizedRequestsPagination.skip,
    memoizedRequestsPagination.take,
  ])

  return (
    <SpectrumManagerContext.Provider
      value={{
        devices,
        setDevices,
        setPlatforms,
        platforms,
        nomenclatures,
        filterOptions,
        operatingUnits,
        frequencyPlans,
        numberOfStations,
        setFilterOptions,
        operatingSubUnits,
        setFrequencyPlans,
        setOperatingUnits,
        detailedExerciseRequests,
        setDetailedExerciseRequests,
        locationTemplatesWithRegions,
        setLocationTemplatesWithRegions,
      }}
    >
      {children}
    </SpectrumManagerContext.Provider>
  )
}
