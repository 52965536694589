import { useContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Edge, Panel } from '@xyflow/react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ThemeProvider } from 'styled-components'
import { Box, Button, IconButton } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import StopCircleSharpIcon from '@mui/icons-material/StopCircleSharp'
import SwapVerticalCircleOutlinedIcon from '@mui/icons-material/SwapVerticalCircleOutlined'
import PlayCircleFilledWhiteSharpIcon from '@mui/icons-material/PlayCircleFilledWhiteSharp'
import Flow from '../../components/features/playbooks/ReactFlowChart'
import Sidebar from '../../components/features/playbooks/Sidebar'
import PlaybookScheduler from '../../components/features/Modals/PlaybookScheduler'
import PlaybookEnableModal from '../../components/features/Modals/PlaybookEnableModal'
import { useToast } from '../../contexts/toast.context'
import { PlaybookContext } from '../../contexts/playbooks.context'
import { SchedulerContext } from '../../contexts/schedulers.context'
import { updatePlaybook } from '../../services/playbook.service'
import { createScheduler } from '../../services/scheduler.service'
import { darkTheme, lightTheme } from '../../themes/theme'
import { DirectionTypes, IPlaybook } from '../../types/playbook.type'
import { ISchedulerOptions } from '../../types/scheduler.type'

import 'reactflow/dist/style.css'
import styles from './styles.module.scss'

export default () => {
  const [isPlayed, setIsPlayed] = useState(false)
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [isScheduling, setIsScheduling] = useState(false)
  const [isLayoutEditing, setIsLayoutEditing] = useState(false)

  const { getAccessTokenSilently } = useAuth0()
  const { getSchedulers, schedulers, setSchedulers } =
    useContext(SchedulerContext)

  const {
    isEditing,
    playbook,
    nodes,
    edges,
    initialPlaybook,
    isLightMode,
    setPlaybook,
    getPlaybook,
    handleUpdate,
    setIsEditing,
    setInitialPlaybook,
  } = useContext(PlaybookContext)
  const { showToast } = useToast()

  const theme = isLightMode ? lightTheme : darkTheme

  const handlePlay = () => {
    setIsPlayed(!isPlayed)
  }

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleCancel = () => {
    setIsEditing(false)

    if (initialPlaybook) {
      setPlaybook(() => ({ ...initialPlaybook }))
      handleUpdate({ ...initialPlaybook })
    }
  }

  const handleChangeDirection = async () => {
    if (playbook) {
      if (playbook?.direction === DirectionTypes.HORIZONTAL) {
        playbook.direction = DirectionTypes.VERTICAL
      } else {
        playbook.direction = DirectionTypes.HORIZONTAL
      }

      await updatePlaybook(playbook, await getAccessTokenSilently())

      getPlaybook(`${playbook.id}`)
    }
  }

  const handleSave = async () => {
    if (playbook) {
      const data = await updatePlaybook(
        {
          ...playbook,
          nodes,
          edges: edges as Array<Edge & { label: string; data: any }>,
        },
        await getAccessTokenSilently(),
      )

      if (data.success && data.data) {
        showToast('success', data.message)

        setPlaybook((prev) =>
          prev
            ? {
                ...prev,
                nodes: prev?.nodes.filter((node) => !node.isRemoved),
                edges: prev?.edges.filter((edge) => !edge.isRemoved),
              }
            : undefined,
        )
        handleUpdate(data.data)
        setIsEditing(false)
      } else {
        showToast('error', data.message)
      }
    }
  }

  const onAdd = async (playbook: IPlaybook) => {
    if (playbook) {
      const data = await updatePlaybook(
        playbook,
        await getAccessTokenSilently(),
        true,
      )

      if (data.success && data.data) {
        setPlaybook(() => data.data)
        handleUpdate(data.data)
        showToast('success', data.message)
      } else {
        showToast('error', data.message)
      }
    }
  }

  const handleSchedule = async (schedulerOptions: ISchedulerOptions) => {
    if (playbook) {
      const data = await createScheduler(
        schedulerOptions.frequency,
        playbook.id,
        await getAccessTokenSilently(),
      )

      if (data.data) {
        showToast('success', data.message)
        setSchedulers([...schedulers, data.data])
        setIsScheduling(false)
      }
    }
  }

  useEffect(() => {
    if (playbook) {
      getSchedulers(playbook.id)
    }
  }, [playbook?.id])

  useEffect(() => {
    const elements = document.body.getElementsByClassName('leva-c-kWgxhW')
    const leva = elements[0] as HTMLElement

    if (leva) {
      if (isLayoutEditing) {
        leva.style.setProperty('display', 'block', 'important')
      } else {
        leva.style.setProperty('display', 'none', 'important')
      }
    }
  }, [isLayoutEditing])

  return (
    <DndProvider backend={HTML5Backend}>
      <Box className={styles.playbookPage}>
        <Sidebar isLightMode={isLightMode} />
        <ThemeProvider theme={theme}>
          <Flow
            isLightMode={isLightMode}
            onAdd={onAdd}
            isLayoutEditing={isLayoutEditing}
          >
            <>
              <Panel position="top-left">
                <>
                  <IconButton onClick={() => handleChangeDirection()}>
                    <SwapVerticalCircleOutlinedIcon
                      sx={{
                        width: 40,
                        height: 40,
                        color: isLightMode ? '#E96801' : 'rgb(255 168 5)',
                      }}
                      className={
                        playbook?.direction === DirectionTypes.HORIZONTAL
                          ? styles.horizontal
                          : styles.vertical
                      }
                    />
                  </IconButton>
                  <IconButton onClick={handlePlay} id="playButton">
                    {playbook?.enabled ? (
                      <StopCircleSharpIcon
                        id="stop"
                        sx={{ color: 'red', width: 40, height: 40 }}
                      />
                    ) : (
                      <PlayCircleFilledWhiteSharpIcon
                        id="play"
                        sx={{ color: 'green', width: 40, height: 40 }}
                      />
                    )}
                  </IconButton>
                </>
              </Panel>
              <Panel position="top-right" className={styles.rightPanel}>
                <>
                  <Box
                    className={styles.buttons}
                    style={{ height: isPanelOpen ? '40px' : '0px' }}
                  >
                    {!isLayoutEditing && (
                      <Button
                        variant="contained"
                        style={
                          isLightMode ? {} : { backgroundColor: '#13222e' }
                        }
                        onClick={() => setIsScheduling(true)}
                      >
                        Schedule
                      </Button>
                    )}
                    {(!isEditing || isLayoutEditing) && (
                      <Button
                        variant="contained"
                        style={
                          isLightMode ? {} : { backgroundColor: '#13222e' }
                        }
                        className={styles.layoutBtn}
                        onClick={() => {
                          setIsLayoutEditing(!isLayoutEditing)
                          setIsEditing(!isEditing)
                        }}
                      >
                        {isLayoutEditing ? 'Cancel' : 'Auto-Layout'}
                      </Button>
                    )}
                    {!isLayoutEditing && (
                      <>
                        <Button
                          variant="contained"
                          style={
                            isLightMode ? {} : { backgroundColor: '#13222e' }
                          }
                          onClick={isEditing ? handleCancel : handleEdit}
                        >
                          {isEditing ? 'Cancel Editing' : 'Edit'}
                        </Button>
                        {isEditing && (
                          <Button
                            variant="contained"
                            style={
                              isLightMode ? {} : { backgroundColor: '#13222e' }
                            }
                            onClick={handleSave}
                          >
                            Save
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                  <IconButton onClick={() => setIsPanelOpen(!isPanelOpen)}>
                    <ArrowBackIosIcon
                      className={
                        !isPanelOpen ? styles.arrowBtn : styles.backArrowBtn
                      }
                    />
                  </IconButton>
                </>
              </Panel>
            </>
          </Flow>
          <PlaybookEnableModal
            playbook={playbook}
            isOpen={isPlayed}
            onClose={() => setIsPlayed(false)}
          />
          <PlaybookScheduler
            isOpen={isScheduling}
            playbookName={playbook?.name}
            isLightMode={isLightMode}
            onClose={() => setIsScheduling(false)}
            handleSchedule={handleSchedule}
          />
        </ThemeProvider>
      </Box>
    </DndProvider>
  )
}
