import { Node } from '@xyflow/react'
import { axiosInstance } from './axios.service'
import { IResponse } from '../types/response.type'
import { NodeEndpoints } from '../types/endpoint.type'

export const createNodes = async (
  nodes: Node[],
  token: string,
): Promise<IResponse<Node[]>> => {
  try {
    const data = await axiosInstance.post(NodeEndpoints.CREATE, nodes, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err.message,
    }
  }
}

export const updateNode = async (
  node: Node,
  token: string,
): Promise<IResponse<Node[]>> => {
  try {
    const data = await axiosInstance.put(NodeEndpoints.UPDATE + node.id, node, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}
