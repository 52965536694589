import { FC } from 'react'
import { Box } from '@mui/material'
import OpenWithIcon from '@mui/icons-material/OpenWith'

import styles from './styles.module.scss'

interface IProps {
  isLightMode: boolean
  isText?: boolean
  backgroundColor?: string
  isSelected?: boolean
}

const DraggableButton: FC<IProps> = ({
  isLightMode,
  isText,
  backgroundColor,
  isSelected,
}) => {
  return (
    <Box
      className={styles.draggableBox}
      style={{
        boxShadow: isSelected
          ? isLightMode
            ? '0px -3px 6px -1px black'
            : '0 -2px 2px 2px #fff'
          : '',
        backgroundColor: isLightMode
          ? backgroundColor
          : isText
          ? backgroundColor
          : '#13222e',
      }}
    >
      <OpenWithIcon
        sx={{ color: isLightMode ? (isText ? 'white' : 'deeppink') : 'white' }}
      />
    </Box>
  )
}

export default DraggableButton
