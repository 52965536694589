import { FC } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Box } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
} from '@mui/x-date-pickers'

import styles from './styles.module.scss'

interface IProps {
  time: Dayjs
  date: Dayjs
  isLightMode: boolean
  handleDateChange: (value: Dayjs) => void
  handleTimeChange: (value: Dayjs) => void
}

const DatePickerComponent: FC<IProps> = ({
  time,
  date,
  isLightMode,
  handleDateChange,
  handleTimeChange,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className={styles.datePicker}>
        <DatePicker
          label="Date"
          value={date}
          sx={{ color: isLightMode ? 'black' : 'white' }}
          onChange={(newValue) => handleDateChange(newValue as Dayjs)}
        />
        <TimePicker
          label="Time"
          value={dayjs(date)}
          sx={{ color: isLightMode ? 'black' : 'white' }}
          onChange={(value) => handleTimeChange(value as Dayjs)}
        />
      </Box>
    </LocalizationProvider>
  )
}

export default DatePickerComponent
