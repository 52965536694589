import React, { useContext, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip,
  Typography,
  Collapse,
  IconButton,
  Button,
  Checkbox,
  TextField,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { PlaybookContext } from '../../../contexts/playbooks.context'
import { getTableCellColor } from '../../../utils/functions'
import styles from './styles.module.scss'

const colors: { [key: string]: { background: string; color: string } } = {}

interface IProps<T> {
  data: T[]
  configs: {
    title: string
    items: {
      column: string
      key: string
      isColor: boolean
      width?: number
      isEditable?: boolean
    }[]
    subTitle: string
    subItems: {
      key: string
      column: string
      isColor: boolean
      isEditable?: boolean
    }[]
    isViewable: boolean
    isDeletable: boolean
  }
  isLightMode: boolean
  isLoading?: boolean
  handleCreate: (id?: number) => void
  handleDelete: (id: number, subItemId?: number) => void
  handleChange: (
    id: number,
    key: string,
    value: string,
    subItem?: number,
  ) => void
  getSubItems: (id: number) => void
}

const ExpandableTable = <T extends Record<string, any>>({
  data,
  configs,
  isLoading,
  handleDelete,
  handleCreate,
  handleChange,
  getSubItems,
}: IProps<T>) => {
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set())
  const [selectedRowId, setSelectedRowId] = useState<number | undefined>()
  const [selectedSubItemId, setSelectedSubItemId] = useState<
    number | undefined
  >()
  const [editableCell, setEditableCell] = useState<{
    rowId: number
    key: string
  } | null>(null)
  const { isLightMode } = useContext(PlaybookContext)

  const toggleRowExpansion = (index: number, id: number) => {
    const newExpandedRows = new Set(expandedRows)
    if (newExpandedRows.has(index)) {
      newExpandedRows.delete(index)
    } else {
      newExpandedRows.add(index)
    }
    setExpandedRows(newExpandedRows)
    getSubItems(id)
  }

  const handleSelectRow = (id: number) => {
    setSelectedRowId(selectedRowId === id ? undefined : id)
  }

  const handleSelectSubItem = (id: number) => {
    setSelectedSubItemId(selectedSubItemId === id ? undefined : id)
  }

  const handleEditCell = (rowId: number, key: string) => {
    setEditableCell({ rowId, key })
  }

  return (
    <Box className={styles.tableComponent}>
      <Box className={styles.titleContainer}>
        <Typography className={styles.title}>{configs.title}</Typography>
        <Box className={styles.action}>
          {handleDelete && selectedRowId && (
            <Button
              sx={{ color: 'white' }}
              onClick={() => {
                handleDelete(selectedRowId)
                setSelectedRowId(undefined)
              }}
            >
              Delete
            </Button>
          )}
          {handleCreate && (
            <Button sx={{ color: 'white' }} onClick={() => handleCreate()}>
              Add
            </Button>
          )}
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        sx={{ height: '350px', overflowY: 'scroll' }}
      >
        <Table>
          <TableHead>
            <TableRow
              sx={{ background: isLightMode ? 'white' : 'rgb(19, 34, 46)' }}
            >
              <TableCell />
              <TableCell></TableCell>
              {configs.items.map(({ column }) => (
                <TableCell key={column}>
                  <Tooltip title={column}>
                    <Typography>{column}</Typography>
                  </Tooltip>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((elem, index) => {
              const isExpanded = expandedRows.has(index)
              const isSelected = selectedRowId === elem.id
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={isSelected}
                        onChange={() => handleSelectRow(elem.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => toggleRowExpansion(index, elem.id)}
                      >
                        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </TableCell>
                    {configs.items.map(
                      ({ key, isColor, width, isEditable }) => {
                        let color = { background: '', color: '' }
                        if (isColor) {
                          const value = elem[key]
                          color = getTableCellColor(colors, value, isLightMode)
                          if (!colors[value]) colors[value] = color
                        }

                        return (
                          <TableCell
                            key={key}
                            className={styles.bodyCell}
                            style={{ padding: '0 10px' }}
                          >
                            {editableCell?.rowId === elem.id &&
                            editableCell?.key === key ? (
                              <TextField
                                value={elem[key]}
                                onChange={(e) => {
                                  handleChange(elem.id, key, e.target.value)
                                }}
                                onBlur={() => setEditableCell(null)}
                                variant="outlined"
                                size="small"
                                sx={{ width: '100%' }}
                              />
                            ) : (
                              <Tooltip title={elem[key]}>
                                <Box
                                  sx={{
                                    backgroundColor: color.background,
                                    cursor: isEditable ? 'pointer' : 'default',
                                  }}
                                  onClick={() =>
                                    isEditable && handleEditCell(elem.id, key)
                                  }
                                  className={styles.coloredCell}
                                >
                                  <Typography
                                    sx={{
                                      color: elem[key]
                                        ? color.color
                                        : 'gray !important',
                                    }}
                                  >
                                    {elem[key] || 'Enter Value'}
                                  </Typography>
                                </Box>
                              </Tooltip>
                            )}
                          </TableCell>
                        )
                      },
                    )}
                  </TableRow>
                  {isExpanded && (
                    <TableRow>
                      <TableCell
                        colSpan={configs.items.length + 3}
                        style={{ paddingLeft: '40px' }}
                      >
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                          <Box>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{ marginBottom: 2 }}
                            >
                              <Typography
                                sx={{
                                  color: isLightMode ? 'black' : 'white',
                                  fontSize: 18,
                                  fontWeight: 600,
                                }}
                              >
                                {configs.subTitle}
                              </Typography>
                              <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button
                                  sx={{
                                    color: 'white',
                                    display: !selectedSubItemId
                                      ? 'none'
                                      : 'block',
                                  }}
                                  onClick={() => {
                                    handleDelete(elem.id, selectedSubItemId)
                                    setSelectedSubItemId(undefined)
                                  }}
                                >
                                  Delete
                                </Button>
                                <Button
                                  sx={{ color: 'white' }}
                                  onClick={() => handleCreate(elem.id)}
                                >
                                  Create
                                </Button>
                              </Box>
                            </Box>
                            {elem.items && elem.items.length > 0 ? (
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    {configs.subItems.map((subItem) => (
                                      <TableCell
                                        key={subItem.key}
                                        sx={{
                                          color: isLightMode
                                            ? 'black'
                                            : 'white',
                                        }}
                                      >
                                        {subItem.column}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {elem.items.map(
                                    (subElem: any, subIndex: number) => {
                                      const isSubItemSelected =
                                        selectedSubItemId === subElem.id
                                      return (
                                        <TableRow key={subIndex}>
                                          <TableCell>
                                            <Checkbox
                                              checked={isSubItemSelected}
                                              onChange={() =>
                                                handleSelectSubItem(subElem.id)
                                              }
                                            />
                                          </TableCell>
                                          {configs.subItems.map(
                                            ({ key, isEditable }) => (
                                              <TableCell key={key}>
                                                {editableCell?.rowId ===
                                                  subElem.id &&
                                                editableCell?.key === key ? (
                                                  <TextField
                                                    value={subElem[key]}
                                                    onChange={(e) => {
                                                      handleChange(
                                                        elem.id,
                                                        key,
                                                        e.target.value,
                                                        subElem.id,
                                                      )
                                                    }}
                                                    onBlur={() =>
                                                      setEditableCell(null)
                                                    }
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                      width: '100%',
                                                      color: subElem[key]
                                                        ? ''
                                                        : 'gray !important',
                                                    }}
                                                  />
                                                ) : (
                                                  <Typography
                                                    onClick={() =>
                                                      isEditable &&
                                                      handleEditCell(
                                                        subElem.id,
                                                        key,
                                                      )
                                                    }
                                                    sx={{
                                                      color: isLightMode
                                                        ? subElem[key]
                                                          ? 'black'
                                                          : 'gray !important'
                                                        : subElem[key]
                                                          ? 'white'
                                                          : 'gray !important',
                                                      cursor: isEditable
                                                        ? 'pointer'
                                                        : 'default',
                                                    }}
                                                  >
                                                    {subElem[key] ||
                                                      'Enter Value'}
                                                  </Typography>
                                                )}
                                              </TableCell>
                                            ),
                                          )}
                                        </TableRow>
                                      )
                                    },
                                  )}
                                </TableBody>
                              </Table>
                            ) : (
                              <Typography
                                sx={{ color: isLightMode ? 'black' : 'white' }}
                              >
                                No data available
                              </Typography>
                            )}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <Typography>Loading...</Typography>
        </Box>
      )}
    </Box>
  )
}

export default ExpandableTable
