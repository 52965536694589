import { axiosInstance } from './axios.service'
import { IResponse } from '../types/response.type'
import { SchedulerEndpoints } from '../types/endpoint.type'
import { IScheduler, SchedulerFrequencies } from '../types/scheduler.type'

export const getAllSchedulers = async (
  id: number,
  token: string,
): Promise<IResponse<IScheduler[]>> => {
  try {
    const data = await axiosInstance.get(SchedulerEndpoints.GET + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createScheduler = async (
  frequency: SchedulerFrequencies,
  playbookId: number,
  token: string,
): Promise<IResponse<IScheduler>> => {
  try {
    const data = await axiosInstance.post(
      SchedulerEndpoints.CREATE,
      {
        frequency,
        playbookId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err.message,
    }
  }
}

export const updateScheduler = async (
  scheduler: IScheduler,
  token: string,
): Promise<IResponse<IScheduler[]>> => {
  try {
    const data = await axiosInstance.put(
      SchedulerEndpoints.UPDATE + scheduler.id,
      scheduler,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteScheduler = async (
  id: number,
  token: string,
): Promise<IResponse<IScheduler[]>> => {
  try {
    const data = await axiosInstance.delete(SchedulerEndpoints.DELETE + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}
