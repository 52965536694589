import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import CloseIcon from '@mui/icons-material/Close'
import styles from './styles.module.scss'
import {
  getColumnValues,
  getTableColumns,
} from '../../../../../services/requests.service'
import { initialFilter } from '../../../../../utils/constants'
import { INodeData } from '../../../../../types/node.type'
import { IColumn, IFilters } from '../../../../../types/requestId.type'

interface IProps {
  data: INodeData
  isLightMode: boolean
  isEditing?: boolean
  initialFilters: IFilters[]
  initialMethod?: string
  setData: Dispatch<SetStateAction<INodeData>>
}

const RequestInfo: FC<IProps> = ({
  data,
  isLightMode,
  isEditing,
  initialMethod,
  initialFilters,
  setData,
}) => {
  const [method, setMethod] = useState(initialMethod || 'Get Data')
  const [watchColumn, setWatchColumn] = useState('')
  const [columns, setColumns] = useState<IColumn[]>([])
  const [updateColumn, setUpdateColumn] = useState('')
  const [newValue, setNewValue] = useState<string>('')
  const [chosenTable, setChosenTable] = useState<string | undefined>(undefined)
  const [filters, setFilters] = useState<IFilters[]>(initialFilters)

  const { getAccessTokenSilently } = useAuth0()

  const handleTableChange = async (tableName: string) => {
    setChosenTable(tableName)

    const data = await getTableColumns(
      tableName,
      await getAccessTokenSilently(),
    )

    if (data.success && data.data) {
      setColumns([...data.data])
    }
  }

  const handleColumnChange = async (columnName: string, index: number) => {
    setFilters((prev) =>
      prev.map((filter, ind) =>
        ind === index ? { ...filter, column: columnName } : filter,
      ),
    )

    if (chosenTable) {
      const data = await getColumnValues(
        chosenTable,
        columnName,
        await getAccessTokenSilently(),
      )

      if (data.success && data.data) {
        const values = data.data

        setFilters((prev) =>
          prev.map((filter, ind) =>
            ind === index ? { ...filter, values } : filter,
          ),
        )
      }
    }
  }

  const handleValueChange = (values: Array<string | number>, index: number) => {
    setFilters((prev) =>
      prev.map((filter, ind) =>
        ind === index ? { ...filter, selectedValues: values } : filter,
      ),
    )
  }

  const handleOperationChange = (value: string, index: number) => {
    setFilters(
      filters.map((filter, ind) =>
        ind === index ? { ...filter, logicalOperation: value } : filter,
      ),
    )
  }

  const handleDelete = (index: number) => {
    setFilters(filters.filter((_, ind) => ind !== index))
  }

  const addNewFilter = () => {
    setFilters([...filters, initialFilter])
  }

  const handleWatchColumn = (column: string) => {
    setWatchColumn(column)
    setData((prev) => ({ ...prev, watchColumn: column }))
  }

  const handleUpdateColumn = (column: string) => {
    setUpdateColumn(column)
    setData((prev) => ({ ...prev, updateColumn: column }))
  }

  const handleNewValue = (value: string) => {
    setNewValue(value)
    setData((prev) => ({ ...prev, newValue: value }))
  }

  useEffect(() => {
    handleTableChange('exercise_request')
  }, [])

  useEffect(() => {
    if (
      chosenTable &&
      filters.length &&
      filters.every((filter) => filter.selectedValues.length && filter.column)
    ) {
      setData((prev) => ({
        ...prev,
        tableName: chosenTable,
        requestMethod: method,
        filters,
      }))
    }
  }, [filters, method])

  useEffect(() => {
    if (data.filters && data.method) {
      setMethod(data.method)
      setFilters(data.filters)
      setChosenTable(data.tableName)
    }
  }, [])

  return (
    <Box
      className={styles.requestInfo}
      draggable
      sx={{ borderColor: isLightMode ? 'black' : 'white' }}
    >
      <Autocomplete
        noOptionsText="Nothing was found"
        disabled={!isEditing}
        className={styles.select}
        options={['exercise_request']}
        onChange={(_, value) => handleTableChange(value || '')}
        value={'exercise_request'}
        renderInput={(params) => <TextField {...params} label="Tables" />}
      />
      <Autocomplete
        noOptionsText="Nothing was found"
        className={styles.select}
        options={['Get Data', 'Update Data', 'Watch']}
        onChange={(_, value) => setMethod(value as string)}
        disabled={!isEditing}
        value={method}
        renderInput={(params) => <TextField {...params} label="Actions" />}
      />
      {chosenTable && (
        <Box className={styles.filters}>
          <Typography>Filter By</Typography>
          {filters?.map((filter, ind) => (
            <Box key={filter.column} className={styles.filter}>
              <Box className={styles.header}>
                <Typography>
                  {filter.column
                    ? `Filter ${filter.column}`
                    : `Filter ${ind + 1}`}
                </Typography>
                {isEditing && (
                  <IconButton onClick={() => handleDelete(ind)}>
                    <CloseIcon className={styles.closeIcon} />
                  </IconButton>
                )}
              </Box>
              <Autocomplete
                noOptionsText="Nothing was found"
                className={styles.select}
                options={columns.map((column) => column.column_name)}
                onChange={(_, value) => handleColumnChange(value || '', ind)}
                value={filter.column}
                disabled={!isEditing}
                renderInput={(params) => (
                  <TextField {...params} label="Columns" />
                )}
              />
              <Autocomplete
                noOptionsText="Nothing was found"
                className={styles.operations}
                options={['Or', 'And']}
                onChange={(_, value) =>
                  handleOperationChange(value as string, ind)
                }
                value={filter.logicalOperation}
                disabled={!isEditing}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} label="Operations" />
                )}
              />
              <Autocomplete
                noOptionsText="Nothing was found"
                multiple
                disableCloseOnSelect
                className={styles.select}
                disabled={!isEditing}
                options={filter.values}
                onChange={(_, values) => handleValueChange(values, ind)}
                value={filter.selectedValues}
                getOptionLabel={(option) => `${option}`}
                renderInput={(params) => (
                  <TextField {...params} label="Values" />
                )}
              />
            </Box>
          ))}
          {isEditing && (
            <Button
              style={
                isLightMode
                  ? { backgroundColor: 'white', color: '' }
                  : { backgroundColor: '#04111a', color: 'white' }
              }
              onClick={addNewFilter}
            >
              Add Filter
            </Button>
          )}
        </Box>
      )}
      {method === 'Watch' && (
        <Box>
          <Typography className={styles.watchTitle}>Watch a column</Typography>
          <Autocomplete
            noOptionsText="Nothing was found"
            disabled={!isEditing}
            className={styles.select}
            options={columns.map((column) => column.column_name)}
            onChange={(_, value) => handleWatchColumn(value || '')}
            value={watchColumn}
            renderInput={(params) => <TextField {...params} label="Columns" />}
          />
        </Box>
      )}
      {method === 'Update Data' && (
        <Box>
          <Typography className={styles.watchTitle}>Update a column</Typography>
          <Autocomplete
            noOptionsText="Nothing was found"
            disabled={!isEditing}
            className={styles.select}
            options={columns.map((column) => column.column_name)}
            onChange={(_, value) => handleUpdateColumn(value || '')}
            value={updateColumn}
            renderInput={(params) => <TextField {...params} label="Columns" />}
          />
          <TextField
            disabled={!isEditing}
            label="New Value"
            value={newValue}
            className={styles.newValueInp}
            onChange={(evt) => handleNewValue(evt.target.value)}
          />
        </Box>
      )}
    </Box>
  )
}

export default RequestInfo
