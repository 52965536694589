export enum TriggerRuleTypes {
  RADIUS = 'radius',
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',
  INTERFERENCE = 'interference',
}

export interface TriggerRule {
  [TriggerRuleTypes.RADIUS]: number
  [TriggerRuleTypes.LATITUDE]: number
  [TriggerRuleTypes.LONGITUDE]: number
  [TriggerRuleTypes.INTERFERENCE]: number
}
