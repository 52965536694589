import { FC, useState } from 'react'
import dayjs from 'dayjs'
import {
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  ISchedulerOptions,
  SchedulerFrequencies,
} from '../../../../types/scheduler.type'
import DatePickerComponent from '../../../shared/DatePicker'

import styles from './styles.module.scss'

interface IProps {
  isOpen: boolean
  playbookName?: string
  isLightMode: boolean
  onClose: () => void
  handleSchedule: (schedulerOptions: ISchedulerOptions) => void
}

const PlaybookScheduler: FC<IProps> = ({
  isOpen,
  playbookName,
  isLightMode,
  onClose,
  handleSchedule,
}) => {
  const [schedulerOptions, setSchedulerOptions] = useState({
    time: dayjs(new Date()),
    date: dayjs(new Date()),
    frequency: SchedulerFrequencies.MINUTE,
  })

  return (
    <Modal className={styles.modal} open={isOpen} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box>
          <Box className={styles.header}>
            <Typography data-testid="title" className={styles.title}>
              Playbook: {playbookName} scheduler
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.content}>
            <DatePickerComponent
              time={schedulerOptions.time}
              date={schedulerOptions.date}
              isLightMode={isLightMode}
              handleDateChange={(newDate) =>
                setSchedulerOptions({
                  ...schedulerOptions,
                  date: newDate,
                })
              }
              handleTimeChange={(newTime) =>
                setSchedulerOptions({
                  ...schedulerOptions,
                  time: newTime,
                })
              }
            />
            <FormControl sx={{ display: 'flex' }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Every Day"
                name="radio-buttons-group"
                onChange={(_, value) =>
                  setSchedulerOptions({
                    ...schedulerOptions,
                    frequency: value as SchedulerFrequencies,
                  })
                }
                sx={{
                  display: 'flex !important',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <FormControlLabel
                  className={styles.lol}
                  value={SchedulerFrequencies.MINUTE}
                  control={<Radio />}
                  label="Every Minute"
                />
                <FormControlLabel
                  value={SchedulerFrequencies.HOUR}
                  className={styles.lol}
                  control={<Radio />}
                  label="Every Hour"
                />
                <FormControlLabel
                  value={SchedulerFrequencies.DAY}
                  className={styles.lol}
                  control={<Radio />}
                  label="Every Day"
                />
                <FormControlLabel
                  value={SchedulerFrequencies.WEEK}
                  className={styles.lol}
                  control={<Radio />}
                  label="Every Week"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box className={styles.actions}>
            <Button
              className={styles.scheduleBtn}
              color={'success'}
              onClick={() =>
                handleSchedule({
                  date: schedulerOptions.date.format('DD.MM.YYYY'),
                  time: schedulerOptions.time.format('HH:mm'),
                  frequency: schedulerOptions.frequency,
                })
              }
            >
              Schedule
            </Button>
            <Button
              className={styles.cancelBtn}
              color="inherit"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default PlaybookScheduler
