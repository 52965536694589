import { FC, useState } from 'react'
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'
import { ClearIcon } from '@mui/x-date-pickers'

import styles from './styles.module.scss'

interface IProps {
  isLightMode: boolean
  handleSearch: (value: string) => void
}

const Search: FC<IProps> = ({ isLightMode, handleSearch }) => {
  const [search, setSearch] = useState('')

  const handleClear = () => {
    setSearch('')
    handleSearch('')
  }

  const handleChange = (value: string) => {
    handleSearch(value)
    setSearch(value)
  }

  return (
    <Box className={styles.search}>
      <TextField
        fullWidth
        value={search}
        data-testid="searchTextField"
        label="Search"
        style={{ backgroundColor: isLightMode ? 'white' : '#13222e' }}
        onChange={(evt) => handleChange(evt.target.value)}
        inputProps={{ 'data-testid': 'search' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClear} data-testid="clearButton">
                <ClearIcon sx={{ color: isLightMode ? 'black' : 'white' }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}

export default Search
