import { FC, useState, Dispatch, useContext, SetStateAction } from 'react'
import { toast } from 'react-toastify'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Button,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import {
  updateEquipmentById,
  deleteEquipmentById,
  createExerciseEquipment,
  createExerciseRequestStationClass,
  createExerciseRequestDeviceAntenna,
  deleteExerciseRequestStationClassById,
  updateExerciseRequestStationClassById,
  deleteExerciseRequestDeviceAntennaById,
  updateExerciseRequestDeviceAntennaById,
} from '../../../../services/equipment.service'
import SectionLayout from '../SectionLayout'
import { AntennaInfo } from '../AntennaInfo'
import { StationClass } from '../StationClass'
import { SelectComponent } from '../../../shared/SelectComponent'
import { SelectWithSearch } from '../../../shared/SearchSelect'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import { ExerciseRequestEquipmentContext } from '../../../../contexts/exercise-request-equipment.context'

import styles from '../SectionLayout/styles.module.scss'

interface IProps {
  setValue: Dispatch<SetStateAction<number>>
  isLightMode: boolean
}

const EquipmentSection: FC<IProps> = ({ isLightMode, setValue }) => {
  const [isUpdate, setIsUpdate] = useState(false)
  const [isDelete, setIsDelete] = useState(false)

  const {
    selectedExerciseRequest,
    exerciseRequestEquipments,
    exerciseRequestFrequencies,
    setExerciseRequestEquipments,
  } = useContext(ExerciseRequestContext)

  const {
    equipment,
    setEquipment,
    emissionTypes,
    manufacturers,
    deviceAntennas,
    stationClasses,
    radarTunabilityTypes,
    stationClassDescriptions,
    emissionDesignatorSimpleTypes,
    exerciseRequestStationClasses,
    exerciseRequestDeviceAntennas,
    setExerciseRequestDeviceAntennas,
    setExerciseRequestStationClasses,
  } = useContext(ExerciseRequestEquipmentContext)

  const { devices, nomenclatures, numberOfStations, operatingUnits } =
    useContext(SpectrumManagerContext)

  const { getAccessTokenSilently } = useAuth0()

  const handleClose = () => {
    setIsUpdate(false)
    setIsDelete(false)
  }

  const handleAdd = async () => {
    if (selectedExerciseRequest) {
      const data = await createExerciseEquipment(
        await getAccessTokenSilently(),
        selectedExerciseRequest.id,
      )

      if (data.success && data.data) {
        setExerciseRequestEquipments([...exerciseRequestEquipments, data.data])
        toast('Equipment created successfully', { type: 'success' })
      }
    }
  }

  const handleSave = async () => {
    if (equipment) {
      const data = await updateEquipmentById(
        await getAccessTokenSilently(),
        equipment.id,
        equipment,
      )

      if (data.success && data.data) {
        setExerciseRequestEquipments(
          exerciseRequestEquipments.map((equipment) =>
            data.data && equipment.id === data.data.id
              ? { ...equipment, ...data.data }
              : equipment,
          ),
        )
        toast('Equipment updated successfully', { type: 'success' })

        for (const stationClass of exerciseRequestStationClasses) {
          await updateExerciseRequestStationClassById(
            await getAccessTokenSilently(),
            stationClass.id,
            stationClass,
          )
        }

        for (const deviceAntenna of exerciseRequestDeviceAntennas) {
          await updateExerciseRequestDeviceAntennaById(
            await getAccessTokenSilently(),
            deviceAntenna.id,
            deviceAntenna,
          )
        }
      }
    }
    handleClose()
  }

  const handleDelete = async () => {
    if (equipment) {
      const data = await deleteEquipmentById(
        await getAccessTokenSilently(),
        equipment.id,
      )

      if (data.success) {
        setExerciseRequestEquipments(
          exerciseRequestEquipments.filter(
            (equipment) => equipment.id !== equipment.id,
          ),
        )
        toast('Frequency removed successfully', { type: 'success' })
        setEquipment(null)
      }
    }
    handleClose()
  }

  const handleEquipmentChange = (
    key: string,
    value: string | number | boolean | null,
  ) => {
    if (equipment) {
      setEquipment({ ...equipment, [key]: value })
    }
  }

  const handleAddStationClass = async () => {
    if (equipment) {
      const data = await createExerciseRequestStationClass(
        await getAccessTokenSilently(),
        equipment.id,
      )

      if (data.success && data.data) {
        setExerciseRequestStationClasses([
          ...exerciseRequestStationClasses,
          data.data,
        ])
      }
    }
  }

  const handleAntennaAdd = async () => {
    if (equipment) {
      const data = await createExerciseRequestDeviceAntenna(
        await getAccessTokenSilently(),
        equipment.id,
      )

      if (data.success && data.data) {
        setExerciseRequestDeviceAntennas([
          ...exerciseRequestDeviceAntennas,
          data.data,
        ])
      }
    }
  }

  const handleDeleteStationClass = async (id: number) => {
    const data = await deleteExerciseRequestStationClassById(
      await getAccessTokenSilently(),
      id,
    )

    if (data.success) {
      setExerciseRequestStationClasses(
        exerciseRequestStationClasses.filter(
          (stationClass) => stationClass.id !== id,
        ),
      )
    }
  }

  const handleDeleteDeviceAntenna = async (id: number) => {
    const data = await deleteExerciseRequestDeviceAntennaById(
      await getAccessTokenSilently(),
      id,
    )

    if (data.success) {
      setExerciseRequestDeviceAntennas(
        exerciseRequestDeviceAntennas.filter(
          (deviceAntenna) => deviceAntenna.id !== id,
        ),
      )
    }
  }

  const handleStationClassChange = (
    id: number,
    key: string,
    value: string | number,
  ) => {
    const updatedStationClasses = exerciseRequestStationClasses.map(
      (stationClass) =>
        stationClass.id === id
          ? { ...stationClass, [key]: value }
          : stationClass,
    )

    setExerciseRequestStationClasses(updatedStationClasses)
  }

  const handleAntennaChange = (
    id: number,
    key: string,
    value: string | number,
  ) => {
    const updatedDeviceAntennas = exerciseRequestDeviceAntennas.map(
      (deviceAntenna) =>
        deviceAntenna.id === id
          ? { ...deviceAntenna, [key]: value }
          : deviceAntenna,
    )

    setExerciseRequestDeviceAntennas(updatedDeviceAntennas)
  }

  return (
    <SectionLayout
      sectionTitle="Equipment"
      btnText="Add Equipment"
      handleAdd={handleAdd}
      isUpdate={isUpdate}
      isDelete={isDelete}
      handleClose={handleClose}
      handleSave={handleSave}
      handleDelete={handleDelete}
      setIsDelete={setIsDelete}
      setIsUpdate={setIsUpdate}
      isLightMode={isLightMode}
      setValue={setValue}
      updateText={'Are you sure you want to update this equipment?'}
      deleteText={'Are you sure you want to delete this equipment?'}
      headerItems={
        <>
          <SelectComponent
            isLightMode={isLightMode}
            label="Frequency"
            value={equipment?.exercise_request_frequency_id}
            className={styles.field}
            items={exerciseRequestFrequencies.map((frequency) => ({
              value: frequency.id,
              displayValue: frequency.platform,
            }))}
            handleChange={(value) =>
              handleEquipmentChange(
                'exercise_request_frequency_id',
                Number(value),
              )
            }
          />
        </>
      }
      isSelected={!!equipment}
      sidebarItems={
        <Box className={styles.detailedContainer}>
          {exerciseRequestEquipments.map((elem) => (
            <Box
              onClick={() => setEquipment(elem)}
              className={
                elem.id === equipment?.id
                  ? isLightMode
                    ? styles.item
                    : styles.selectedItem
                  : isLightMode
                    ? styles.lightItem
                    : styles.item
              }
            >
              {elem.id === equipment?.id && (
                <CircleIcon
                  sx={{ color: 'green', height: 15, width: 15 }}
                  className={styles.circle}
                />
              )}
              <Box className={styles.details}>
                <Typography>Name:</Typography>
                <Typography>{elem.equipment_name}</Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Frequency:</Typography>
                <Typography>
                  {elem.start_freq} {elem.start_indicator}
                </Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Location:</Typography>
                <Typography>{elem.transmitter_location}</Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Receiving Unit:</Typography>
                <Typography>{elem.receiving_operating_unit_name}</Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Station Config:</Typography>
                <Typography>{elem.station_class_type}</Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Major Fn:</Typography>
                <Typography>{elem.major_fn_name}</Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Detailed Fn:</Typography>
                <Typography>{elem.detailed_fn_name}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      }
      fieldItems={
        equipment
          ? [
              <Box className={styles.section}>
                <Box className={styles.detailsSubSection}>
                  <Box className={styles.fieldContainer}>
                    <Typography className={styles.label}>
                      Send/Receive:
                    </Typography>
                    <Select
                      className={styles.field}
                      onChange={(evt) =>
                        handleEquipmentChange(
                          'send_receive',
                          evt.target.value || 'Send and Receive',
                        )
                      }
                      value={equipment.send_receive}
                    >
                      {['Send and Receive', 'Send Only', 'Receive Only'].map(
                        (elem) => (
                          <MenuItem key={elem} value={elem}>
                            {elem}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </Box>
                  <Box className={styles.fieldContainer}>
                    <Typography className={styles.label}>Device:</Typography>
                    <SelectWithSearch
                      width={350}
                      value={devices
                        .map((device) => ({
                          label: device.model,
                          value: device.id,
                        }))
                        .find((elem) => elem.value === equipment.device_id)}
                      label="Devices"
                      handleChange={(value) =>
                        handleEquipmentChange('device_id', Number(value))
                      }
                      options={devices.map((device) => ({
                        value: device.id,
                        label: device.model,
                      }))}
                    />
                  </Box>
                  <Box className={styles.fieldContainer}>
                    <Typography className={styles.label}>
                      Number of Station:
                    </Typography>
                    <SelectComponent
                      isLightMode={isLightMode}
                      label="Number of Station"
                      value={equipment.number_of_stations_id}
                      className={styles.field}
                      items={numberOfStations.map((numberOfStation) => ({
                        value: numberOfStation.id,
                        displayValue: numberOfStation.code,
                      }))}
                      handleChange={(value) =>
                        handleEquipmentChange(
                          'number_of_stations_id',
                          Number(value),
                        )
                      }
                    />
                  </Box>
                  <Box className={styles.fieldContainer} hidden>
                    <Typography className={styles.label}>
                      System Name
                    </Typography>
                    <SelectComponent
                      isLightMode={isLightMode}
                      value={equipment.number_of_stations_other}
                      className={styles.field}
                      items={numberOfStations.map((numberOfStation) => ({
                        value: numberOfStation.id,
                        displayValue: numberOfStation.code,
                      }))}
                      handleChange={(value) =>
                        handleEquipmentChange(
                          'number_of_stations_other',
                          Number(value),
                        )
                      }
                    />
                  </Box>
                </Box>
                {exerciseRequestStationClasses.map((stationClass) => (
                  <StationClass
                    isLightMode={isLightMode}
                    stationClass={stationClass}
                    handleDelete={handleDeleteStationClass}
                    handleChange={handleStationClassChange}
                    emissionTypes={emissionTypes}
                    stationClasses={stationClasses}
                    operatingUnits={operatingUnits}
                    stationClassDescriptions={stationClassDescriptions}
                    emissionDesignatorSimpleTypes={
                      emissionDesignatorSimpleTypes
                    }
                  />
                ))}
                <Button
                  color="success"
                  variant="contained"
                  className={styles.addFreq}
                  onClick={handleAddStationClass}
                >
                  Add a station class
                </Button>
                <Box className={styles.detailsSubSection} hidden>
                  <Box className={styles.fieldContainer}>
                    <Typography className={styles.label}>
                      Equipment Nomenclature:
                    </Typography>
                    <SelectComponent
                      isLightMode={isLightMode}
                      value={equipment.equpment_nomeclature_id}
                      className={styles.field}
                      items={nomenclatures.map((nomenclature) => ({
                        value: nomenclature.id,
                        displayValue: nomenclature.name,
                      }))}
                      handleChange={(value) =>
                        handleEquipmentChange(
                          'equpment_nomeclature_id',
                          Number(value),
                        )
                      }
                    />
                  </Box>
                </Box>

                {exerciseRequestDeviceAntennas.map((deviceAntenna) => (
                  <AntennaInfo
                    isLightMode
                    antennaInfo={deviceAntenna}
                    deviceAntennas={deviceAntennas}
                    handleDelete={handleDeleteDeviceAntenna}
                    handleChange={handleAntennaChange}
                  />
                ))}
                <Button
                  color="success"
                  variant="contained"
                  className={styles.addFreq}
                  onClick={handleAntennaAdd}
                >
                  Add an Antenna
                </Button>
                <Box className={styles.detailsSubSection} hidden>
                  <Box className={styles.fieldContainer}>
                    <Typography className={styles.label}>
                      Equipment Certification Identification Number:
                    </Typography>
                    <TextField
                      className={styles.field}
                      value={equipment.equipment_certification_id_number}
                      onChange={(evt) =>
                        handleEquipmentChange(
                          'equipment_certification_id_number',
                          evt.target.value,
                        )
                      }
                    />
                  </Box>
                </Box>
                <Box className={styles.detailsSubSection} hidden>
                  <Box className={styles.fieldContainer}>
                    <SelectComponent
                      isLightMode={isLightMode}
                      value={equipment.manufacturer_code_type_id}
                      className={styles.field}
                      items={manufacturers.map((manufacturer) => ({
                        value: manufacturer.id,
                        displayValue: manufacturer.name,
                      }))}
                      handleChange={(value) =>
                        handleEquipmentChange(
                          'manufacturer_code_type_id',
                          Number(value),
                        )
                      }
                    />
                    <TextField
                      value={equipment.model_number}
                      onChange={(evt) =>
                        handleEquipmentChange('model_number', evt.target.value)
                      }
                    />
                  </Box>
                </Box>
              </Box>,
              <Box className={styles.section}>
                <Box className={styles.detailsSubSection} hidden>
                  <Box className={styles.fieldContainer}>
                    <Typography className={styles.label}>
                      International Call Sign / NAVAIDS Identifier:
                    </Typography>
                    <TextField
                      className={styles.field}
                      value={equipment.international_callsign}
                      onChange={(evt) =>
                        handleEquipmentChange(
                          'international_callsign',
                          evt.target.value,
                        )
                      }
                    />
                  </Box>
                </Box>
                <Box className={styles.detailsSubSection}>
                  <Box className={styles.fieldContainer}>
                    <Box></Box>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Typography>Show Radar Characteristics: </Typography>
                      <Checkbox
                        checked={equipment.is_radar_chars}
                        onChange={(_, checked) =>
                          handleEquipmentChange('is_radar_chars', checked)
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>,
              equipment.is_radar_chars ? (
                <Box className={styles.section}>
                  <Box className={styles.titleContainer}>
                    <Typography className={styles.title}>
                      Radar Characteristics
                    </Typography>
                  </Box>
                  <Box className={styles.subSection}>
                    <Box className={styles.fieldContainer}>
                      <Typography className={styles.label}>
                        Tunability:
                      </Typography>
                      <SelectComponent
                        isLightMode={isLightMode}
                        label="Tunability"
                        value={equipment.radar_tunability_type_id}
                        className={styles.field}
                        items={radarTunabilityTypes.map((tunability) => ({
                          value: tunability.id,
                          displayValue: tunability.name,
                        }))}
                        handleChange={(value) =>
                          handleEquipmentChange(
                            'radar_tunability_type_id',
                            Number(value),
                          )
                        }
                      />
                    </Box>
                    <Box className={styles.fieldContainer}>
                      <Typography className={styles.label}>
                        Pulse duration (Width):
                      </Typography>
                      <TextField
                        label="Pulse duration"
                        value={equipment.radar_pulse_duration}
                        className={styles.field}
                        onChange={(evt) =>
                          handleEquipmentChange(
                            'radar_pulse_duration',
                            Number(evt.target.value),
                          )
                        }
                      />
                    </Box>
                    <Box className={styles.fieldContainer}>
                      <Typography className={styles.label}>
                        Pulse repetition rate:
                      </Typography>
                      <TextField
                        label="Pulse repetition rate"
                        value={equipment.radar_pulse_repetition_rate}
                        className={styles.field}
                        onChange={(evt) =>
                          handleEquipmentChange(
                            'radar_pulse_repetition_rate',
                            Number(evt.target.value),
                          )
                        }
                      />
                    </Box>
                    <Box className={styles.fieldContainer}>
                      <Typography className={styles.label}>
                        Intermediate Frequency:
                      </Typography>
                      <Box
                        className={styles.field}
                        style={{ display: 'flex', gap: 10 }}
                      >
                        <TextField
                          value={equipment.radar_intermediate_frequency}
                          fullWidth
                          label="Intermediate Frequency"
                          className={styles.field}
                          onChange={(evt) =>
                            handleEquipmentChange(
                              'radar_intermediate_frequency',
                              Number(evt.target.value),
                            )
                          }
                        />
                        <Select
                          value={
                            equipment.radar_intermediate_frequency_indicator
                          }
                          onChange={(evt) =>
                            handleEquipmentChange(
                              'radar_intermediate_frequency_indicator',
                              evt.target.value,
                            )
                          }
                        >
                          {['K', 'M', 'G', 'T'].map((elem) => (
                            <MenuItem key={elem} value={elem}>
                              {elem}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                    <Box className={styles.fieldContainer}>
                      <Typography className={styles.label}>
                        Sidelobe Suppression:
                      </Typography>
                      <SelectComponent
                        isLightMode={isLightMode}
                        label="Sidelobe Suppression"
                        value={equipment.radar_sidelobe_suppression}
                        className={styles.field}
                        items={['Y', 'N'].map((elem) => ({
                          value: elem,
                          displayValue: elem,
                        }))}
                        handleChange={(value) =>
                          handleEquipmentChange(
                            'radar_sidelobe_suppression',
                            Number(value),
                          )
                        }
                      />
                    </Box>
                    <Box className={styles.fieldContainer}>
                      <Typography className={styles.label}>Power:</Typography>
                      <TextField
                        label="Power"
                        value={equipment.radar_power}
                        className={styles.field}
                        onChange={(evt) =>
                          handleEquipmentChange(
                            'radar_power',
                            Number(evt.target.value),
                          )
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              ) : (
                <></>
              ),
            ]
          : []
      }
    />
  )
}

export default EquipmentSection
