import {
  FC,
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from 'react'
import { Edge } from '@xyflow/react'
import CloseIcon from '@mui/icons-material/Close'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { useToast } from '../../../../contexts/toast.context'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { SelectComponent } from '../../../shared/SelectComponent'
import {
  getAllAgencyOverrides,
  getAllHopsets,
  getAllSFAFAntennas,
  getAllSFAFContents,
  getAllSFAFEquipments,
  getAllSFAFExcludedFrequencies,
  getAllSFAFFrequencies,
  getAllSFAFStationClasses,
  getAssignedFrequenciesByFrequencyId,
  getEquipmentsByFrequencyId,
  getFrequencyById,
  updateAssignedFrequencyById,
  updateEquipmentById,
  updateExerciseRequestStatus,
  updateFrequencyById,
} from '../../../../services/spectrum-manager.service'
import { IExerciseRequestFrequency } from '../../../../types/exercise.type'
import { IExerciseRequestEquipment } from '../../../../types/equipment.type'
import {
  IAssignedFrequency,
  IExerciseRequestDetails,
} from '../../../../types/spectrum-manager.type'
import { generateSFAF } from '../../../../utils/sfaf-generator'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'

import styles from './styles.module.scss'

interface IProps {
  isOpen: boolean
  request: IExerciseRequestDetails
  isEditing?: boolean
  handleClose: () => void
}

const SFAFDrawer: FC<IProps> = ({ isOpen, request, handleClose }) => {
  const [sfaf, setSfaf] = useState('')
  const [frequency, setFrequency] = useState<IExerciseRequestFrequency | null>(
    null,
  )
  const [equipments, setEquipments] = useState<IExerciseRequestEquipment[]>([])
  const [assignedFrequencies, setAssignedFrequencies] = useState<
    IAssignedFrequency[]
  >([])

  const { showToast } = useToast()
  const { isLightMode } = useContext(PlaybookContext)
  const { filterOptions } = useContext(SpectrumManagerContext)
  const { getAccessTokenSilently } = useAuth0()

  const linkRef = useRef<HTMLAnchorElement>(null)

  const onClose = () => {
    handleClose()
  }

  const handleExport = () => {
    if (linkRef.current && sfaf) {
      const file = new Blob([sfaf], { type: 'text/plain' })
      linkRef.current.href = URL.createObjectURL(file)
      linkRef.current.download = 'sfaf.txt'

      linkRef.current.click()
    }
  }

  const handleChange = async (
    type: string,
    id: number,
    key: string,
    value: string | number,
  ) => {
    const token = await getAccessTokenSilently()

    if (type === 'assigned freq') {
      const updatedFrequencies = assignedFrequencies.map((assignedFreq) =>
        id === assignedFreq.id
          ? {
              ...assignedFreq,
              [key]: value,
            }
          : assignedFreq,
      )

      setAssignedFrequencies(updatedFrequencies)

      for (const assignedFreq of updatedFrequencies) {
        await updateAssignedFrequencyById(token, assignedFreq.id, assignedFreq)
      }
    } else if (type === 'equipment') {
      const updatedEquipments = equipments.map((equipment) =>
        id === equipment.id
          ? {
              ...equipment,
              [key]: value,
            }
          : equipment,
      )

      setEquipments(updatedEquipments)

      for (const equipment of updatedEquipments) {
        await updateEquipmentById(token, equipment.id, equipment)
      }
    } else if (type === 'freq' && frequency) {
      const updatedFrequency = {
        ...frequency,
        [key]: value,
      }

      setFrequency(updatedFrequency)
      await updateFrequencyById(token, updatedFrequency.id, updatedFrequency)
    } else if (type === 'request') {
      await updateExerciseRequestStatus(token, id, value as string)
    }
  }

  const handleProcessAll = async () => {
    for (const assignedFreq of assignedFrequencies) {
      await updateAssignedFrequencyById(
        await getAccessTokenSilently(),
        assignedFreq.id,
        { ...assignedFreq, status: 'ACCEPTED' },
      )
    }
  }

  const getFrequency = async () => {
    const data = await getFrequencyById(
      await getAccessTokenSilently(),
      request.id_inner,
    )

    if (data.data && data.success) {
      setFrequency(data.data)
    }
  }

  const getAssignedFrequencies = async () => {
    const data = await getAssignedFrequenciesByFrequencyId(
      await getAccessTokenSilently(),
      request.id_inner,
    )

    if (data.data && data.success) {
      setAssignedFrequencies(data.data)
    }
  }

  const getEquipments = async () => {
    const data = await getEquipmentsByFrequencyId(
      await getAccessTokenSilently(),
      request.id_inner,
    )

    if (data.data && data.success) {
      setEquipments(data.data)
    }
  }

  const handleSFAFGenerate = async () => {
    const token = await getAccessTokenSilently()

    const sfafAntennas = await getAllSFAFAntennas(token, filterOptions)
    const sfafContents = await getAllSFAFContents(token, filterOptions)
    const sfafEquipments = await getAllSFAFEquipments(token, filterOptions)
    const sfafFrequencies = await getAllSFAFFrequencies(token, filterOptions)
    const sfafStationClasses = await getAllSFAFStationClasses(
      token,
      filterOptions,
    )
    const sfafExcludedFrequencies = await getAllSFAFExcludedFrequencies(
      token,
      filterOptions,
    )
    const sfafAgencyOverrides = await getAllAgencyOverrides(token, 1)
    const hopsets = await getAllHopsets(token)

    if (
      sfafFrequencies.data &&
      sfafEquipments.data &&
      sfafContents.data &&
      sfafStationClasses.data &&
      sfafExcludedFrequencies.data &&
      sfafAntennas.data &&
      hopsets.data
    ) {
      const sfaf = await generateSFAF(
        'single',
        token,
        sfafFrequencies.data,
        sfafContents.data,
        sfafAgencyOverrides.data,
        sfafExcludedFrequencies.data,
        sfafEquipments.data,
        hopsets.data,
        request.id_inner,
        sfafStationClasses.data,
        sfafAntennas.data,
      )

      setSfaf(sfaf)
    }
  }

  useEffect(() => {
    if (request.id_inner) {
      getFrequency()
      getEquipments()
      getAssignedFrequencies()
    }
  }, [request.id_inner])

  return (
    <Drawer
      className={styles.drawer}
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: isLightMode ? 'white' : 'rgb(19, 34, 46) !important',
        },
      }}
      classes={{
        paper: styles.drawerPaper,
      }}
    >
      <Box className={styles.drawerHeader}>
        <IconButton onClick={onClose}>
          <CloseIcon className={styles.closeIcon} />
        </IconButton>
      </Box>
      <Box className={styles.drawerContent}>
        <Box className={styles.fieldContainer}>
          <Typography>Request Status</Typography>
          <SelectComponent
            isLightMode={isLightMode}
            items={['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED', 'TEST'].map(
              (value) => ({ value, displayValue: value }),
            )}
            defaultValue={request.status}
            className={styles.select}
            handleChange={(value) =>
              handleChange(
                'request',
                request.request_id_inner,
                'status',
                String(value),
              )
            }
          />
        </Box>
        <Box className={styles.section}>
          <Box className={styles.titleContainer}>
            <Typography className={styles.title}>
              Assign Frequencies - {assignedFrequencies[0]?.start_indicator}
              {assignedFrequencies[0]?.start_freq}
              {'-'}
              {assignedFrequencies[0]?.end_indicator}
              {assignedFrequencies[0]?.end_freq}
            </Typography>
            <Button
              className={styles.btn}
              variant="contained"
              onClick={handleProcessAll}
              sx={isLightMode ? {} : { backgroundColor: '#13222e' }}
            >
              Process All
            </Button>
          </Box>
          {assignedFrequencies.map((assignedFrequency) => (
            <Box className={styles.assignedFreq} key={assignedFrequency.id}>
              <TextField className={styles.input} value="AFSO24" />
              <TextField
                className={styles.input}
                defaultValue={assignedFrequency.frequency}
                onChange={(evt) =>
                  handleChange(
                    'assigned freq',
                    assignedFrequency.id,
                    'frequency',
                    evt.target.value,
                  )
                }
              />
              <TextField
                className={styles.input}
                defaultValue={assignedFrequency.list_serial_number}
                onChange={(evt) =>
                  handleChange(
                    'assigned freq',
                    assignedFrequency.id,
                    'list_serial_number',
                    evt.target.value,
                  )
                }
              />
              <TextField
                className={styles.input}
                defaultValue={assignedFrequency.f_114}
                onChange={(evt) =>
                  handleChange(
                    'assigned freq',
                    assignedFrequency.id,
                    'f_114',
                    evt.target.value,
                  )
                }
              />
              <SelectComponent
                isLightMode={isLightMode}
                items={['NOT_STARTED', 'ACCEPTED', 'APPROVED', 'DENIED'].map(
                  (value) => ({ value, displayValue: value }),
                )}
                defaultValue={assignedFrequency.status}
                className={styles.select}
                handleChange={(value) =>
                  handleChange(
                    'assigned freq',
                    assignedFrequency.id,
                    'status',
                    String(value),
                  )
                }
              />
            </Box>
          ))}
        </Box>
        <Box className={styles.section}>
          <Box className={styles.titleContainer}>
            <Typography className={styles.title}>
              Override/Admin Entries
            </Typography>
          </Box>
          {equipments.map((equipment) => (
            <Box className={styles.equipment}>
              <Box className={styles.titleContainer}>
                <Typography>340: {equipment.equipment_name}</Typography>
              </Box>
              <Box className={styles.fieldContainer}>
                <Typography>343</Typography>
                <TextField
                  defaultValue={equipment.f_343}
                  onChange={(evt) =>
                    handleChange(
                      'equipment',
                      equipment.id,
                      'f_343',
                      evt.target.value,
                    )
                  }
                />
              </Box>
              <Box className={styles.fieldContainer}>
                <Typography>501</Typography>
                <Box className={styles.f_501}>
                  <TextField
                    defaultValue={equipment.f_501_irac}
                    onChange={(evt) =>
                      handleChange(
                        'equipment',
                        equipment.id,
                        'f_501_irac',
                        evt.target.value,
                      )
                    }
                  />
                  <TextField
                    defaultValue={equipment.f_501_sps}
                    onChange={(evt) =>
                      handleChange(
                        'equipment',
                        equipment.id,
                        'f_501_sps',
                        evt.target.value,
                      )
                    }
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        {frequency && (
          <>
            <Box className={styles.antennaContainer}>
              <Typography>Antenna:</Typography>
              <Box className={styles.fields}>
                <TextField
                  defaultValue={frequency.f_354}
                  onChange={(evt) =>
                    handleChange(
                      'freq',
                      frequency.id,
                      'f_354',
                      evt.target.value,
                    )
                  }
                />
                <TextField
                  defaultValue={frequency.f_454}
                  onChange={(evt) =>
                    handleChange(
                      'freq',
                      frequency.id,
                      'f_454',
                      evt.target.value,
                    )
                  }
                />
              </Box>
            </Box>
            <Box className={styles.orientationContainer}>
              <Typography>Orientation:</Typography>
              <Box className={styles.fields}>
                <TextField
                  defaultValue={frequency.f_362}
                  onChange={(evt) =>
                    handleChange(
                      'freq',
                      frequency.id,
                      'f_362',
                      evt.target.value,
                    )
                  }
                />
                <TextField
                  defaultValue={frequency.f_462}
                  onChange={(evt) =>
                    handleChange(
                      'freq',
                      frequency.id,
                      'f_462',
                      evt.target.value,
                    )
                  }
                />
              </Box>
            </Box>
          </>
        )}
        <Box className={styles.actions}>
          <Button
            className={styles.generateBtn}
            variant="contained"
            onClick={() => handleSFAFGenerate()}
            sx={isLightMode ? {} : { backgroundColor: '#13222e' }}
          >
            Generate SFAF
          </Button>
          <Button
            className={styles.exportBtn}
            variant="contained"
            onClick={handleExport}
            sx={isLightMode ? {} : { backgroundColor: '#13222e' }}
          >
            Export SFAF
          </Button>
        </Box>
        <Box className={styles.sfafContainer}>
          <textarea
            placeholder="SFAF value"
            style={{
              fontSize: 14,
              width: '100%',
              height: sfaf ? '1000px' : '50px',
              color: isLightMode ? 'black' : 'white',
              backgroundColor: isLightMode ? 'white' : 'rgb(19, 34, 46)',
            }}
            value={sfaf}
          />
        </Box>
      </Box>

      <a ref={linkRef} hidden />
    </Drawer>
  )
}

export default SFAFDrawer
