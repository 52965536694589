import { Update, createBrowserHistory } from 'history'
import { useLayoutEffect, useReducer } from 'react'
import { Router } from 'react-router-dom'

export const history = createBrowserHistory({ window })
const reducer = (_: Update, action: Update) => action

interface IProps {
  children: JSX.Element
}

export const HistoryRouter: React.FC<IProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => history.listen(dispatch), [])

  return (
    <Router
      navigationType={state.action}
      location={state.location}
      navigator={history}
    >
      {children}
    </Router>
  )
}
