import { FC } from 'react'
import { Box } from '@mui/material'

interface PanelProps {
  children: JSX.Element
  value: number
  index: number
}

export const TabPanel: FC<PanelProps> = (props) => {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      style={{ height: '100%', width: '100%' }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}
