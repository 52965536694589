import { FC, useState } from 'react'
import { Box, Typography } from '@mui/material'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import EventBox from '../Event'
import { EventColor } from '../../../../utils/functions'
import { EventStatus, IEvent } from '../../../../types/event.type'

import styles from './styles.module.scss'

interface IProps {
  date: string
  runNumber: string
  events: IEvent[]
  isLightMode: boolean
  open: boolean
}

const EventsGroup: FC<IProps> = ({
  events,
  runNumber,
  open,
  date,
  isLightMode,
}) => {
  const [isOpen, setIsOpen] = useState(open)

  return (
    <Box
      className={styles.group}
      id="eventsGroup"
      data-testid="eventGroup"
      style={{
        borderColor: EventColor(EventStatus.SUCCESS, isLightMode),
      }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <Box className={styles.header}>
        <ArrowBackIosNewOutlinedIcon
          data-testid="arrowIcon"
          className={isOpen ? styles.arrow : styles.bottomArrow}
          style={{ color: EventColor(EventStatus.SUCCESS, isLightMode) }}
        />
        <Typography
          className={styles.title}
          style={{ color: EventColor(EventStatus.SUCCESS, isLightMode) }}
        >
          {date}: Playbook run N{runNumber}
        </Typography>
      </Box>
      {isOpen && (
        <Box className={styles.events} id="events">
          {events.map((event, ind) => (
            <EventBox
              key={event.text + ind}
              {...event}
              isLightMode={isLightMode}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}

export default EventsGroup
