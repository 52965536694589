import { Navigate, Route, Routes } from 'react-router'
import Playbook from '../pages/playbook'
import ResiumMap from '../pages/resium-map'
import { useAuth0 } from '@auth0/auth0-react'
import Login from '../pages/login'
import Header from '../components/features/Header'
import { FC } from 'react'
import { HistoryRouter } from '../utils/history'
import PlaybooksDashboard from '../pages/PlaybooksDashboard'
import ExerciseRequestFormPage from '../pages/ExecrciseRequestForm'
import SpectrumManagerDashboard from '../pages/SpectrumManagerDashboard'

interface IProps {
  children: JSX.Element
}

const Layout: FC<IProps> = ({ children }) => {
  return (
    <>
      <Header />
      {children}
    </>
  )
}

const RoutesComponent = () => {
  const { isAuthenticated } = useAuth0()

  const ProtectedRoute = ({
    element,
    type,
    ...rest
  }: {
    element: JSX.Element
    type?: string
  }) => {
    if (type === 'login') {
      return isAuthenticated ? (
        <Navigate to="/exercise-request-form" />
      ) : (
        element
      )
    }

    return isAuthenticated ? element : <Navigate to="/login" />
  }

  return (
    <HistoryRouter>
      <Routes data-testid="routes">
        <Route
          path="/:playbookId"
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <Playbook />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/map"
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <ResiumMap />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/playbooks"
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <PlaybooksDashboard />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/login"
          element={<ProtectedRoute type="login" element={<Login />} />}
        />
        <Route
          path="/"
          element={<Navigate to="/exercise-request-form" replace />}
        />
        <Route
          path="/spectrum-manager-dashboard"
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <SpectrumManagerDashboard />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/exercise-request-form"
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <ExerciseRequestFormPage />
                </Layout>
              }
            />
          }
        />
      </Routes>
    </HistoryRouter>
  )
}

export default RoutesComponent
