import { createRoot } from 'react-dom/client'
import './index.scss'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import { ReactFlowProvider } from '@xyflow/react'
import { MUIThemeProvider } from './themes/useTheme'
import { ToastProvider } from './contexts/toast.context'
import { EventsProvider } from './contexts/events.context'
import { PlaybookProvider } from './contexts/playbooks.context'
import { SchedulersProvider } from './contexts/schedulers.context'
import { AdditionalActionsProvider } from './contexts/additional-actions.context'
import * as serviceWorker from './serviceWorker'
import { getConfig } from './config'
import { history } from './utils/history'
import { ExerciseRequestProvider } from './contexts/exercise-request.context'
import { ExerciseRequestEquipmentProvider } from './contexts/exercise-request-equipment.context'
import { SpectrumManagerProvider } from './contexts/spectrum.context'

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname,
  )
}

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig()

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Auth0Provider {...providerConfig}>
    <ReactFlowProvider>
      <ToastProvider>
        <PlaybookProvider>
          <SpectrumManagerProvider>
            <ExerciseRequestProvider>
              <ExerciseRequestEquipmentProvider>
                <EventsProvider>
                  <SchedulersProvider>
                    <AdditionalActionsProvider>
                      <MUIThemeProvider>
                        <App />
                      </MUIThemeProvider>
                    </AdditionalActionsProvider>
                  </SchedulersProvider>
                </EventsProvider>
              </ExerciseRequestEquipmentProvider>
            </ExerciseRequestProvider>
          </SpectrumManagerProvider>
        </PlaybookProvider>
      </ToastProvider>
    </ReactFlowProvider>
  </Auth0Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
