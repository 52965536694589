import { FC, useContext, useState } from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Typography,
} from '@mui/material'
import { PlaybookContext } from '../../../../../contexts/playbooks.context'
import { AdditionalActionsContext } from '../../../../../contexts/additional-actions.context'

import 'mapbox-gl/dist/mapbox-gl.css'
import styles from './styles.module.scss'

interface IProps {
  data: {
    nodeId: number
    elasticIndex: string
    interference: number
  }
  setData: (data: any) => void
}

const InterferenceInfo: FC<IProps> = ({ data, setData }) => {
  const [interference, setInterference] = useState(data.interference)

  const { playbook, isLightMode, setPlaybook } = useContext(PlaybookContext)
  const { additionalActions } = useContext(AdditionalActionsContext)

  const handleChange = (value: number) => {
    if (playbook) {
      setInterference(value)

      const nodes = playbook.nodes.map((node) =>
        +node.id === data.nodeId
          ? { ...node, data: { ...node.data, interference: value } }
          : node,
      )

      setData({ ...data, interference: value })
      setPlaybook({ ...playbook, nodes })
    }
  }

  const handleSelect = (id: number) => {
    const additionalAction = additionalActions.find(
      (additionalAction) => additionalAction.id === id,
    )

    if (!additionalAction) return

    setData({
      ...data,
      elasticIndex: additionalAction.name,
    })
  }

  return (
    <div className={styles.content}>
      <div className={styles.textContainer}>
        <Typography
          id="nodeIdentifier"
          className={styles.text}
          style={{
            fontWeight: 600,
          }}
        >
          Interference
        </Typography>
        <Box className={styles.statusContainer}></Box>
      </div>
      <div className={styles.sliderContainer}>
        <label
          data-testid="label"
          style={{ color: isLightMode ? 'black' : 'white' }}
        >
          Interference {interference}
        </label>
        <Slider
          value={interference}
          min={0}
          max={100}
          onChange={(_, value) => handleChange(value as number)}
        />
        <FormControl sx={{ width: '100%' }}>
          <InputLabel sx={{ top: 20, color: 'white' }} id="sensor">
            Select sensor network
          </InputLabel>
          <Select
            className={styles.select}
            labelId="sensor"
            value={data.elasticIndex}
            defaultValue={data.elasticIndex}
            label="Select sensor network"
            onChange={(evt) => handleSelect(+(evt.target.value as string))}
          >
            {additionalActions.map((additionalNode) => (
              <MenuItem key={additionalNode.id} value={additionalNode.id}>
                {additionalNode.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

export default InterferenceInfo
