import { FC, useContext, useState } from 'react'
import { AppBar, Box, Tab, Tabs } from '@mui/material'
import GeneralSection from '../GeneralSection'
import LocationSection from '../LocationSection'
import FrequencySection from '../FrequencySection'
import EquipmentSection from '../Equipment'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import { TabPanel } from '../../../shared/TabPanel'

import styles from './styles.module.scss'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: { overflowY: 'scroll', borderRadius: 0 },
  }
}

interface IProps {
  isLightMode: boolean
}

const Sections: FC<IProps> = ({ isLightMode }) => {
  const [value, setValue] = useState(0)

  const { selectedExerciseRequest } = useContext(ExerciseRequestContext)

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box
      className={styles.sections}
      style={{
        backgroundColor: isLightMode ? 'white' : '#13222e',
        height: '100%',
      }}
    >
      {selectedExerciseRequest && (
        <>
          <Box className={styles.content}>
            <AppBar position="static">
              <Tabs
                value={value}
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: isLightMode ? 'rgb(22, 119, 56)' : 'white',
                  },
                }}
                sx={{ color: 'green' }}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="General" {...a11yProps(0)} />
                <Tab label="Location" {...a11yProps(1)} />
                <Tab label="Frequency" {...a11yProps(2)} />
                <Tab label="Equipment" {...a11yProps(3)} />
              </Tabs>
            </AppBar>
          </Box>

          <Box className={styles.tabsContainer}>
            <TabPanel value={value} index={0}>
              <Box sx={{ overflowY: 'hidden', overflowX: '' }}>
                <GeneralSection
                  isLightMode={isLightMode}
                  setValue={setValue}
                  selectedExerciseRequest={selectedExerciseRequest}
                />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <LocationSection isLightMode={isLightMode} setValue={setValue} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <FrequencySection isLightMode={isLightMode} setValue={setValue} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <EquipmentSection isLightMode={isLightMode} setValue={setValue} />
            </TabPanel>
          </Box>
        </>
      )}
    </Box>
  )
}

export default Sections
