import { createContext, ReactNode, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { getAllAdditionalActions } from '../services/additional-actions.service'
import {
  IAdditionalAction,
  IAdditionalActionsContext,
} from '../types/additional-actions.type'

export const AdditionalActionsContext =
  createContext<IAdditionalActionsContext>({
    additionalActions: [],
    getAdditionalActions: () => {},
    setAdditionalActions: () => {},
  })

export const AdditionalActionsProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [additionalActions, setAdditionalActions] = useState<
    IAdditionalAction[]
  >([])

  const { getAccessTokenSilently } = useAuth0()

  const getAdditionalActions = async () => {
    const data = await getAllAdditionalActions(await getAccessTokenSilently())

    if (data.success && data.data) {
      setAdditionalActions(data.data)
    }
  }

  useEffect(() => {
    getAdditionalActions()
  }, [])

  return (
    <AdditionalActionsContext.Provider
      value={{ additionalActions, getAdditionalActions, setAdditionalActions }}
    >
      {children}
    </AdditionalActionsContext.Provider>
  )
}
