import { FC, useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { PlaybookContext } from '../../../contexts/playbooks.context'

import styles from './styles.module.scss'

interface IProps {
  code: number
  message: string
}

const ErrorPage: FC<IProps> = ({ code, message }) => {
  const { isLightMode } = useContext(PlaybookContext)

  return (
    <Box
      className={styles.error}
      style={{ backgroundColor: isLightMode ? 'white' : '#13222e' }}
    >
      <Box className={styles.errorContainer}>
        <Typography className={styles.code}>{code}</Typography>
        <Typography className={styles.border}>|</Typography>
        <Typography className={styles.message}>{message}</Typography>
      </Box>
    </Box>
  )
}

export default ErrorPage
