import { createContext, ReactNode, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  IExercise,
  IUsageTime,
  IStateRegion,
  IMajorFunction,
  IExerciseRequest,
  ILocationTemplate,
  IUsageTimeService,
  IExerciseRequestContext,
  IExerciseRequestLocation,
  IExerciseRequestFrequency,
} from '../types/exercise.type'
import {
  getAllLocations,
  getAllExercises,
  getAllUsageTimes,
  getAllFrequencies,
  getAllStateRegions,
  getAllMajorFunctions,
  getAllExerciseRequests,
  getAllLocationTemplates,
  getAllUsageTimeServices,
} from '../services/exercise-request-form.service'
import { IExerciseRequestEquipment } from '../types/equipment.type'
import { getAllEquipments } from '../services/equipment.service'

export const ExerciseRequestContext = createContext<IExerciseRequestContext>({
  exercises: [],
  usageTimes: [],
  stateRegions: [],
  setExercises: () => {},
  majorFunctions: [],
  exerciseRequests: [],
  usageTimeServices: [],
  locationTemplates: [],
  getExerciseRequests: () => {},
  setExerciseRequests: () => {},
  selectedExerciseRequest: null,
  exerciseRequestLocations: [],
  exerciseRequestEquipments: [],
  exerciseRequestFrequencies: [],
  setSelectedExerciseRequest: () => {},
  setExerciseRequestLocations: () => {},
  setExerciseRequestEquipments: () => {},
  setExerciseRequestFrequencies: () => {},
  selectedExerciseRequestLocation: null,
  selectedExerciseRequestFrequency: null,
  setSelectedExerciseRequestLocation: () => {},
  setSelectedExerciseRequestFrequency: () => {},
})

export const ExerciseRequestProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [exercises, setExercises] = useState<IExercise[]>([])
  const [usageTimes, setUsageTimes] = useState<IUsageTime[]>([])
  const [stateRegions, setStateRegions] = useState<IStateRegion[]>([])
  const [exerciseRequests, setExerciseRequests] = useState<IExerciseRequest[]>(
    [],
  )
  const [majorFunctions, setMajorFunctions] = useState<IMajorFunction[]>([])
  const [locationTemplates, setLocationTemplates] = useState<
    ILocationTemplate[]
  >([])
  const [usageTimeServices, setUsageTimeServices] = useState<
    IUsageTimeService[]
  >([])
  const [selectedExerciseRequest, setSelectedExerciseRequest] =
    useState<IExerciseRequest | null>(null)
  const [exerciseRequestEquipments, setExerciseRequestEquipments] = useState<
    IExerciseRequestEquipment[]
  >([])
  const [exerciseRequestLocations, setExerciseRequestLocations] = useState<
    IExerciseRequestLocation[]
  >([])
  const [exerciseRequestFrequencies, setExerciseRequestFrequencies] = useState<
    IExerciseRequestFrequency[]
  >([])
  const [selectedExerciseRequestLocation, setSelectedExerciseRequestLocation] =
    useState<IExerciseRequestLocation | null>(null)
  const [
    selectedExerciseRequestFrequency,
    setSelectedExerciseRequestFrequency,
  ] = useState<IExerciseRequestFrequency | null>(null)

  const { getAccessTokenSilently } = useAuth0()

  const getExercises = async () => {
    const data = await getAllExercises(await getAccessTokenSilently())

    if (data.success && data.data) {
      setExercises(data.data)
    }
  }

  const getExerciseRequests = async (
    type: 'my requests' | 'all unit requests',
    exercise_id: number | null,
  ) => {
    const data = await getAllExerciseRequests(
      await getAccessTokenSilently(),
      type,
      exercise_id,
    )

    if (data.success && data.data) {
      setExerciseRequests(data.data)
    }
  }

  const getStateRegions = async () => {
    const data = await getAllStateRegions(await getAccessTokenSilently())

    if (data.success && data.data) {
      setStateRegions(data.data)
    }
  }

  const getLocationTemplates = async () => {
    const data = await getAllLocationTemplates(await getAccessTokenSilently())

    if (data.success && data.data) {
      setLocationTemplates(data.data)
    }
  }

  const getUsageTimes = async () => {
    const data = await getAllUsageTimes(await getAccessTokenSilently())

    if (data.success && data.data) {
      setUsageTimes(data.data)
    }
  }

  const getUsageTimeServices = async () => {
    const data = await getAllUsageTimeServices(await getAccessTokenSilently())

    if (data.success && data.data) {
      setUsageTimeServices(data.data)
    }
  }

  const getExerciseRequestLocations = async () => {
    if (selectedExerciseRequest) {
      const data = await getAllLocations(
        await getAccessTokenSilently(),
        selectedExerciseRequest.id,
      )

      if (data.success && data.data) {
        setExerciseRequestLocations(data.data)
      }
    }
  }

  const getMajorFunctions = async () => {
    const data = await getAllMajorFunctions(await getAccessTokenSilently())

    if (data.success && data.data) {
      setMajorFunctions(data.data)
    }
  }

  const getExerciseRequestFrequencies = async () => {
    if (selectedExerciseRequest) {
      const data = await getAllFrequencies(
        await getAccessTokenSilently(),
        selectedExerciseRequest.id,
      )

      if (data.success && data.data) {
        setExerciseRequestFrequencies(data.data)
      }
    }
  }

  const getExerciseRequestEquipments = async () => {
    if (selectedExerciseRequest) {
      const data = await getAllEquipments(
        await getAccessTokenSilently(),
        selectedExerciseRequest.id,
      )

      if (data.success && data.data) {
        setExerciseRequestEquipments(data.data)
      }
    }
  }

  useEffect(() => {
    getExerciseRequestLocations()
    getExerciseRequestEquipments()
    getExerciseRequestFrequencies()
  }, [selectedExerciseRequest])

  useEffect(() => {
    getExercises()
    getUsageTimes()
    getStateRegions()
    getMajorFunctions()
    getLocationTemplates()
    getUsageTimeServices()
  }, [])

  return (
    <ExerciseRequestContext.Provider
      value={{
        exercises,
        usageTimes,
        stateRegions,
        setExercises,
        majorFunctions,
        exerciseRequests,
        locationTemplates,
        usageTimeServices,
        getExerciseRequests,
        setExerciseRequests,
        selectedExerciseRequest,
        exerciseRequestLocations,
        exerciseRequestEquipments,
        setSelectedExerciseRequest,
        exerciseRequestFrequencies,
        setExerciseRequestLocations,
        setExerciseRequestEquipments,
        setExerciseRequestFrequencies,
        selectedExerciseRequestLocation,
        selectedExerciseRequestFrequency,
        setSelectedExerciseRequestLocation,
        setSelectedExerciseRequestFrequency,
      }}
    >
      {children}
    </ExerciseRequestContext.Provider>
  )
}
