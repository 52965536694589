import { useContext } from 'react'
import { Box } from '@mui/material'
import TableComponent from '../../../shared/Table'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import { ExerciseRequestTableConfigs } from '../../../../utils/constants'
import Search from '../../../shared/Search'
import { IPagination } from '../../../../types/playbook.type'

import styles from './styles.module.scss'

const Requests = () => {
  const {
    detailedExerciseRequests,
    setDetailedExerciseRequests,
    filterOptions,
    setFilterOptions,
  } = useContext(SpectrumManagerContext)

  const { isLightMode } = useContext(PlaybookContext)

  return (
    <Box
      className={styles.requests}
      style={{ backgroundColor: isLightMode ? 'white' : '#13222e' }}
    >
      <Search
        isLightMode={isLightMode}
        handleSearch={(search) =>
          setFilterOptions({ ...filterOptions, search })
        }
      />
      <TableComponent
        height={450}
        isLightMode={isLightMode}
        isLoading={detailedExerciseRequests.isLoading}
        data={detailedExerciseRequests.requests}
        totalCount={detailedExerciseRequests.pagination.count}
        configs={ExerciseRequestTableConfigs}
        handleRefetch={(pagination: IPagination) =>
          setDetailedExerciseRequests((prev) => ({ ...prev, pagination }))
        }
      />
    </Box>
  )
}

export default Requests
