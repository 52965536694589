import { Dispatch, SetStateAction } from 'react'
import { type Edge, type Node } from '@xyflow/react'
import { IEvents } from './event.type'
import { Direction } from '../algorithms'

export interface IPagination {
  skip: number
  take: number
  count: number
}

export interface IPlaybookContext {
  playbook: IPlaybook | undefined
  playbooks: IPlaybook[]
  user: string
  error: string
  nodes: Node[]
  edges: Edge[]
  initialPlaybook: IPlaybook | undefined
  isEditing: boolean
  isLightMode: boolean
  isLoading: boolean
  pagination: IPagination
  layoutOptions: IAutoLayout
  setUser: Dispatch<SetStateAction<string>>
  getPlaybook: (id: string) => void
  setLayoutOptions: Dispatch<SetStateAction<IAutoLayout>>
  setNodes: Dispatch<SetStateAction<Node[]>>
  setEdges: Dispatch<SetStateAction<Edge[]>>
  handleUpdate: (playbook: IPlaybook) => void
  getPlaybooks: (search?: string) => void
  setPlaybook: Dispatch<SetStateAction<IPlaybook | undefined>>
  setIsLightMode: Dispatch<SetStateAction<boolean>>
  setInitialPlaybook: Dispatch<SetStateAction<IPlaybook | undefined>>
  setIsEditing: Dispatch<SetStateAction<boolean>>
}

export interface IPlaybook {
  id: number
  name: string
  createdAt: Date
  enabled: boolean
  direction: DirectionTypes
  events: IEvents
  autoLayoutOptions: IAutoLayout
  nodes: Array<Node & { isRemoved?: boolean; data: any }>
  edges: Array<Edge & { isRemoved?: boolean; data: any }>
  nextNodeId: number
}

export interface IAutoLayout {
  algorithm: 'd3-hierarchy' | 'elk' | 'dagre'
  direction: Direction
}

export enum NotificationStatuses {
  SENT = 'Sent',
  SPAM = 'Spam',
  WAITING = 'Waiting',
}

export enum PlaybookColumnsTypes {
  ID = 'id',
  NAME = 'name',
  ENABLED = 'enabled',
  CREATED_AT = 'createdAt',
}

export enum DirectionTypes {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}
