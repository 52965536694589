import { FC, useEffect, useState } from 'react'
import { Box } from '@mui/material'

import styles from './styles.module.scss'

interface IProps {
  isAnimated: boolean
  icons: JSX.Element[]
}

const AnimatedIcon: FC<IProps> = ({ isAnimated, icons }) => {
  const [icon, setIcon] = useState(1)

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (isAnimated) {
      interval = setInterval(() => {
        setIcon((prevIcon) => (prevIcon % icons.length) + 1)
      }, 800)
    }

    return () => clearInterval(interval)
  }, [isAnimated])

  return <Box className={styles.animatedIcon}>{icons[icon - 1]}</Box>
}

export default AnimatedIcon
