import { FC, useContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Typography } from '@mui/material'
import NodeHandle from '../../components/shared/Handle'
import NodeIcons from '../../components/shared/NodeIcons'
import DraggableButton from '../../components/shared/DraggableButton'
import { PlaybookContext } from '../../contexts/playbooks.context'
import { updateNode } from '../../services/node.service'
import { NodeTypes } from '../../types/node.type'

import styles from './styles.module.scss'

interface IProps {
  data: {
    state: string
    nodeId: number
  }
}

const ButtonsNode: FC<IProps> = ({ data }) => {
  const [isHighlighted, setIsHighlighted] = useState(false)

  const { getAccessTokenSilently } = useAuth0()
  const { nodes, isLightMode, playbook } = useContext(PlaybookContext)

  const onClick = async (state: string) => {
    if (playbook && playbook.enabled) {
      const node = playbook.nodes.find((node) => +node.id === data.nodeId)

      if (node) {
        await updateNode(
          {
            ...node,
            data: { ...node.data, state },
          },
          await getAccessTokenSilently(),
        )
      }
    }
  }

  useEffect(() => {
    const node = nodes.find((node) => node.selected && +node.id === data.nodeId)

    if (node) {
      setIsHighlighted(true)
    } else {
      setIsHighlighted(false)
    }
  }, [nodes])

  return (
    <div
      className={styles.node}
      id={`${data.nodeId}`}
      style={
        isHighlighted
          ? {
              backgroundColor: isLightMode ? 'white' : '#13222e',
              boxShadow: isLightMode ? '0 0 10px black' : '0 0 10px #fff',
              border: '1px solid white',
            }
          : {
              backgroundColor: isLightMode ? 'white' : '#13222e',
            }
      }
    >
      <DraggableButton
        backgroundColor={'white'}
        isLightMode={isLightMode}
        isSelected={isHighlighted}
      />
      <div className={styles.content + ' nodrag'}>
        {!playbook?.enabled && <NodeIcons nodeType={NodeTypes.BUTTONS} />}
        {!playbook?.enabled && (
          <Typography
            id="nodeIdentifier"
            className={styles.text}
          >
            Manual Review
          </Typography>
        )}
        {playbook && <NodeHandle direction={playbook.direction} />}

        {playbook?.enabled && (
          <Box className={styles.buttonsContainer} id="nodeIdentifier">
            <Button
              id="allowButton"
              onClick={() => onClick('allow')}
              sx={{ color: isLightMode ? 'green' : '#08f308' }}
              className={styles.button}
            >
              Allow
            </Button>
            <Button
              id="denyButton"
              onClick={() => onClick('deny')}
              sx={{ color: isLightMode ? 'red' : '#ff4545' }}
              className={styles.button}
            >
              Deny
            </Button>
          </Box>
        )}
      </div>
    </div>
  )
}

export default ButtonsNode
