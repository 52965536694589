import { useContext, useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { IArcDiagramNodes } from '../../../../types/spectrum-manager.type'
import { getAllArcDiagramNodes } from '../../../../services/spectrum-manager.service'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'

const ArcDiagramIframe = () => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [model, setModel] = useState<{ nodes: IArcDiagramNodes[] }>({
    nodes: [],
  })

  const { getAccessTokenSilently } = useAuth0()
  const { filterOptions } = useContext(SpectrumManagerContext)

  const getArcDiagramNodes = async () => {
    const data = await getAllArcDiagramNodes(
      await getAccessTokenSilently(),
      filterOptions,
    )

    if (data.data) {
      setModel({ nodes: data.data })
    }
  }

  useEffect(() => {
    getArcDiagramNodes()
  }, [filterOptions])

  useEffect(() => {
    if (iframeRef.current && model.nodes.length) {
      const sendModelToIframe = () => {
        if (iframeRef.current?.contentWindow) {
          iframeRef.current.contentWindow.postMessage(model, '*')
        }
      }

      if (
        iframeRef.current?.contentWindow?.document.readyState === 'complete'
      ) {
        sendModelToIframe()
      } else {
        iframeRef.current.onload = sendModelToIframe
      }
    }
  }, [model])

  return (
    <iframe
      ref={iframeRef}
      src="/arc-diagram.html"
      width="100%"
      height="800"
      style={{ border: 'none' }}
    />
  )
}

export default ArcDiagramIframe
