import { SyntheticEvent, useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
  Box,
  Modal,
  Button,
  TextField,
  Typography,
  IconButton,
  Tabs,
  Tab,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { TabPanel } from '../../../shared/TabPanel'
import { DevicesTransmitters } from '../../SpectrumManagerDashboard/DeviceTransmitters'
import { DeviceAntennas } from '../../SpectrumManagerDashboard/DeviceAntennas'
import { IDevice, IDeviceAntenna } from '../../../../types/equipment.type'

import styles from './styles.module.scss'
import { IPlatform } from '../../../../types/spectrum-manager.type'

interface IProps<T> {
  open: boolean
  fields: { key: string; column: string; isColor: boolean }[]
  platform: IPlatform
  onClose: () => void
  handleSave: () => void
  handleChange: (key: string, value: string) => void
}

const PlatformModal = <T extends Record<string, any>>({
  open,
  fields,
  platform,
  onClose,
  handleSave,
  handleChange,
}: IProps<T>) => {
  return (
    <Modal className={styles.modal} open={open} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box className={styles.content}>
          <Box className={styles.header}>
            <Typography className={styles.title} id="title">
              Platform Details
            </Typography>
            <IconButton onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.content}>
            <Box className={styles.fields}>
              {fields.slice(1).map((field) => (
                <Box className={styles.fieldContainer}>
                  <Typography>{field.column}:</Typography>
                  <TextField
                    value={(platform as any)[field.key]}
                    className={styles.field}
                    size="small"
                    onChange={(evt) =>
                      handleChange(field.key, evt.target.value)
                    }
                  />
                </Box>
              ))}
              <Box className={styles.fieldContainer}>
                <Typography></Typography>
                <Button sx={{ color: 'white' }} onClick={handleSave}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default PlatformModal
