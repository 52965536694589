import { Node } from '@xyflow/react'
import { IFilters } from './requestId.type'

export type IReactFlowNode = Node & { data: any }

export enum NodeTypes {
  MAP = 'map',
  TEXT = 'text',
  SFAF = 'sfaf',
  EMAIL = 'email',
  SLIDER = 'slider',
  BUTTONS = 'buttons',
  PLAYBOOK = 'playbook',
  REQUEST_ID = 'requestId',
  POST_REQUEST = 'postRequest',
  TurnOffNetwork = 'turn_network_off',
  SwitchFrequency = 'Switch Frequency',
  ModifyBandwidth = 'Modify Bandwidth',
  ModifyQualityOfService = 'Modify Quality of Service',
}

export enum AddNodeTypes {
  MAP = 'map',
  SFAF = 'sfaf',
  TEXT = 'text',
  EMAIL = 'email',
  SLIDER = 'slider',
  ACTION = 'action',
  TRIGGER = 'trigger',
  BUTTONS = 'buttons',
  REQUEST_ID = 'requestId',
  POST_REQUEST = 'postRequest',
  TurnOffNetwork = 'turn_network_off',
  SwitchFrequency = 'Switch Frequency',
  ModifyBandwidth = 'Modify Bandwidth',
  ADDITIONAL_NODE = 'additionalAction',
  ModifyQualityOfService = 'Modify Quality of Service',
}

export interface INodeData {
  interference?: number
  latitude?: number
  longitude?: number
  radius?: number
  label?: string
  nodeId?: number
  deviceId?: number
  requestId?: number
  triggerId?: number
  email?: string
  enabled?: boolean
  state?: string
  tableName?: string
  filters?: IFilters[]
  requestMethod?: string
  watchColumn?: string
  updateColumn?: string
  newValue?: string
  method?: string
  url?: string
  endpoint?: string
  headers: { key: string; value: string }[]
  body: { [key: string]: any }
}
