import { createContext, ReactNode, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  getAllEmissionTypes,
  getALlDeviceAntennas,
  getAllStationClasses,
  getAllRadarTunabilityTypes,
  getAllStationClassDescriptions,
  getAllExerciseRequestDeviceAntennas,
  getAllEmissionDesignatorSimpleTypes,
  getAllExerciseRequestStationClasses,
} from '../services/equipment.service'
import {
  IEmissionType,
  IManufacturer,
  IStationClass,
  IDeviceAntenna,
  IRadarTunabilityTypes,
  IExerciseRequestEquipment,
  IExerciseRequestStationClass,
  IEmissionDesignatorSimpleType,
  IExerciseRequestDeviceAntenna,
  IExerciseRequestEquipmentContext,
} from '../types/equipment.type'

export const ExerciseRequestEquipmentContext =
  createContext<IExerciseRequestEquipmentContext>({
    equipment: null,
    setEquipment: () => {},
    emissionTypes: [],
    manufacturers: [],
    deviceAntennas: [],
    stationClasses: [],
    radarTunabilityTypes: [],
    stationClassDescriptions: [],
    emissionDesignatorSimpleTypes: [],
    exerciseRequestDeviceAntennas: [],
    exerciseRequestStationClasses: [],
    setExerciseRequestDeviceAntennas: () => {},
    setExerciseRequestStationClasses: () => {},
  })

export const ExerciseRequestEquipmentProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [equipment, setEquipment] = useState<IExerciseRequestEquipment | null>(
    null,
  )
  const [deviceAntennas, setDeviceAntennas] = useState<IDeviceAntenna[]>([])
  const [emissionTypes, setEmissionTypes] = useState<IEmissionType[]>([])
  const [manufacturers, setManufacturers] = useState<IManufacturer[]>([])
  const [stationClasses, setStationClasses] = useState<IStationClass[]>([])
  const [radarTunabilityTypes, setRadarTunabilityTypes] = useState<
    IRadarTunabilityTypes[]
  >([])
  const [stationClassDescriptions, setStationClassDescriptions] = useState<
    IStationClass[]
  >([])
  const [emissionDesignatorSimpleTypes, setEmissionDesignatorSimpleTypes] =
    useState<IEmissionDesignatorSimpleType[]>([])
  const [exerciseRequestStationClasses, setExerciseRequestStationClasses] =
    useState<IExerciseRequestStationClass[]>([])
  const [exerciseRequestDeviceAntennas, setExerciseRequestDeviceAntennas] =
    useState<IExerciseRequestDeviceAntenna[]>([])

  const { getAccessTokenSilently } = useAuth0()

  const getExerciseRequestStationClasses = async () => {
    if (equipment) {
      const data = await getAllExerciseRequestStationClasses(
        await getAccessTokenSilently(),
        equipment.id,
      )

      if (data.data && data.success) {
        setExerciseRequestStationClasses(data.data)
      }
    }
  }

  const getEmissionTypes = async () => {
    if (equipment?.device_id) {
      const data = await getAllEmissionTypes(await getAccessTokenSilently())

      if (data.data && data.success) {
        setEmissionTypes(data.data)
      }
    }
  }

  const getEmissionDesignatorSimpleTypes = async () => {
    if (equipment?.device_id) {
      const data = await getAllEmissionDesignatorSimpleTypes(
        await getAccessTokenSilently(),
      )

      if (data.data && data.success) {
        setEmissionDesignatorSimpleTypes(data.data)
      }
    }
  }

  const getRadarTunabilityTypes = async () => {
    const data = await getAllRadarTunabilityTypes(
      await getAccessTokenSilently(),
    )

    if (data.success && data.data) {
      setRadarTunabilityTypes(data.data)
    }
  }

  const getStationClasses = async () => {
    if (equipment?.device_id) {
      const data = await getAllStationClasses(await getAccessTokenSilently())

      if (data.data && data.success) {
        setStationClasses(data.data)
      }
    }
  }

  const getExerciseRequestDeviceAntennas = async () => {
    if (equipment) {
      const data = await getAllExerciseRequestDeviceAntennas(
        await getAccessTokenSilently(),
        equipment.id,
      )

      if (data.success && data.data) {
        setExerciseRequestDeviceAntennas(data.data)
      }
    }
  }

  const getDeviceAntennas = async () => {
    if (equipment?.device_id) {
      const data = await getALlDeviceAntennas(
        await getAccessTokenSilently(),
        equipment?.device_id,
      )

      if (data.success && data.data) {
        setDeviceAntennas(data.data)
      }
    }
  }

  const getStationClassDescriptions = async () => {
    if (equipment?.device_id) {
      const data = await getAllStationClassDescriptions(
        await getAccessTokenSilently(),
      )

      if (data.data && data.success) {
        setStationClassDescriptions(data.data)
      }
    }
  }

  useEffect(() => {
    if (equipment) {
      getRadarTunabilityTypes()
      getExerciseRequestStationClasses()
      getExerciseRequestDeviceAntennas()

      if (equipment?.device_id) {
        getDeviceAntennas()
        getStationClasses()
        getEmissionTypes()
        getStationClassDescriptions()
        getEmissionDesignatorSimpleTypes()
      }
    }
  }, [equipment])

  return (
    <ExerciseRequestEquipmentContext.Provider
      value={{
        equipment,
        setEquipment,
        emissionTypes,
        manufacturers,
        deviceAntennas,
        stationClasses,
        radarTunabilityTypes,
        stationClassDescriptions,
        emissionDesignatorSimpleTypes,
        exerciseRequestDeviceAntennas,
        exerciseRequestStationClasses,
        setExerciseRequestDeviceAntennas,
        setExerciseRequestStationClasses,
      }}
    >
      {children}
    </ExerciseRequestEquipmentContext.Provider>
  )
}
