import { type Node, type Edge } from '@xyflow/react'

import elk from './elk'
import dagre from './dagre'
import d3Hierarchy from './d3-hierarchy'

export type Direction = 'TB' | 'LR' | 'RL' | 'BT'

export type LayoutAlgorithmOptions = {
  direction: Direction
}

export type LayoutAlgorithm = (
  nodes: Node[],
  edges: Edge[],
  options: LayoutAlgorithmOptions,
) => Promise<{ nodes: Node[]; edges: Edge[] }>

export default {
  dagre,
  'd3-hierarchy': d3Hierarchy,
  elk,
}
