import { FC, useContext, useEffect, useState } from 'react'
import Map, { GeolocateControl, Layer, Marker, Source } from 'react-map-gl'
import { TextField, Typography } from '@mui/material'
import { PlaybookContext } from '../../../../../contexts/playbooks.context'
import { getLayerCoords } from '../../../../../utils/functions'
import { TriggerRuleTypes } from '../../../../../types/trigger.type'

import 'mapbox-gl/dist/mapbox-gl.css'
import styles from './styles.module.scss'

interface IProps {
  data: {
    radius: number
    latitude: number
    longitude: number
    nodeId: number
  }
  setData: (data: any) => void
}

const MapInfo: FC<IProps> = ({ data, setData }) => {
  const [markData, setMarkData] = useState({
    latitude: data.latitude || 0,
    longitude: data.longitude || 0,
    radius: data.radius || 0,
  })
  const [geoJson, setGeoJson] = useState<any>(null)

  const { playbook, setPlaybook } = useContext(PlaybookContext)

  const onChange = (key: TriggerRuleTypes, value: number) => {
    if (playbook) {
      const nodes = playbook.nodes.map((node) =>
        +node.id === data.nodeId
          ? { ...node, data: { ...node.data, [key]: value } }
          : node,
      )

      setMarkData({ ...markData, [key]: value })
      setData({ ...data, [key]: value })
      setPlaybook({ ...playbook, nodes })
    }
  }

  useEffect(() => {
    if (playbook) {
      const node = playbook.nodes.find((node) => +node.id == data.nodeId)

      if (
        node &&
        node.data.latitude &&
        node.data.longitude &&
        node.data.radius
      ) {
        const geoCoords = getLayerCoords(
          node.data.latitude,
          node.data.longitude,
          node.data.radius,
        )

        setMarkData({
          radius: node.data.radius,
          latitude: node.data.latitude,
          longitude: node.data.longitude,
        })

        setGeoJson({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [geoCoords],
              },
            },
          ],
        })
      }
    }
  }, [playbook])

  return (
    <div className={styles.content}>
      <div className={styles.textContainer}>
        <Typography
          id="nodeIdentifier"
          className={styles.text}
          style={{
            fontSize: 30,
            width: 200,
            height: 20,
            fontWeight: 600,
          }}
        >
          Geo-Based
        </Typography>
      </div>
      <div style={{ width: '100%', height: '300px' }}>
        <Map
          mapboxAccessToken={process.env.REACT_APP_MAP_ACCESS_TOKEN}
          mapStyle="mapbox://styles/mapbox/satellite-v9"
          initialViewState={{
            latitude: markData.latitude,
            longitude: markData.longitude,
            zoom: 3.5,
          }}
        >
          <Source id="custom-source-id" type="geojson" data={geoJson}>
            <Layer
              id="custom-layer-id"
              type="fill"
              source="custom-source-id"
              paint={{
                'fill-color': '#ADC3D4',
                'fill-opacity': 0.4,
              }}
            />
          </Source>
          <Marker latitude={markData.latitude} longitude={markData.longitude} />
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
        </Map>
      </div>
      <div>
        <div>
          <div className={styles.inputs}>
            <TextField
              label="Latitude"
              type="number"
              value={markData.latitude}
              onChange={(evt) =>
                onChange(TriggerRuleTypes.LATITUDE, +evt.target.value)
              }
            />
            <TextField
              label="Longitude"
              type="number"
              value={markData.longitude}
              onChange={(evt) =>
                onChange(TriggerRuleTypes.LONGITUDE, +evt.target.value)
              }
            />
            <TextField
              label="Radius"
              type="number"
              value={markData.radius}
              onChange={(evt) =>
                onChange(TriggerRuleTypes.RADIUS, +evt.target.value)
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MapInfo
