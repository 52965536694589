import { FC, useEffect, useRef, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import {
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  TextField,
  Autocomplete,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { downloadPdf } from '../../../../services/playbook.service'
import { getColumnValues } from '../../../../services/requests.service'
import { isValidBase64 } from '../../../../utils/functions'

import styles from './styles.module.scss'

interface IProps {
  open: boolean
  onClose: () => void
}

const ExportPDFModal: FC<IProps> = ({ open, onClose }) => {
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [locations, setLocations] = useState<string[]>([])
  const [exerciseLocations, setExerciseLocations] = useState<string[]>([])

  const { getAccessTokenSilently } = useAuth0()

  const pdfRef = useRef<HTMLAnchorElement>(null)

  const handleDateChange = (value: Dayjs | null, type: 'start' | 'end') => {
    if (value) {
      if (type === 'start') {
        setStartDate(value)
      } else {
        setEndDate(value)
      }
    }
  }

  const getLocations = async () => {
    const data = await getColumnValues(
      'exercise_request',
      'transmitter_location',
      await getAccessTokenSilently(),
    )

    if (data.data) {
      setExerciseLocations(data.data.filter((location) => location) as string[])
    }
  }

  const base64ToBlob = (base64: string) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: 'application/pdf' })
  }

  const downloadSinglePdf = async () => {
    const data = await downloadPdf(
      locations,
      [startDate.toDate().getTime(), endDate.toDate().getTime()],
      await getAccessTokenSilently(),
    )

    if (data && isValidBase64(data)) {
      const blob = base64ToBlob(data)

      const blobUrl = URL.createObjectURL(blob)

      if (pdfRef.current) {
        pdfRef.current.href = blobUrl
        pdfRef.current.click()
      }
    }
  }

  useEffect(() => {
    getLocations()
  }, [])

  return (
    <Modal className={styles.modal} open={open} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box>
          <Box className={styles.header}>
            <Typography className={styles.title} id="title">
              Export PDF
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.selects}>
            <Autocomplete
              noOptionsText="Nothing was found"
              className={styles.operations}
              options={exerciseLocations}
              onChange={(_, value) => setLocations(value as string[])}
              multiple
              value={locations}
              renderInput={(params) => (
                <TextField {...params} label="Locations" />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box className={styles.dateRange}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(value) => handleDateChange(value, 'start')}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(value) => handleDateChange(value, 'end')}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box className={styles.actions}>
            <Button
              color="inherit"
              className={styles.pdfBtn}
              onClick={downloadSinglePdf}
            >
              Download PDF
            </Button>
            <a ref={pdfRef} className={styles.pdfBtn} download hidden>
              Download PDF
            </a>
            <Button
              className={styles.cancelBtn}
              color="inherit"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ExportPDFModal
