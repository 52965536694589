import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { toast } from 'react-toastify'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import SectionLayout from '../SectionLayout'
import Map, { GeolocateControl, Marker } from 'react-map-gl'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import {
  convertToMGRS,
  decimalToDMS,
  dmsToDecimal,
} from '../../../../utils/functions'
import {
  createExerciseLocation,
  deleteLocationById,
  updateLocationById,
} from '../../../../services/exercise-request-form.service'
import { SelectWithSearch } from '../../../shared/SearchSelect'

import styles from '../SectionLayout/styles.module.scss'

interface IProps {
  isLightMode: boolean
  setValue: Dispatch<SetStateAction<number>>
}

const LocationSection: FC<IProps> = ({ isLightMode, setValue }) => {
  const [MGRS, setMGRS] = useState('')
  const [transmitterLocation, setTransmitterLocation] = useState('')
  const [radius, setRadius] = useState(0)
  const [region, setRegion] = useState(0)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [template, setTemplate] = useState(0)
  const [elevation, setElevation] = useState(0)
  const [locationType, setLocationType] = useState('deg/min/sec')
  const [latitude, setLatitude] = useState({
    decimals: 0,
    degrees: 0,
    minutes: 0,
    seconds: 0,
    NS: 'N',
  })
  const [longitude, setLongitude] = useState({
    decimals: 0,
    degrees: 0,
    minutes: 0,
    seconds: 0,
    EW: 'E',
  })

  const {
    stateRegions,
    locationTemplates,
    selectedExerciseRequest,
    exerciseRequestLocations,
    setExerciseRequestLocations,
    selectedExerciseRequestLocation,
    setSelectedExerciseRequestLocation,
  } = useContext(ExerciseRequestContext)

  const { getAccessTokenSilently } = useAuth0()

  const handleAdd = async () => {
    if (selectedExerciseRequest) {
      const data = await createExerciseLocation(
        await getAccessTokenSilently(),
        selectedExerciseRequest.id,
      )

      if (data.success && data.data) {
        setExerciseRequestLocations([...exerciseRequestLocations, data.data])
        toast('Location created successfully', { type: 'success' })
      }
    }
  }

  const handleClose = () => {
    setIsUpdate(false)
    setIsDelete(false)
  }

  const handleSave = async () => {
    if (selectedExerciseRequest && selectedExerciseRequestLocation) {
      const data = await updateLocationById(
        await getAccessTokenSilently(),
        selectedExerciseRequestLocation.id,
        {
          rad: radius,
          elevation,
          lat_deg: latitude.degrees,
          lat_min: latitude.minutes,
          lat_sec: latitude.seconds,
          lat_NS: latitude.NS,
          lon_deg: longitude.degrees,
          lon_min: longitude.minutes,
          lon_sec: longitude.seconds,
          lon_EW: longitude.EW,
          loc_template: template,
          state_region_id: region,
          transmitter_loc: transmitterLocation,
        },
      )

      if (data.success && data.data) {
        setExerciseRequestLocations(
          exerciseRequestLocations.map((location) =>
            data.data && location.id === data.data.id ? data.data : location,
          ),
        )
        toast('Location updated successfully', { type: 'success' })
      }
    }
    handleClose()
  }

  const handleDelete = async () => {
    if (selectedExerciseRequestLocation) {
      const data = await deleteLocationById(
        await getAccessTokenSilently(),
        selectedExerciseRequestLocation.id,
      )

      if ((data.success, data.data)) {
        setExerciseRequestLocations(
          exerciseRequestLocations.filter(
            (location) => location.id !== selectedExerciseRequestLocation.id,
          ),
        )
        toast('Location removed successfully', { type: 'success' })
        setSelectedExerciseRequestLocation(null)
      }
    }
    handleClose()
  }

  const handleMapClick = ({ lat, lng }: { lat: number; lng: number }) => {
    const latData = decimalToDMS(lat, true)
    const longData = decimalToDMS(lng, false)

    setLatitude({ decimals: lat, ...latData, NS: latData.direction })
    setLongitude({ decimals: lng, ...longData, EW: longData.direction })
  }

  const handleTemplateChange = (templateId: number) => {
    setTemplate(templateId)
    const locationTemplate = locationTemplates.find(
      (elem) => elem.id === templateId,
    )

    if (locationTemplate) {
      setRegion(locationTemplate.state_region_type_id)
      setTransmitterLocation(locationTemplate.transmitter_location)
      setLatitude({
        degrees: locationTemplate.latitude_deg,
        minutes: locationTemplate.latitude_min,
        seconds: locationTemplate.latitude_sec,
        NS: locationTemplate.latitude_ns,
        decimals: dmsToDecimal(
          locationTemplate.latitude_deg,
          locationTemplate.latitude_min,
          locationTemplate.latitude_sec,
          locationTemplate.latitude_ns,
        ),
      })
      setLongitude({
        degrees: locationTemplate.longitude_deg,
        minutes: locationTemplate.longitude_min,
        seconds: locationTemplate.longitude_sec,
        EW: locationTemplate.longitude_ew,
        decimals: dmsToDecimal(
          locationTemplate.longitude_deg,
          locationTemplate.longitude_min,
          locationTemplate.longitude_sec,
          locationTemplate.longitude_ew,
        ),
      })
    }
  }

  useEffect(() => {
    if (selectedExerciseRequestLocation) {
      setElevation(selectedExerciseRequestLocation.elevation)
      setRegion(selectedExerciseRequestLocation.state_region_type_id)
      setTransmitterLocation(
        selectedExerciseRequestLocation.transmitter_location,
      )
      setTemplate(selectedExerciseRequestLocation.location_template_id)
      setRadius(selectedExerciseRequestLocation.radius)
      setElevation(selectedExerciseRequestLocation.elevation)
      setLatitude({
        degrees: selectedExerciseRequestLocation.latitude_deg,
        minutes: selectedExerciseRequestLocation.latitude_min,
        seconds: selectedExerciseRequestLocation.latitude_sec,
        NS: selectedExerciseRequestLocation.latitude_ns,
        decimals: dmsToDecimal(
          selectedExerciseRequestLocation.latitude_deg,
          selectedExerciseRequestLocation.latitude_min,
          selectedExerciseRequestLocation.latitude_sec,
          selectedExerciseRequestLocation.latitude_ns,
        ),
      })
      setLongitude({
        degrees: selectedExerciseRequestLocation.longitude_deg,
        minutes: selectedExerciseRequestLocation.longitude_min,
        seconds: selectedExerciseRequestLocation.longitude_sec,
        EW: selectedExerciseRequestLocation.longitude_ew,
        decimals: dmsToDecimal(
          selectedExerciseRequestLocation.longitude_deg,
          selectedExerciseRequestLocation.longitude_min,
          selectedExerciseRequestLocation.longitude_sec,
          selectedExerciseRequestLocation.longitude_ew,
        ),
      })
    }
  }, [selectedExerciseRequestLocation])

  useEffect(() => {
    setMGRS(
      convertToMGRS(
        latitude.degrees,
        latitude.minutes,
        latitude.seconds,
        latitude.NS,
        longitude.degrees,
        longitude.minutes,
        longitude.seconds,
        longitude.EW,
      ),
    )
  }, [latitude, longitude])

  return (
    <SectionLayout
      sectionTitle="Location"
      btnText="Add Location"
      handleAdd={handleAdd}
      isUpdate={isUpdate}
      isDelete={isDelete}
      handleSave={handleSave}
      setValue={setValue}
      handleDelete={handleDelete}
      handleClose={handleClose}
      setIsDelete={setIsDelete}
      setIsUpdate={setIsUpdate}
      isLightMode={isLightMode}
      updateText={'Are you suer you want to update this location'}
      deleteText={'Are you suer you want to delete this location'}
      headerItems={
        <>
          <Select
            value={locationType}
            onChange={(evt) => setLocationType(evt.target.value)}
          >
            <MenuItem value="decimal">Decimal Degrees</MenuItem>
            <MenuItem value="deg/min/sec">Degrees Minutes Seconds</MenuItem>
            <MenuItem value="MGRS">MGRS</MenuItem>
          </Select>
        </>
      }
      isSelected={!!selectedExerciseRequestLocation}
      mapComponent={
        <Map
          onClick={(evt) => handleMapClick(evt.lngLat)}
          mapboxAccessToken={process.env.REACT_APP_MAP_ACCESS_TOKEN}
          mapStyle="mapbox://styles/mapbox/satellite-v9"
          viewState={{
            latitude: latitude.decimals,
            longitude: longitude.decimals,
            zoom: 3.5,
            bearing: 0,
            height: 100,
            padding: { bottom: 0, top: 0, left: 0, right: 0 },
            pitch: 1,
            width: 100,
          }}
        >
          <Marker latitude={latitude.decimals} longitude={longitude.decimals} />
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
        </Map>
      }
      sidebarItems={
        <Box className={styles.detailedContainer}>
          {exerciseRequestLocations.map((location) => (
            <Box
              onClick={() => setSelectedExerciseRequestLocation(location)}
              className={
                location.id === selectedExerciseRequestLocation?.id
                  ? isLightMode
                    ? styles.item
                    : styles.selectedItem
                  : isLightMode
                    ? styles.lightItem
                    : styles.item
              }
            >
              {location.id === selectedExerciseRequestLocation?.id && (
                <CircleIcon
                  sx={{ color: 'green', height: 15, width: 15 }}
                  className={styles.circle}
                />
              )}
              <Typography>{location.state_region_name}</Typography>
              <Box className={styles.details}>
                <Typography>Location:</Typography>
                <Typography>{location.transmitter_location}</Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Radius:</Typography>
                <Typography>{location.radius}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      }
      fieldItems={[
        <Box className={styles.section}>
          <Box className={styles.detailsSubSection}>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                Populate from template:
              </Typography>
              <SelectWithSearch
                width={350}
                value={locationTemplates
                  .map((template) => ({
                    label: template.template_name,
                    value: template.id,
                  }))
                  .find((location) => location.value === template)}
                label="Templates"
                handleChange={(value) => handleTemplateChange(Number(value))}
                options={locationTemplates.map((template) => ({
                  value: template.id,
                  label: template.template_name,
                }))}
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                State/Region/Country:
              </Typography>
              <SelectWithSearch
                width={350}
                value={stateRegions
                  .map((stateRegion) => ({
                    value: stateRegion.id,
                    label: stateRegion.name,
                  }))
                  .find((stateRegion) => stateRegion.value === region)}
                options={stateRegions.map((region) => ({
                  value: region.id,
                  label: region.name,
                }))}
                label="Regions"
                handleChange={(value) => setRegion(Number(value))}
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                City/Installation/Location:
              </Typography>
              <TextField
                className={styles.field}
                label="Location"
                value={transmitterLocation}
                onChange={(evt) => setTransmitterLocation(evt.target.value)}
              />
            </Box>
            <Box className={styles.fieldContainer}>
              {locationType !== 'MGRS' && (
                <>
                  <Typography className={styles.label}>Latitude:</Typography>
                  {locationType === 'deg/min/sec' && (
                    <Box className={styles.locationContainer}>
                      <Box className={styles.details}>
                        <Typography className={styles.label}>
                          Degrees
                        </Typography>
                        <TextField
                          value={
                            latitude.NS === 'S'
                              ? -latitude.degrees
                              : latitude.degrees
                          }
                          onChange={(evt) =>
                            setLatitude({
                              ...latitude,
                              degrees: Number(evt.target.value),
                            })
                          }
                          className={styles.locationField}
                          type="number"
                        />
                      </Box>
                      <Box className={styles.details}>
                        <Typography className={styles.label}>
                          Minutes
                        </Typography>
                        <TextField
                          value={latitude.minutes}
                          onChange={(evt) =>
                            setLatitude({
                              ...latitude,
                              minutes: Number(evt.target.value),
                            })
                          }
                          className={styles.locationField}
                          type="number"
                        />
                      </Box>
                      <Box className={styles.details}>
                        <Typography className={styles.label}>
                          Seconds
                        </Typography>
                        <TextField
                          value={latitude.seconds}
                          onChange={(evt) =>
                            setLatitude({
                              ...latitude,
                              seconds: Number(evt.target.value),
                            })
                          }
                          className={styles.locationField}
                          type="number"
                        />
                      </Box>
                    </Box>
                  )}
                  {locationType === 'decimal' && (
                    <TextField
                      className={styles.field}
                      value={latitude.decimals}
                      label="Latitude"
                      onChange={(evt) =>
                        setLatitude({
                          ...latitude,
                          decimals: Number(evt.target.value),
                        })
                      }
                      placeholder="Enter Value"
                    />
                  )}
                </>
              )}
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                {locationType === 'MGRS' ? 'MGRS:' : 'Longitude:'}
              </Typography>
              {locationType === 'deg/min/sec' && (
                <Box className={styles.locationContainer}>
                  <Box className={styles.details}>
                    <Typography className={styles.label}>Degrees</Typography>
                    <TextField
                      value={
                        longitude.EW == 'W'
                          ? -longitude.degrees
                          : longitude.degrees
                      }
                      onChange={(evt) =>
                        setLongitude({
                          ...longitude,
                          degrees: Number(evt.target.value),
                        })
                      }
                      className={styles.locationField}
                      type="number"
                    />
                  </Box>
                  <Box className={styles.details}>
                    <Typography className={styles.label}>Minutes</Typography>
                    <TextField
                      value={longitude.minutes}
                      onChange={(evt) =>
                        setLongitude({
                          ...longitude,
                          degrees: Number(evt.target.value),
                        })
                      }
                      className={styles.locationField}
                      type="number"
                    />
                  </Box>
                  <Box className={styles.details}>
                    <Typography className={styles.label}>Seconds</Typography>
                    <TextField
                      value={longitude.seconds}
                      onChange={(evt) =>
                        setLongitude({
                          ...longitude,
                          degrees: Number(evt.target.value),
                        })
                      }
                      className={styles.locationField}
                      type="number"
                    />
                  </Box>
                </Box>
              )}
              {locationType === 'decimal' && (
                <TextField
                  className={styles.field}
                  value={longitude.decimals}
                  label="Longitude"
                  onChange={(evt) =>
                    setLongitude({
                      ...longitude,
                      decimals: Number(evt.target.value),
                    })
                  }
                  placeholder="Enter Value"
                />
              )}
              {locationType === 'MGRS' && (
                <TextField
                  className={styles.field}
                  value={MGRS}
                  onChange={(evt) => setMGRS(evt.target.value)}
                  placeholder="Enter Value"
                />
              )}
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>Radius (km):</Typography>
              <TextField
                className={styles.field}
                value={radius}
                label="Radius"
                onChange={(evt) => setRadius(Number(evt.target.value))}
                placeholder="Enter Value"
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>Elevation:</Typography>
              <TextField
                className={styles.field}
                label="Elevation"
                value={elevation}
                onChange={(evt) => setElevation(Number(evt.target.value))}
              />
            </Box>
          </Box>
        </Box>,
      ]}
    />
  )
}

export default LocationSection
