import { FC } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Typography } from '@mui/material'
import { EventStatus } from '../../../../types/event.type'
import { EventColor } from '../../../../utils/functions'

import styles from './styles.module.scss'

interface IProps {
  isLightMode: boolean
  text: string
  date: Date
  status: EventStatus
}

const EventBox: FC<IProps> = ({ isLightMode, text, date, status }) => {
  return (
    <>
      <Box
        className={styles.event}
        style={{ borderColor: EventColor(status, isLightMode) }}
      >
        <InfoIcon style={{ color: EventColor(status, isLightMode) }} />
        <Box className={styles.textContainer}>
          <Typography
            id="event"
            className={styles.text}
            style={{ color: EventColor(status, isLightMode) }}
          >
            {`${date}: ${text}`}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default EventBox
