import { SyntheticEvent, useContext, useState } from 'react'
import { AppBar, Box, Tab, Tabs } from '@mui/material'
import { Exercises } from '../Exercises'
import { OperatingUnits } from '../OperatingUnits'
import { LocationTemplates } from '../LocationTemplates'
import { TabPanel } from '../../../shared/TabPanel'
import { PlaybookContext } from '../../../../contexts/playbooks.context'

import styles from './styles.module.scss'

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

export const FieldsControls = () => {
  const [value, setValue] = useState(0)
  const { isLightMode } = useContext(PlaybookContext)

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box className={styles.fieldControls}>
      <Box className={styles.tabs}>
        <AppBar position="static">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              sx: {
                backgroundColor: isLightMode ? 'rgb(22, 119, 56)' : 'white',
              },
            }}
            sx={{ color: 'green' }}
            aria-label="Vertical tabs example"
          >
            <Tab
              label="Exercises"
              sx={{
                background:
                  value === 0
                    ? isLightMode
                      ? 'rgb(22, 119, 56, 0.2)'
                      : 'rgb(255, 255, 255, 0.2)'
                    : '',
              }}
              {...a11yProps(0)}
            />
            <Tab
              label="Locations"
              sx={{
                background:
                  value === 1
                    ? isLightMode
                      ? 'rgb(22, 119, 56, 0.2)'
                      : 'rgb(255, 255, 255, 0.2)'
                    : '',
              }}
              {...a11yProps(1)}
            />
            <Tab
              label="Units"
              sx={{
                background:
                  value === 2
                    ? isLightMode
                      ? 'rgb(22, 119, 56, 0.2)'
                      : 'rgb(255, 255, 255, 0.2)'
                    : '',
              }}
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>
      </Box>
      <Box className={styles.content}>
        <TabPanel value={value} index={0}>
          <Exercises />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LocationTemplates />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <OperatingUnits />
        </TabPanel>
      </Box>
    </Box>
  )
}
