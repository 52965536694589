import { FC, useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import {
  createDeviceAntenna,
  createDeviceAntennaMode,
  deleteDeviceAntenna,
  deleteDeviceAntennaMode,
  getAllDeviceAntennaModes,
  updateDeviceAntenna,
  updateDeviceAntennaMode,
} from '../../../../services/spectrum-manager.service'
import { useAuth0 } from '@auth0/auth0-react'
import { DeviceAntennaConfigs } from '../../../../utils/constants'
import ExpandableTable from '../../../shared/ExpandableTable'
import { IDeviceAntenna } from '../../../../types/equipment.type'
import { PlaybookContext } from '../../../../contexts/playbooks.context'

import styles from './styles.module.scss'

interface IProps {
  deviceId: number
  deviceAntennas: IDeviceAntenna[]
}

export const DeviceAntennas: FC<IProps> = ({ deviceAntennas, deviceId }) => {
  const [deviceAntennasWithModes, setDeviceAntennasWithModes] =
    useState(deviceAntennas)

  const { isLightMode } = useContext(PlaybookContext)
  const { getAccessTokenSilently } = useAuth0()

  const handleGetSubItems = async (id: number) => {
    const data = await getAllDeviceAntennaModes(
      await getAccessTokenSilently(),
      id,
    )

    if (data.data) {
      setDeviceAntennasWithModes(
        deviceAntennasWithModes.map((elem) =>
          elem.id === id ? { ...elem, items: data.data } : elem,
        ),
      )
    }
  }

  const handleCreate = async (antennaId?: number) => {
    const token = await getAccessTokenSilently()

    if (antennaId) {
      const data = await createDeviceAntennaMode(token, antennaId)

      if (data.data) {
        setDeviceAntennasWithModes(
          deviceAntennasWithModes.map((antenna) => {
            if (antenna.id === antennaId && data.data) {
              const items = antenna.items
                ? [...antenna.items, data.data]
                : [data.data]

              return {
                ...antenna,
                items,
              }
            }
            return antenna
          }),
        )
      }
    } else {
      const data = await createDeviceAntenna(
        await getAccessTokenSilently(),
        deviceId,
      )

      if (data.data) {
        setDeviceAntennasWithModes([data.data, ...deviceAntennasWithModes])
      }
    }
  }

  const handleChange = async (
    id: number,
    key: string,
    value: string,
    subItem?: number,
  ) => {
    const token = await getAccessTokenSilently()

    if (subItem) {
      const deviceAntenna = deviceAntennasWithModes.find(
        (elem) => elem.id === id,
      )

      if (deviceAntenna) {
        const modes = deviceAntenna.items?.map((mode) =>
          mode.id === subItem ? { ...mode, [key]: value } : mode,
        )

        setDeviceAntennasWithModes(
          deviceAntennasWithModes.map((elem) =>
            elem.id === id ? { ...elem, items: modes } : elem,
          ),
        )

        const updatedMode = modes?.find((mode) => mode.id === subItem)

        if (updatedMode) {
          await updateDeviceAntennaMode(token, updatedMode.id, updatedMode)
        }
      }
    } else {
      const updatedDeviceAntennas = deviceAntennasWithModes.map((elem) =>
        elem.id === id ? { ...elem, [key]: value } : elem,
      )
      setDeviceAntennasWithModes(updatedDeviceAntennas)

      const updatedAntenna = updatedDeviceAntennas.find(
        (antenna) => antenna.id === id,
      )

      if (updatedAntenna) {
        await updateDeviceAntenna(token, updatedAntenna.id, updatedAntenna)
      }
    }
  }

  const handleDelete = async (id: number, subItemId?: number) => {
    const token = await getAccessTokenSilently()

    if (subItemId) {
      const data = await deleteDeviceAntennaMode(token, subItemId)

      if (data.success) {
        setDeviceAntennasWithModes(
          deviceAntennasWithModes.map((antenna) =>
            antenna.id === id
              ? {
                  ...antenna,
                  items: antenna?.items?.filter(
                    (mode) => mode.id !== subItemId,
                  ),
                }
              : antenna,
          ),
        )
      }
    } else {
      const data = await deleteDeviceAntenna(token, id)

      if (data.success) {
        setDeviceAntennasWithModes(
          deviceAntennasWithModes.filter((antenna) => antenna.id !== id),
        )
      }
    }
  }

  useEffect(() => {
    setDeviceAntennasWithModes(deviceAntennas)
  }, [deviceAntennas])

  return (
    <Box className={styles.deviceTransmitters}>
      <ExpandableTable
        configs={DeviceAntennaConfigs}
        isLightMode={isLightMode}
        data={deviceAntennasWithModes}
        getSubItems={handleGetSubItems}
        handleCreate={handleCreate}
        handleChange={handleChange}
        handleDelete={handleDelete}
      />
    </Box>
  )
}
