import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { toast } from 'react-toastify'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import DeleteIcon from '@mui/icons-material/Delete'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import {
  updateFrequencyById,
  createExerciseFrequency,
  getIntermediateFunctionsByMajorFunctionId,
  getDetailedFunctionsByIntermediateFunctionId,
  deleteFrequencyById,
  getAllSingleFrequencies,
  getAllFrequencyTemplates,
  deleteSingleFrequency,
  createSingleFrequency,
  updateSingleFrequency,
} from '../../../../services/exercise-request-form.service'
import SectionLayout from '../SectionLayout'
import {
  IDetailedFunction,
  IFrequencyTemplate,
  IIntermediateFunction,
  IExerciseRequestFrequencySingle,
} from '../../../../types/exercise.type'

import styles from '../SectionLayout/styles.module.scss'
import { SelectComponent } from '../../../shared/SelectComponent'

interface IProps {
  setValue: Dispatch<SetStateAction<number>>
  isLightMode: boolean
}

const FrequencySection: FC<IProps> = ({ isLightMode, setValue }) => {
  const [saturn, setSaturn] = useState(false)
  const [azimuth, setAzimuth] = useState(0)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [location, setLocation] = useState(155)
  const [platform, setPlatform] = useState('')
  const [usageTime, setUsageTime] = useState(0)
  const [description, setDescription] = useState('')
  const [majorFunction, setMajorFunction] = useState(0)
  const [usageTimeService, setUsageTimeService] = useState(0)
  const [frequencyNumbers, setFrequencyNumbers] = useState(0)
  const [detailedFunction, setDetailedFunction] = useState(0)
  const [frequencyTemplates, setFrequencyTemplates] = useState<
    IFrequencyTemplate[]
  >([])
  const [detailedFunctions, setDetailedFunctions] = useState<
    IDetailedFunction[]
  >([])
  const [singleFrequencies, setSingleFrequencies] = useState<
    IExerciseRequestFrequencySingle[]
  >([])
  const [receivingLocationId, setReceivingLocationId] = useState(0)
  const [intermediateFunction, setIntermediateFunction] = useState(0)
  const [isDifferentLocation, setIsDifferentLocation] = useState(false)
  const [intermediateFunctions, setIntermediateFunctions] = useState<
    IIntermediateFunction[]
  >([])

  const {
    usageTimes,
    majorFunctions,
    usageTimeServices,
    selectedExerciseRequest,
    exerciseRequestLocations,
    exerciseRequestFrequencies,
    setExerciseRequestFrequencies,
    selectedExerciseRequestFrequency,
    setSelectedExerciseRequestFrequency,
  } = useContext(ExerciseRequestContext)

  const { getAccessTokenSilently } = useAuth0()

  const handleClose = () => {
    setIsUpdate(false)
    setIsDelete(false)
  }

  const handleAdd = async () => {
    if (selectedExerciseRequest) {
      const data = await createExerciseFrequency(
        await getAccessTokenSilently(),
        selectedExerciseRequest.id,
      )

      if (data.success && data.data) {
        setExerciseRequestFrequencies([
          ...exerciseRequestFrequencies,
          data.data,
        ])
        toast('Location created successfully', { type: 'success' })
      }
    }
  }

  const handleSave = async () => {
    if (selectedExerciseRequestFrequency && selectedExerciseRequestFrequency) {
      const data = await updateFrequencyById(
        await getAccessTokenSilently(),
        selectedExerciseRequestFrequency.id,
        {
          hopset: '',
          saturn,
          platform: platform,
          locationId: location,
          usageTimeId: usageTime || null,
          percentTime: 0,
          majorFunctionId: majorFunction || null,
          receiveLocationId: receivingLocationId || null,
          usageTimeServiceId: usageTimeService || null,
          detailedFunctionId: detailedFunction || null,
          numberOfFrequencies: frequencyNumbers,
          supplementaryDetails: description,
          intermediateFunctionId: intermediateFunction || null,
          isDifferentReceiveLocation: isDifferentLocation,
        },
      )

      if (data.success && data.data) {
        setExerciseRequestFrequencies(
          exerciseRequestFrequencies.map((frequency) =>
            data.data && frequency.id === data.data.id ? data.data : frequency,
          ),
        )
        toast('Frequency updated successfully', { type: 'success' })

        for (const frequencySingle of singleFrequencies) {
          await updateSingleFrequency(
            await getAccessTokenSilently(),
            frequencySingle.id,
            {
              ...frequencySingle,
            },
          )
        }
      }
    }
    handleClose()
  }

  const handleDelete = async () => {
    if (selectedExerciseRequestFrequency) {
      const data = await deleteFrequencyById(
        await getAccessTokenSilently(),
        selectedExerciseRequestFrequency.id,
      )

      if (data.success) {
        setExerciseRequestFrequencies(
          exerciseRequestFrequencies.filter(
            (frequency) => frequency.id !== selectedExerciseRequestFrequency.id,
          ),
        )
        toast('Frequency removed successfully', { type: 'success' })
        setSelectedExerciseRequestFrequency(null)
      }
    }
    handleClose()
  }

  const getIntermediateFunctions = async () => {
    if (majorFunction) {
      const data = await getIntermediateFunctionsByMajorFunctionId(
        await getAccessTokenSilently(),
        majorFunction,
      )

      if (data.success && data.data) {
        setIntermediateFunctions(data.data)
      }
    }
  }

  const getFrequencyTemplates = async () => {
    const data = await getAllFrequencyTemplates(await getAccessTokenSilently())

    if (data.success && data.data) {
      setFrequencyTemplates(data.data)
    }
  }

  const getSingleFrequencies = async () => {
    if (selectedExerciseRequestFrequency) {
      const data = await getAllSingleFrequencies(
        await getAccessTokenSilently(),
        selectedExerciseRequestFrequency.id,
      )

      if (data.data && data.success) {
        setSingleFrequencies(data.data)
      }
    }
  }

  const getDetailedFunctions = async () => {
    if (intermediateFunction) {
      const data = await getDetailedFunctionsByIntermediateFunctionId(
        await getAccessTokenSilently(),
        intermediateFunction,
      )

      if (data.success && data.data) {
        setDetailedFunctions(data.data)
      }
    }
  }

  const handleAddSingleFrequency = async () => {
    if (selectedExerciseRequestFrequency) {
      const data = await createSingleFrequency(
        await getAccessTokenSilently(),
        selectedExerciseRequestFrequency.id,
      )

      if (data.data && data.success) {
        setSingleFrequencies([...singleFrequencies, data.data])
      }
    }
  }

  const handleSingleFrequencyDelete = async (id: number) => {
    const data = await deleteSingleFrequency(await getAccessTokenSilently(), id)

    if (data.success) {
      setSingleFrequencies(
        singleFrequencies.filter(
          (frequencySingle) => frequencySingle.id !== id,
        ),
      )
    }
  }

  const handleSingleFrequencyUpdate = async (
    id: number,
    key: string,
    value: string | number | boolean,
  ) => {
    const updatedSingleFrequencies = singleFrequencies.map((frequencySingle) =>
      frequencySingle.id === id
        ? { ...frequencySingle, [key]: value }
        : frequencySingle,
    )

    setSingleFrequencies(updatedSingleFrequencies)
  }

  useEffect(() => {
    if (majorFunction) {
      getIntermediateFunctions()
    }
  }, [majorFunction])

  useEffect(() => {
    if (intermediateFunction) {
      getDetailedFunctions()
    }
  }, [intermediateFunction])

  useEffect(() => {
    if (selectedExerciseRequestFrequency) {
      setPlatform(selectedExerciseRequestFrequency.platform)
      setLocation(selectedExerciseRequestFrequency.exercise_request_location_id)
      setUsageTime(selectedExerciseRequestFrequency.usage_time_id)
      setUsageTimeService(
        selectedExerciseRequestFrequency.usage_time_service_id,
      )
      setReceivingLocationId(
        selectedExerciseRequestFrequency.receive_exercise_request_location_id,
      )
      setIsDifferentLocation(
        selectedExerciseRequestFrequency.is_different_receive_location,
      )
      setDescription(selectedExerciseRequestFrequency.supplementary_details)
      setMajorFunction(selectedExerciseRequestFrequency.major_function_id)
      setFrequencyNumbers(
        selectedExerciseRequestFrequency.number_of_frequencies,
      )
      setDetailedFunction(selectedExerciseRequestFrequency.detailed_function_id)
      setIntermediateFunction(
        selectedExerciseRequestFrequency.intermediate_function_id,
      )
      getSingleFrequencies()
    }
  }, [selectedExerciseRequestFrequency])

  return (
    <SectionLayout
      sectionTitle="Frequency"
      btnText="Add Frequency"
      handleAdd={handleAdd}
      isUpdate={isUpdate}
      isDelete={isDelete}
      handleClose={handleClose}
      handleSave={handleSave}
      handleDelete={handleDelete}
      setIsDelete={setIsDelete}
      setIsUpdate={setIsUpdate}
      isLightMode={isLightMode}
      setValue={setValue}
      updateText={'Are you sure you want to update this frequency?'}
      deleteText={'Are you sure you want to delete this frequency?'}
      headerItems={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box className={styles.item}>
            <SelectComponent
              isLightMode={isLightMode}
              items={exerciseRequestLocations.map((elem) => ({
                value: elem.id,
                displayValue: elem.transmitter_location,
              }))}
              label="Location"
              value={location}
              className={styles.field}
              handleChange={(value) => setLocation(Number(value))}
            />
            <Checkbox
              checked={isDifferentLocation}
              onChange={(_, checked) => setIsDifferentLocation(checked)}
            />
            <Typography>Different receiver location</Typography>
          </Box>
          {isDifferentLocation && (
            <Box className={styles.item}>
              <SelectComponent
                isLightMode={isLightMode}
                items={exerciseRequestLocations.map((elem) => ({
                  value: elem.id,
                  displayValue: elem.transmitter_location,
                }))}
                label="Receiving Location"
                value={receivingLocationId}
                className={styles.field}
                handleChange={(value) => setLocation(Number(value))}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography>Azimuth</Typography>
                <TextField
                  className={styles.field}
                  label="Azimuth"
                  value={receivingLocationId ? 139.6584 : 0}
                  onChange={(evt) => setAzimuth(Number(evt.target.value))}
                />
              </Box>
            </Box>
          )}
        </Box>
      }
      isSelected={!!selectedExerciseRequestFrequency}
      sidebarItems={
        <Box className={styles.detailedContainer}>
          {exerciseRequestFrequencies.map((frequency) => (
            <Box
              onClick={() => setSelectedExerciseRequestFrequency(frequency)}
              className={
                frequency.id === selectedExerciseRequestFrequency?.id
                  ? isLightMode
                    ? styles.item
                    : styles.selectedItem
                  : isLightMode
                    ? styles.lightItem
                    : styles.item
              }
            >
              {frequency.id === selectedExerciseRequestFrequency?.id && (
                <CircleIcon
                  sx={{ color: 'green', height: 15, width: 15 }}
                  className={styles.circle}
                />
              )}
              <Box className={styles.details}>
                <Typography>Location:</Typography>
                <Typography className={styles.info}>
                  {
                    exerciseRequestLocations.find(
                      (location) =>
                        location.id === frequency.exercise_request_location_id,
                    )?.transmitter_location
                  }
                </Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Platform:</Typography>
                <Typography className={styles.info}>
                  {frequency.platform}
                </Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Major Fn:</Typography>
                <Typography className={styles.info}>
                  {
                    majorFunctions.find(
                      (elem) => elem.id === frequency.major_function_id,
                    )?.name
                  }
                </Typography>
              </Box>
              <Box className={styles.details}>
                <Typography>Detailed Fn:</Typography>
                <Typography className={styles.info}>
                  {
                    detailedFunctions.find(
                      (elem) => elem.id === frequency.detailed_function_id,
                    )?.name
                  }
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      }
      fieldItems={[
        <Box className={styles.section}>
          <Box className={styles.titleContainer}>
            <Typography className={styles.title}>Emissions</Typography>
            <Box className={styles.saturn}>
              <Typography>Saturn</Typography>
              <Checkbox
                checked={saturn}
                onChange={(_, checked) => setSaturn(checked)}
              />
            </Box>
          </Box>
          <Box className={styles.subSection}>
            <Typography></Typography>
          </Box>
          <Box className={styles.subSection}>
            {singleFrequencies.map((frequencySingle, index) => (
              <Box className={styles.singleFrequency} key={frequencySingle.id}>
                <Typography>{index + 1}.</Typography>
                <Box className={styles.details}>
                  <Select sx={{ display: 'none' }}>
                    {frequencyTemplates.map((template) => (
                      <MenuItem key={template.id} value={template.id}>
                        {template.display}
                      </MenuItem>
                    ))}
                  </Select>
                  <Checkbox
                    sx={{ display: 'none' }}
                    checked={frequencySingle.is_ref_frequency}
                    onChange={(_, checked) =>
                      handleSingleFrequencyUpdate(
                        frequencySingle.id,
                        'is_ref_frequency',
                        checked,
                      )
                    }
                  />
                  <Checkbox
                    sx={{ display: 'none' }}
                    checked={frequencySingle.is_custom}
                    onChange={(_, checked) =>
                      handleSingleFrequencyUpdate(
                        frequencySingle.id,
                        'is_custom',
                        checked,
                      )
                    }
                  />
                  <Typography>Band</Typography>
                  <Checkbox
                    checked={frequencySingle.is_band}
                    onChange={(_, checked) =>
                      handleSingleFrequencyUpdate(
                        frequencySingle.id,
                        'is_band',
                        checked,
                      )
                    }
                  />
                  <Select
                    className={styles.field}
                    defaultValue="K"
                    value={frequencySingle.start_indicator}
                    onChange={(evt) =>
                      handleSingleFrequencyUpdate(
                        frequencySingle.id,
                        'start_indicator',
                        evt.target.value,
                      )
                    }
                  >
                    {['K', 'M', 'G', 'T'].map((measure) => (
                      <MenuItem key={measure} value={measure}>
                        {measure}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    className={styles.field}
                    value={frequencySingle.start_freq}
                    type="number"
                    onChange={(evt) =>
                      handleSingleFrequencyUpdate(
                        frequencySingle.id,
                        'start_freq',
                        Number(evt.target.value),
                      )
                    }
                  />
                  <Select
                    className={styles.field}
                    defaultValue="K"
                    value={frequencySingle.end_indicator}
                    onChange={(evt) =>
                      handleSingleFrequencyUpdate(
                        frequencySingle.id,
                        'end_indicator',
                        evt.target.value,
                      )
                    }
                  >
                    {['K', 'M', 'G', 'T'].map((measure) => (
                      <MenuItem key={measure} value={measure}>
                        {measure}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    value={frequencySingle.end_freq}
                    type="number"
                    className={styles.field}
                    onChange={(evt) =>
                      handleSingleFrequencyUpdate(
                        frequencySingle.id,
                        'end_freq',
                        Number(evt.target.value),
                      )
                    }
                  />
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() =>
                      handleSingleFrequencyDelete(frequencySingle.id)
                    }
                  >
                    <DeleteIcon sx={{ color: 'white' }} />
                  </Button>
                </Box>
              </Box>
            ))}
            <Button
              color="success"
              variant="contained"
              className={styles.addFreq}
              onClick={handleAddSingleFrequency}
            >
              Add Excluded Freq
            </Button>
          </Box>
        </Box>,
        <Box className={styles.section}>
          <Box className={styles.titleContainer}>
            <Typography className={styles.title}>
              Frequency Characteristics
            </Typography>
          </Box>
          <Box className={styles.subSection}>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>Platform:</Typography>
              <TextField
                value={platform}
                label="Platform"
                className={styles.field}
                onChange={(evt) => setPlatform(evt.target.value)}
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                Numbers of Frequency:
              </Typography>
              <TextField
                value={frequencyNumbers}
                label="Numbers of Frequency"
                className={styles.field}
                onChange={(evt) =>
                  setFrequencyNumbers(Number(evt.target.value))
                }
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>Major function:</Typography>
              <SelectComponent
                isLightMode={isLightMode}
                items={majorFunctions.map((elem) => ({
                  value: elem.id,
                  displayValue: elem.name,
                }))}
                label="Major FN"
                value={majorFunction}
                className={styles.field}
                handleChange={(value) => setMajorFunction(Number(value))}
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                Intermediate Function:
              </Typography>
              <SelectComponent
                isLightMode={isLightMode}
                items={intermediateFunctions.map((elem) => ({
                  value: elem.id,
                  displayValue: elem.name,
                }))}
                label="Intermediate FN"
                value={intermediateFunction}
                className={styles.field}
                handleChange={(value) => setIntermediateFunction(Number(value))}
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                Detailed Function:
              </Typography>
              <SelectComponent
                isLightMode={isLightMode}
                items={detailedFunctions.map((elem) => ({
                  value: elem.id,
                  displayValue: elem.name,
                }))}
                label="Detailed FN"
                value={detailedFunction}
                className={styles.field}
                handleChange={(value) => setDetailedFunction(Number(value))}
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>Usage Time:</Typography>
              <SelectComponent
                isLightMode={isLightMode}
                items={usageTimes.map((elem) => ({
                  value: elem.id,
                  displayValue: elem.name,
                }))}
                label="Usage Time"
                value={usageTime}
                className={styles.field}
                handleChange={(value) => setUsageTime(Number(value))}
              />
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>
                Usage Time Service:
              </Typography>
              <SelectComponent
                isLightMode={isLightMode}
                label="Usage Time Service"
                value={usageTimeService}
                className={styles.field}
                handleChange={(value) => setUsageTimeService(Number(value))}
                items={usageTimeServices.map((elem) => ({
                  value: elem.id,
                  displayValue: elem.name,
                }))}
              />
            </Box>
            <TextField
              fullWidth
              label="Description"
              value={description}
              onChange={(evt) => setDescription(evt.target.value)}
              type="area"
            />
          </Box>
        </Box>,
      ]}
    />
  )
}

export default FrequencySection
