import { useContext, useState } from 'react'
import { Box } from '@mui/material'
import { PlaybookContext } from '../../contexts/playbooks.context'
import Toolbar from '../../components/features/SpectrumManagerDashboard/Toolbar'
import Requests from '../../components/features/SpectrumManagerDashboard/Requests'
import ArcDiagram from '../../components/features/SpectrumManagerDashboard/ArcDiagrams'
import { TabPanel } from '../../components/shared/TabPanel'
import { FieldsControls } from '../../components/features/SpectrumManagerDashboard/FieldsControls'
import { DevicesPlatforms } from '../../components/features/SpectrumManagerDashboard/DevicesPlatforms'

import styles from './styles.module.scss'

const SpectrumManagerDashboard = () => {
  const [value, setValue] = useState(0)

  const { isLightMode } = useContext(PlaybookContext)

  return (
    <Box
      className={styles.spectrumManager}
      style={{ backgroundColor: isLightMode ? 'white' : '#04111a' }}
    >
      <Box
        className={styles.content}
        style={{ backgroundColor: isLightMode ? 'white' : '#13222e' }}
      >
        <Toolbar value={value} setValue={setValue} />
        <TabPanel value={value} index={0}>
          <Requests />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ArcDiagram />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <></>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <FieldsControls />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <DevicesPlatforms />
        </TabPanel>
      </Box>
    </Box>
  )
}

export default SpectrumManagerDashboard
