import { Node } from '@xyflow/react'

export const createNode = (
  id: string,
  x: number,
  y: number,
  data: any,
  type?: string,
): Node => ({
  id,
  type,
  data: { ...data },
  position: { x, y },
  width: 300,
  height: 60,
})
