import {
  FC,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDrop } from 'react-dnd'
import { useParams } from 'react-router'
import {
  MiniMap,
  addEdge,
  type Edge,
  type Node,
  Connection,
  EdgeChange,
  NodeChange,
  getIncomers,
  getOutgoers,
  useReactFlow,
  applyNodeChanges,
  applyEdgeChanges,
  getConnectedEdges,
  ReactFlowInstance,
} from '@xyflow/react'
import Loader from '../../../shared/Loader'
import NodeDrawer from '../NodeDrawer/index'
import ContextMenu from '../ContextMenu'
import useAutoLayout from '../../../../hooks/useAutoLayout'
import { createNode } from '../../../../utils/nodeCreator'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { DirectionTypes, IPlaybook } from '../../../../types/playbook.type'
import { AddNodeTypes, INodeData } from '../../../../types/node.type'
import {
  ControlsStyled,
  ReactFlowStyled,
  nodeColor,
  nodeTypes,
} from '../../../../utils/constants'
import ErrorPage from '../../../shared/ErrorPage'
import { hashids } from '../PlaybookTable'
import { getNewNodePosition, mergeRefs } from '../../../../utils/functions'

import '@xyflow/react/dist/style.css'
import '@xyflow/react/dist/base.css'
import styles from './styles.module.scss'

interface IProps {
  isLightMode: boolean
  isLayoutEditing: boolean
  children: JSX.Element
  onAdd: (playbook: IPlaybook) => void
}

const proOptions = {
  account: 'paid-pro',
  hideAttribution: true,
}

const Flow: FC<IProps> = ({
  isLightMode,
  isLayoutEditing,
  children,
  onAdd,
}) => {
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance | null>(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [defaultNodeData, setDefaultNodeData] = useState<INodeData | {}>({})
  const [menu, setMenu] = useState<null | {
    id: string
    top: number | boolean
    left: number | boolean
    right: number | boolean
    bottom: number | boolean
  }>(null)
  const [nodeData, setNodeData] = useState({
    type: AddNodeTypes.TRIGGER,
    x: 0,
    y: 0,
    label: '',
  })

  const ref = useRef<any>(null)
  const { fitView } = useReactFlow()
  const { playbookId = '0' } = useParams()

  const {
    playbook,
    isEditing,
    isLoading,
    edges,
    error,
    nodes,
    setNodes,
    setEdges,
    handleUpdate,
    setIsEditing,
    setPlaybook,
    getPlaybook,
  } = useContext(PlaybookContext)

  useAutoLayout(isLayoutEditing)

  const [_, drop] = useDrop({
    accept: 'draggableItem',
    drop: (item: { type: AddNodeTypes }, monitor: any) => {
      const { x, y } = monitor.getClientOffset()

      if (reactFlowInstance) {
        // const position = reactFlowInstance.screenToFlowPosition({
        //   x,
        //   y,
        // })

        const position = getNewNodePosition(
          nodes,
          playbook?.direction ?? DirectionTypes.VERTICAL,
        )

        if (playbook) {
          const isSFAFOrButtons =
            item.type === AddNodeTypes.SFAF ||
            item.type === AddNodeTypes.BUTTONS

          const isActionNode =
            item.type === AddNodeTypes.ModifyBandwidth ||
            item.type === AddNodeTypes.ModifyQualityOfService ||
            item.type === AddNodeTypes.SwitchFrequency ||
            item.type === AddNodeTypes.TurnOffNetwork

          setNodeData({
            type: isActionNode ? AddNodeTypes.ACTION : item.type,
            x: position.x,
            y: position.y,
            label: item.type,
          })

          playbook.nodes = [
            ...nodes,
            createNode(
              `${playbook.nextNodeId + 1}`,
              position.x,
              position.y,
              { label: item.type },
              isSFAFOrButtons
                ? item.type
                : `${isActionNode ? 'action' : item.type}Example`,
            ),
          ]

          setPlaybook(() => playbook)

          if (!isSFAFOrButtons) {
            handleUpdate(playbook)
            setIsDrawerOpen(true)
          } else {
            onAdd(playbook)
          }
        }
      }
    },
  })

  const onNodeContextMenu = useCallback(
    (event: MouseEvent, node: Node) => {
      event.preventDefault()

      setMenu({
        id: node.id,
        top: event.clientY - 70,
        left: event.clientX - 50,
        right: false,
        bottom: false,
      })
    },
    [setMenu],
  )

  const onPaneClick = useCallback(() => setMenu(null), [setMenu])

  const onNodesChange = useCallback(
    (changes: NodeChange[]) =>
      setNodes((nds) => applyNodeChanges(changes, nds as Node[])),
    [setNodes],
  )

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) =>
      setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  )

  const onNodesDelete = useCallback(
    (deleted: Node[]) => {
      const filteredEdges = deleted.reduce((acc: Edge[], node: Node) => {
        const incomers = getIncomers(node, nodes, edges)
        const outgoers = getOutgoers(node, nodes, edges)
        const connectedEdges = getConnectedEdges([node], edges)

        const remainingEdges = acc.filter(
          (edge) => !connectedEdges.includes(edge),
        )

        const createdEdges = incomers.flatMap(({ id: source }) =>
          outgoers.map(({ id: target }) => ({
            ...remainingEdges[0],
            id: `${source}-${target}`,
            source,
            target,
          })),
        )

        return [...remainingEdges, ...createdEdges]
      }, edges)

      setEdges(filteredEdges)
      if (playbook) {
        setPlaybook({
          ...playbook,
          edges: filteredEdges as Array<Edge & { label: string; data: any }>,
        })
      }
    },
    [nodes, edges],
  )

  const onConnect = useCallback(
    (connection: Connection) => {
      setEdges((eds) =>
        addEdge(
          {
            ...connection,
            animated: playbook?.enabled,
            style: {
              fontSize: 20,
              stroke: playbook?.enabled
                ? 'green'
                : isLightMode
                  ? 'black'
                  : 'white',
              strokeWidth: 3,
            },
          },
          eds,
        ),
      )
    },
    [edges],
  )

  const handleClose = () => {
    if (playbook && !isEditing) {
      playbook.nodes = nodes.filter((node) => !node.type?.includes('Example'))
      setPlaybook(() => playbook)
      handleUpdate(playbook)
    }

    setIsDrawerOpen(false)
  }

  const onNodeClick = (_: any, node: Node) => {
    if (
      node.type === AddNodeTypes.TEXT ||
      node.type === AddNodeTypes.SFAF ||
      node.type === AddNodeTypes.BUTTONS
    ) {
      return
    }

    setIsEditing(true)
    setIsDrawerOpen(true)

    setNodeData({
      type: node.type as AddNodeTypes,
      x: node.position.x,
      y: node.position.y,
      label: node.type as string,
    })

    setDefaultNodeData({ ...node.data })
  }

  const handleAdd = (data: any) => {
    if (playbook) {
      const filteredNodes = nodes.filter(
        (node) => !node.type?.includes('Example'),
      )

      const newPosition = getNewNodePosition(filteredNodes, playbook.direction)

      playbook.nodes = [
        ...filteredNodes,
        createNode(
          `${playbook.nextNodeId + 1}`,
          newPosition.x,
          newPosition.y,
          {
            ...data,
            nodeId: playbook.nextNodeId + 1,
          },
          nodeData.type,
        ),
      ]

      setPlaybook({
        ...playbook,
      })

      setIsDrawerOpen(false)
      onAdd(playbook)
    }
  }

  useEffect(() => {
    if (playbookId) {
      const decoded = hashids.decode(playbookId)
      const id =
        Array.isArray(decoded) && decoded.length > 0 ? decoded[0] : null

      if (id) {
        getPlaybook(`${id}`)
      }
    }

    return () => {
      setNodes([])
      setEdges([])
      setPlaybook(undefined)
    }
  }, [])

  useEffect(() => {
    if (isLayoutEditing) fitView()
  }, [nodes, edges, fitView, isLayoutEditing, playbook?.autoLayoutOptions])

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return <ErrorPage code={400} message={error} />
  }

  return (
    <div className={styles.chart}>
      <ReactFlowStyled
        fitView
        maxZoom={10}
        minZoom={0.1}
        nodes={nodes}
        edges={edges}
        snapToGrid={true}
        snapGrid={[20, 20]}
        nodeTypes={nodeTypes}
        proOptions={proOptions}
        ref={mergeRefs(ref, drop)}
        nodesDraggable={isEditing}
        onInit={setReactFlowInstance}
        onConnect={isEditing ? onConnect : undefined}
        onNodeClick={isEditing ? onNodeClick : undefined}
        onNodesChange={isEditing ? onNodesChange : undefined}
        onEdgesChange={isEditing ? onEdgesChange : undefined}
        onNodesDelete={isEditing ? onNodesDelete : undefined}
        onNodeContextMenu={isEditing ? onNodeContextMenu : undefined}
        defaultEdgeOptions={{
          style: { strokeWidth: 3 },
          labelStyle: {
            fontSize: 20,
            stroke: '#49bbb3',
            strokeWidth: 3,
          },
          labelShowBg: false,
        }}
      >
        {menu && <ContextMenu onClick={onPaneClick} {...menu} />}
        <MiniMap
          nodeColor={nodeColor}
          style={{ background: isLightMode ? '#fff' : '#13222e' }}
          draggable
          pannable
        />
        <ControlsStyled />
        {children}
      </ReactFlowStyled>
      <NodeDrawer
        type={nodeData.type}
        label={nodeData.label}
        isEditing={isEditing}
        isOpen={isDrawerOpen}
        defaultData={defaultNodeData}
        handleAdd={handleAdd}
        handleClose={handleClose}
      />
    </div>
  )
}

export default Flow
