import { createContext, ReactNode, useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { type Node, type Edge } from '@xyflow/react'
import { getAllPlaybook, getOnePLaybook } from '../services/playbook.service'
import {
  IPlaybook,
  IAutoLayout,
  IPagination,
  IPlaybookContext,
} from '../types/playbook.type'
import { createEdge } from '../utils/edgeCreator'
import { createNode } from '../utils/nodeCreator'

const initialPagination = {
  count: 0,
  skip: 0,
  take: 10,
}

export const PlaybookContext = createContext<IPlaybookContext>({
  user: '',
  nodes: [],
  edges: [],
  isLoading: true,
  error: '',
  isEditing: false,
  isLightMode: false,
  playbook: undefined,
  playbooks: [],
  initialPlaybook: undefined,
  pagination: initialPagination,
  layoutOptions: {
    algorithm: 'elk',
    direction: 'TB',
  },
  setUser: () => {},
  setEdges: () => {},
  setNodes: () => {},
  handleUpdate: (playbook: IPlaybook) => {},
  setIsEditing: () => {},
  getPlaybook: () => {},
  setLayoutOptions: () => {},
  getPlaybooks: () => {},
  setPlaybook: () => {},
  setIsLightMode: () => {},
  setInitialPlaybook: () => {},
})

export const PlaybookProvider = ({ children }: { children: ReactNode }) => {
  const [nodes, setNodes] = useState<Node[]>([])
  const [edges, setEdges] = useState<Edge[]>([])
  const [layoutOptions, setLayoutOptions] = useState<IAutoLayout>({
    algorithm: 'elk',
    direction: 'LR',
  })
  const [user, setUser] = useState('')
  const [error, setError] = useState('')
  const [playbooks, setPlaybooks] = useState<IPlaybook[]>([])
  const [isLightMode, setIsLightMode] = useState(true)
  const [playbook, setPlaybook] = useState<IPlaybook | undefined>()
  const [initialPlaybook, setInitialPlaybook] = useState<
    IPlaybook | undefined
  >()
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const pagination = useRef<IPagination>(initialPagination)

  const { getAccessTokenSilently } = useAuth0()

  const getPlaybook = async (playbookId: string) => {
    setIsLoading(true)
    const data = await getOnePLaybook(
      playbookId,
      await getAccessTokenSilently(),
    )

    if (data.success && data.data) {
      setIsLoading(false)

      setLayoutOptions({ ...data.data.autoLayoutOptions })
      setPlaybook({ ...data.data })
      handleUpdate(data.data)
    }

    if (!data.success) {
      setError(data.message)
      setIsLoading(false)
    }
  }

  const getPlaybooks = async (search?: string) => {
    setIsLoading(true)
    const data = await getAllPlaybook(
      await getAccessTokenSilently(),
      search,
      pagination.current,
    )

    if (data.success && data.data) {
      setIsLoading(false)
      setPlaybooks([...data.data])

      if (data.pagination) {
        pagination.current = data.pagination
      }
    }

    if (!data.success) {
      setError(data.message)
      setIsLoading(false)
    }
  }

  const handleUpdate = (playbook: IPlaybook) => {
    setInitialPlaybook({ ...playbook })
    const isLightMode =
      (window.localStorage.getItem('mode') || 'light') === 'light'

    if (playbook && playbook.nodes.length) {
      const edges: Edge[] = []
      for (const edge of playbook.edges) {
        if (!edge.isRemoved) {
          edges.push(
            createEdge(
              edge.id,
              edge.source,
              edge.target,
              playbook?.enabled,
              isLightMode,
            ),
          )
        }
      }

      const nodes: Node[] = []

      for (const node of playbook.nodes) {
        if (!node.isRemoved) {
          nodes.push(
            createNode(
              `${node.id}`,
              node.position.x,
              node.position.y,
              node.data,
              node.type,
            ),
          )
        }
      }

      setNodes(() => nodes)
      setEdges(() => edges)
    }
  }

  useEffect(() => {
    const mode = window.localStorage.getItem('mode') || 'light'
    setIsLightMode(mode === 'light')
    getPlaybooks()
  }, [])

  return (
    <PlaybookContext.Provider
      value={{
        playbook,
        playbooks,
        user,
        error,
        nodes,
        edges,
        initialPlaybook,
        isEditing,
        isLoading,
        isLightMode,
        layoutOptions,
        pagination: pagination.current,
        setEdges,
        setNodes,
        setUser,
        setIsLightMode,
        handleUpdate,
        setLayoutOptions,
        setInitialPlaybook,
        setIsEditing,
        getPlaybook,
        getPlaybooks,
        setPlaybook,
      }}
    >
      {children}
    </PlaybookContext.Provider>
  )
}
