import { FC } from 'react'
import { Autocomplete, Box, TextField } from '@mui/material'

interface IProps {
  label: string
  width?: number
  value?: { value: string | number; label: string }
  options: { value: string | number; label: string }[]
  handleChange: (value?: string | number) => void
}

export const SelectWithSearch: FC<IProps> = ({
  label,
  width,
  value,
  options,
  handleChange,
}) => {
  return (
    <Autocomplete
      id="autocomplete"
      sx={{ width: width ?? '100%' }}
      options={options}
      autoHighlight
      value={value || null}
      isOptionEqualToValue={(option, value) => option.value === value?.value}
      onChange={(_, newValue) => handleChange(newValue?.value)}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props

        return (
          <Box key={key} component="li" {...optionProps}>
            {option.label}
          </Box>
        )
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  )
}
