import { FC, useContext, useEffect, useRef, useState } from 'react'
import { connect } from 'socket.io-client'
import { Box, Tabs, AppBar, Tab, IconButton } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import file from '../../../../sfaf.txt'
import DragNDrop from '../Drag&Drop'
import EventsGroup from '../EventsGroup'
import { EventsContext } from '../../../../contexts/events.context'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { IEvent } from '../../../../types/event.type'

import styles from './styles.module.scss'

const socket = connect(process.env.REACT_APP_BASE_URL as string)

interface PanelProps {
  children: JSX.Element
  value: number
  index: number
}

const TabPanel: FC<PanelProps> = (props) => {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

interface IProps {
  isLightMode: boolean
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: { overflowY: 'scroll' },
  }
}

const Sidebar: FC<IProps> = ({ isLightMode }) => {
  const [value, setValue] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const { playbook } = useContext(PlaybookContext)
  const { events, eventsRoom, setEvents } = useContext(EventsContext)

  const sidebarRef = useRef<HTMLDivElement>(null)

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (events && sidebarRef.current && value) {
      sidebarRef.current.scrollTo({
        top: sidebarRef.current.scrollHeight,
        behavior: 'smooth',
      })
    } else if (sidebarRef.current) {
      sidebarRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [events, value])

  useEffect(() => {
    socket.emit('joinRoom', `${eventsRoom}`)

    socket.on('events', (data: IEvent) => {
      if (data.text === 'SFAF generator worked successfully') {
        const filePath = file
        const a = document.createElement('a')
        a.href = filePath
        a.download = 'sfaf.txt'
        document.body.appendChild(a)
        a.click()

        document.body.removeChild(a)
      }

      setEvents((prev) => {
        if (prev[data.run_number]) {
          return {
            ...prev,
            [data.run_number]: {
              ...prev[data.run_number],
              events: [...prev[data.run_number].events, data],
            },
          }
        }

        return {
          ...prev,
          [data.run_number]: {
            events: [data],
            eventsDate: data.date,
          },
        }
      })
    })

    return () => {
      socket.emit('leaveRoom', `${eventsRoom}`)
      socket.off('events')
    }
  }, [])

  useEffect(() => {
    if (playbook) {
      setEvents({ ...playbook.events })
    }
  }, [playbook?.events])

  return (
    <Box
      className={styles.sidebar}
      ref={sidebarRef}
      style={{
        backgroundColor: isLightMode ? 'white' : '#13222e',
        width: isOpen ? 568 : 45,
      }}
    >
      <Box className={styles.content}>
        <Box className={styles.btn}>
          <IconButton
            onClick={() => setIsOpen(!isOpen)}
            style={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              backgroundColor: isLightMode ? 'white' : '#13222e',
              borderColor: isLightMode ? 'black' : 'white',
              transition: 'transform 1s ease',
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        <AppBar position="static">
          <Tabs
            value={value}
            TabIndicatorProps={{ sx: { backgroundColor: '#ff7800' } }}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label={isOpen ? 'Add Nodes' : ''} {...a11yProps(0)} />
            <Tab label="Event logs" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </Box>
      <TabPanel value={value} index={0}>
        <Box sx={{ overflowY: 'hidden', overflowX: '' }}>
          <DragNDrop />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box className={styles.events} style={{ width: isOpen ? 370 : 0 }}>
          {Object.keys(events).map((value, ind) => (
            <EventsGroup
              key={value}
              events={events[value].events}
              date={events[value].eventsDate}
              runNumber={value}
              open={
                !!playbook?.enabled && Object.keys(events).length - 1 === ind
              }
              isLightMode={isLightMode}
            />
          ))}
        </Box>
      </TabPanel>
    </Box>
  )
}

export default Sidebar
