import { ChangeEvent, FC, useEffect, useState } from 'react'
import { Box, Select, MenuItem, Typography, IconButton } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IPagination } from '../../../types/playbook.type'

import styles from './styles.module.scss'

interface IProps {
  pagination: IPagination
  isLightMode: boolean
  onPageChange: (skip: number) => void
  onRowsPerPageChange: (take: number) => void
}

const TableToolbar: FC<IProps> = ({
  pagination,
  isLightMode,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const [paginationText, setPaginationText] = useState('')

  const handleChangePage = (newSkip: number) => {
    onPageChange(newSkip)
  }

  const handleChangeRowsPerPage = (value: number) => {
    onRowsPerPageChange(value)
  }

  useEffect(() => {
    const startItem = pagination.skip + 1
    const endItem = Math.min(
      pagination.count,
      pagination.skip + pagination.take,
    )
    setPaginationText(`${startItem}-${endItem} of ${pagination.count}`)
  }, [pagination])

  return (
    <Box className={styles.toolbar}>
      <Box className={styles.content}>
        <Box className={styles.middleBar}>
          <IconButton>
            <FilterAltIcon sx={{ color: 'gray' }} />
          </IconButton>
          <IconButton>
            <FileDownloadIcon sx={{ color: 'gray' }} />
          </IconButton>
          <IconButton>
            <RefreshIcon sx={{ color: 'gray' }} />
          </IconButton>
        </Box>
        <Box className={styles.endBar} display="flex" alignItems="center">
          <Select
            value={pagination.take}
            onChange={(evt) =>
              handleChangeRowsPerPage(Number(evt.target.value))
            }
            variant="standard"
            disableUnderline
            sx={{ color: isLightMode ? 'black' : 'white' }}
          >
            {[10, 25, 50].map((takeOption) => (
              <MenuItem key={takeOption} value={takeOption}>
                {takeOption}
              </MenuItem>
            ))}
          </Select>
          <Typography sx={{ marginLeft: 2 }}>{paginationText}</Typography>
          <Box>
            <IconButton
              disabled={pagination.skip === 0}
              onClick={() =>
                handleChangePage(pagination.skip - pagination.take)
              }
              sx={{ color: isLightMode ? 'black' : 'white' }}
            >
              <ArrowBackIosIcon />
            </IconButton>

            <IconButton
              disabled={pagination.skip + pagination.take >= pagination.count}
              onClick={() =>
                handleChangePage(pagination.skip + pagination.take)
              }
              sx={{ color: isLightMode ? 'black' : 'white' }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TableToolbar
