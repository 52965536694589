import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import AceEditor from 'react-ace'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import RemoveIcon from '@mui/icons-material/Delete'
import { isValidJSON } from '../../../../../utils/functions'
import { INodeData } from '../../../../../types/node.type'

import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/theme-solarized_dark'

import styles from './styles.module.scss'

interface IProps {
  data: INodeData
  setData: Dispatch<SetStateAction<INodeData>>
  isLightMode: boolean
}

const PostRequestInfo: FC<IProps> = ({ data, setData, isLightMode }) => {
  const [url, setUrl] = useState('')
  const [endpoint, setEndpoint] = useState('')
  const [body, setBody] = useState<string>('')
  const [headers, setHeaders] = useState<{ key: string; value: string }[]>([])
  const [isChecked, setIsChecked] = useState(data.body ? true : false)

  const handleUrlChange = (value: string) => {
    setUrl(value)
  }

  const onBodyChange = (value: string) => {
    setBody(value)
  }

  const onHeadersChange = (index: number, key: string, value: string) => {
    const updatedHeaders = headers.map((header, ind) =>
      ind === index ? { ...header, [key]: value } : header,
    )
    setHeaders(updatedHeaders)
  }

  const handleAddHeader = () => {
    setHeaders([...headers, { key: '', value: '' }])
  }

  const handleRemove = (index: number) => {
    setHeaders(headers.filter((_, ind) => index !== ind))
  }

  useEffect(() => {
    let newData = { ...data, headers, url, endpoint }
    if (isValidJSON(body)) {
      newData.body = JSON.parse(body)
    }
    setData(newData)
  }, [body, headers, url, endpoint])

  useEffect(() => {
    if (data.headers && data.body && data.url && data.endpoint) {
      setBody(JSON.stringify(data.body))
      setHeaders(data.headers)
      setUrl(data.url)
      setEndpoint(data.endpoint)
    }
  }, [])

  return (
    <Box
      className={styles.postRequestInfo}
      draggable
      sx={{ borderColor: isLightMode ? 'black' : 'white' }}
    >
      <Typography className={styles.title}>Post Request</Typography>
      <Box className={styles.urlContainer}>
        <Autocomplete
          noOptionsText="Nothing was found"
          className={styles.select}
          options={['https://webhook.site/']}
          onChange={(_, value) => handleUrlChange(value || '')}
          value={url}
          renderInput={(params) => <TextField {...params} label="URL" />}
        />
        <TextField
          value={endpoint}
          label="Endpoint"
          className={styles.input}
          onChange={(evt) => setEndpoint(evt.target.value)}
        />
      </Box>
      <Typography>Headers:</Typography>
      <Box className={styles.headers}>
        {headers.map((header, ind) => (
          <Box key={ind} className={styles.header}>
            <TextField
              className={styles.input}
              label="key"
              value={header.key}
              onChange={(evt) => onHeadersChange(ind, 'key', evt.target.value)}
            />
            <TextField
              value={header.value}
              label="value"
              className={styles.input}
              onChange={(evt) =>
                onHeadersChange(ind, 'value', evt.target.value)
              }
            />
            <IconButton
              className={styles.remove}
              onClick={() => handleRemove(ind)}
            >
              <RemoveIcon sx={{ color: 'red' }} />
            </IconButton>
          </Box>
        ))}
        <Box className={styles.btnContainer}>
          <Button
            sx={{ background: isLightMode ? 'unset' : '#0a1a27' }}
            onClick={handleAddHeader}
          >
            Add Header
          </Button>
        </Box>
      </Box>
      <Box className={styles.bodyHeader}>
        <Typography>Request Body:</Typography>
        <Checkbox
          onClick={() => setIsChecked(!isChecked)}
          checked={isChecked}
        />
      </Box>
      {isChecked && (
        <AceEditor
          mode="json"
          className={styles.aceEditor}
          theme={isLightMode ? 'github' : 'solarized_dark'}
          value={body}
          onChange={onBodyChange}
          setOptions={{ fontSize: 18 }}
        />
      )}
    </Box>
  )
}

export default PostRequestInfo
